import AuctionGuideImg from '@/assets/images/AuctionGuide.png';
import CoupngGuideImg from '@/assets/images/CoupangGuide.png';
import ESMGuideImg from '@/assets/images/ESMGuide.png';
import GmarketGuideImg from '@/assets/images/GmarketGuide.png';
import IdusGuideImg from '@/assets/images/IdusGuide.png';
import InterparkGuideImg from '@/assets/images/InterparkGuide.png';
import TalkstoreGuideImg from '@/assets/images/TalkstoreGuide.png';
import TmonGuideImg from '@/assets/images/TmonGuide.png';
import WMPGuideImg from '@/assets/images/WMPGuide.png';
import ElevenGuideImg from '@/assets/images/11stGuide.png';
import NewGmarketGuideImg from '@/assets/images/NewGmarketGuideImg.png';
import NewAuctionGuideImg from '@/assets/images/NewAuctionGuideImg.png';

/**
 * 마켓연동 가이드들을 clinet 에서 하드 코딩한 데이터
 * MarketGuideImageOptionalList 를 대체
 */
export type GuideImage = {
  type: 'guideImg';
  guideImageUrl: string;
};

type LinkGuideContents = {
  title: string;
  mainText: React.ReactNode;
  linkButtonText?: string;
  linkUrl?: string;
  videoId?: string;
};

export type GuideLink = {
  type: 'linkGuide';
  contents: LinkGuideContents;
};

type MarketGuideDataEntry = GuideImage | GuideLink;

type MarketGuideData = {
  [marketName: string]: MarketGuideDataEntry[];
};

// TODO new 로 하드 코딩
const MARKET_GUIDE_DATA: MarketGuideData = {
  smartstore: [
    // 여기 고민중..
  ],
  coupang: [
    {
      type: 'guideImg',
      guideImageUrl: CoupngGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '쿠팡 연동을 위해 OPEN API KEY가 필요해요!',
        mainText: (
          <span>
            셀러박스 단일 연동을 하신다면 업체 선택을
            <br />타 업체와 중복 연동을 하신다면 직접 입력을 확인해 주세요.
          </span>
        ),
        linkButtonText: '쿠팡 OPEN API KEY 발급하기',
        linkUrl:
          'https://marketplace.coupangcorp.com/s/?utm_source=google_sa_pc&utm_medium=paid&utm_campaign=go_sa_pc_br&gad_source=1',
      },
    },
  ],
  '11st': [
    {
      type: 'guideImg',
      guideImageUrl: ElevenGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '11번가 연동을 위해 API KEY가 필요해요!',
        mainText: '',
        linkButtonText: '11번가 API KEY 발급받기',
        linkUrl: 'https://openapi.11st.co.kr/openapi/OpenApiFrontMain.tmall',
        videoId: 'L9YVg7lQf5A',
      },
    },
  ],
  esmplus: [
    {
      type: 'guideImg',
      guideImageUrl: ESMGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: 'ESM PLUS 연동을 위해 2차 인증 해제가 필요해요!',
        mainText: (
          <span>
            ESM PLUS 계정에 2단계 인증이 설정되어 있다면, ID/PW가 일치해도 마켓 연동이 불가능해요.
            <br />
            마켓 연동을 위해 2단계 인증을 반드시 해제해 주세요.
          </span>
        ),
        linkButtonText: 'ESM PLUS 2차 인증 해제하러 가기',
        linkUrl: 'https://signin.esmplus.com/login',
      },
    },
  ],
  wemakeprice: [
    {
      type: 'guideImg',
      guideImageUrl: WMPGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '위메프 연동을 위해 2단계 인증 해제가 필요해요!',
        mainText: (
          <span>
            위메프 계정에 2단계 인증이 설정되어 있다면, ID/PW가 일치해도 마켓 연동이 불가능해요.
            <br />
            마켓 연동을 위해 2단계 인증을 반드시 해제해 주세요.
          </span>
        ),
        linkButtonText: '위메프 2차 인증 해제하러 가기',
        linkUrl: 'https://wpartner.wemakeprice.com/login',
      },
    },
  ],
  gmarket: [
    {
      type: 'guideImg',
      guideImageUrl: GmarketGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: 'G마켓 연동을 위해 셀링툴 설정이 필요해요!',
        mainText: (
          <span>
            G마켓 계정에 2단계 인증이 설정되어 있다면, ID/PW가 일치해도 마켓 연동이 불가능해요.
            <br />
            마켓 연동을 위해 2단계 인증을 반드시 해제해 주세요.
          </span>
        ),
        linkButtonText: 'G마켓 셀링툴 설정하기',
        linkUrl: 'https://signin.esmplus.com/login',
      },
    },
  ],
  auction: [
    {
      type: 'guideImg',
      guideImageUrl: AuctionGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '옥션 연동을 위해 셀링툴 설정이 필요해요!',
        mainText: (
          <span>
            옥션 계정에 2단계 인증이 설정되어 있다면, ID/PW가 일치해도 마켓 연동이 불가능해요.
            <br />
            마켓 연동을 위해 2단계 인증을 반드시 해제해 주세요.
          </span>
        ),
        linkButtonText: '옥션 셀링툴 설정하기',
        linkUrl: 'https://signin.esmplus.com/login',
      },
    },
  ],
  interpark: [
    {
      type: 'guideImg',
      guideImageUrl: InterparkGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '인터파크 연동을 위해 2차 인증 해제가 필요해요!',
        mainText: (
          <span>
            인터파크 계정에 2차 인증이 설정되어 있다면, ID/PW가 일치해도 마켓 연동이 불가능해요.
            <br />
            마켓 연동을 위해 2차 인증을 반드시 해제해 주세요.
          </span>
        ),
        linkButtonText: '인터파크 2차 인증 해제하러 가기',
        linkUrl: 'https://seller.interpark.com/login',
      },
    },
  ],
  tmon: [
    {
      type: 'guideImg',
      guideImageUrl: TmonGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '티몬 연동을 위해 2차 인증 미사용 설정이 필요해요!',
        mainText: (
          <span>
            티몬 계정에 신규기기 로그인 2차 인증이 설정되어 있다면, ID/PW가 일치해도 마켓 연동이
            불가능해요.
            <br />
            마켓 연동을 위해 2차 인증을 반드시 미사용으로 설정해 주세요.
          </span>
        ),
        linkButtonText: '티몬 2차 인증 해제하러 가기',
        linkUrl: 'https://spc.tmon.co.kr/member/login?return_url=%2F',
      },
    },
  ],
  idus: [
    {
      type: 'guideImg',
      guideImageUrl: IdusGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '아이디어스 연동을 위해 2단계 인증 해제가 필요해요!',
        mainText: (
          <span>
            아이디어스 계정에 2단계 인증이 설정되어 있다면, ID/PW가 일치해도 마켓 연동이 불가능해요.
            <br />
            마켓 연동을 위해 2단계 인증을 반드시 해제해 주세요.
          </span>
        ),
        linkButtonText: '아이디어스 2차 인증 해제하러 가기',
        linkUrl: 'https://artist.idus.com/login?return=%252F',
      },
    },
  ],
  talkstore: [
    {
      type: 'guideImg',
      guideImageUrl: TalkstoreGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '카카오톡 스토어 연동을 위해 API KEY가 필요해요!',
        mainText: '',
        linkButtonText: '카카오톡 스토어 API KEY 발급받기',
        linkUrl: 'https://shopping-seller.kakao.com/display/store-seller/',
      },
    },
  ],
  lotteon: [
    {
      type: 'guideImg',
      guideImageUrl: '',
    },
    {
      type: 'linkGuide',
      contents: {
        title: '롯데온 연동을 위해 API KEY가 필요해요!',
        mainText: '',
        linkButtonText: '롯데온 API KEY 발급받기',
        linkUrl: 'https://store.lotteon.com/cm/main/login_SO.wsp',
        videoId: 'yjbnJTd8H-Q',
      },
    },
  ],
  ably: [
    {
      type: 'linkGuide',
      contents: {
        title: '계정이 기억나지 않으신가요? 판매자 페이지에서 확인해 보세요',
        mainText: '',
        linkButtonText: '에이블리 판매자 페이지',
        linkUrl: 'https://my.a-bly.com/login?redirect=%2F',
      },
    },
  ],
  zigzag: [
    {
      type: 'linkGuide',
      contents: {
        title: '계정이 기억나지 않으신가요? 판매자 페이지에서 확인해 보세요',
        mainText: '',
        linkButtonText: '지그재그 판매자 페이지',
        linkUrl: 'https://partners.kakaostyle.com/login',
      },
    },
  ],
  melchi: [
    {
      type: 'linkGuide',
      contents: {
        title: '계정이 기억나지 않으신가요? 판매자 페이지에서 확인해 보세요',
        mainText: '',
        linkButtonText: '멸치쇼핑 판매자 페이지',
        linkUrl: 'http://www.about-smelchi.com/',
      },
    },
  ],
  imweb: [
    {
      type: 'linkGuide',
      contents: {
        title: '계정이 기억나지 않으신가요? 판매자 페이지에서 확인해 보세요',
        mainText: '',
        linkButtonText: '아임웹 판매자 페이지',
        linkUrl: 'https://imweb.me/login',
      },
    },
  ],
  sixshop: [
    {
      type: 'linkGuide',
      contents: {
        title: '계정이 기억나지 않으신가요? 판매자 페이지에서 확인해 보세요',
        mainText: '',
        linkButtonText: '식스샵 판매자 페이지',
        linkUrl: 'https://www.sixshop.com/dashboard/shop-home',
      },
    },
  ],
  alwayz: [
    {
      type: 'linkGuide',
      contents: {
        title: '계정이 기억나지 않으신가요? 판매자 페이지에서 확인해 보세요',
        mainText: '',
        linkButtonText: '올웨이즈 판매자 페이지',
        linkUrl: 'https://alwayzseller.ilevit.com/',
      },
    },
  ],
  'gmarket-new': [
    {
      type: 'guideImg',
      guideImageUrl: NewGmarketGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: 'G마켓 연동을 위해 셀링툴 설정이 필요해요!',
        mainText: (
          <span>
            ESM PLUS에서 셀링툴은 최대 5개 까지 선택할 수 있습니다.
            <br />
            셀링툴 업체 선택이 완료되지 않을 경우 연동에 실패할 수 있으니 셀링툴 설정을 해주세요!
          </span>
        ),
        linkButtonText: 'G마켓 셀링툴 설정하기',
        linkUrl: 'https://signin.esmplus.com/login',
      },
    },
  ],
  'auction-new': [
    {
      type: 'guideImg',
      guideImageUrl: NewAuctionGuideImg,
    },
    {
      type: 'linkGuide',
      contents: {
        title: '옥션 연동을 위해 셀링툴 설정이 필요해요!',
        mainText: (
          <span>
            ESM PLUS에서 셀링툴은 최대 5개 까지 선택할 수 있습니다.
            <br />
            셀링툴 업체 선택이 완료되지 않을 경우 연동에 실패할 수 있으니 셀링툴 설정을 해주세요!
          </span>
        ),
        linkButtonText: '옥션 셀링툴 설정하기',
        linkUrl: 'https://signin.esmplus.com/login',
      },
    },
  ],
};

export default MARKET_GUIDE_DATA;
