import axios from 'axios';
import * as Sentry from '@sentry/react';

// prod
const prod = 'https://api.cplat.io/v2';
// dev
const dev = 'https://devapi.cplat.io/v2';

// TODO 에러
export const baseURL = import.meta.env.MODE === 'development' ? dev : prod;

export const cplatApiV2 = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
});

// 버블링을 통한 최상단에서 network error 보고
cplatApiV2.interceptors.response.use(
  (res) => res,
  (err) => {
    Sentry.captureException(err, {
      tags: { key: 'network' },
    });
  },
);

/** sellkey api 호출 */
const prodSellkey = 'https://staging-api.cplat.io/v2';
const devSellkey = 'https://devapi.cplat.io/v2';

export const baseURLSellkey = import.meta.env.MODE === 'development' ? devSellkey : prodSellkey;

export const cplatApiV2Sellkey = axios.create({
  baseURL: baseURLSellkey,
  headers: { 'Content-Type': 'application/json' },
});

cplatApiV2Sellkey.interceptors.response.use(
  (res) => res,
  (err) => {
    Sentry.captureException(err, {
      tags: { key: 'network' },
    });
  },
);
