import { useEffect, useState } from 'react';

const useTimeCount = (time: number = 180) => {
  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  return { timeLeft, setTimeLeft };
};

export default useTimeCount;
