import {
  Toast,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/components/molecules/Toast/Toast';
import { useToast } from '@/hooks/useToast';

export const Toaster = () => {
  const { toasts, dismiss } = useToast();

  return (
    <ToastProvider swipeDirection="up">
      {toasts.map(({ id, icon, title, description, action, ...props }) => {
        return (
          <Toast key={id} {...props} duration={2000} onClick={() => dismiss()}>
            <div className="grid gap-1">
              {title && (
                <div className="flex items-center gap-[6px]">
                  {icon && <ToastTitle>{icon}</ToastTitle>}
                  <ToastTitle className="whitespace-pre-wrap">{title}</ToastTitle>
                </div>
              )}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
};
