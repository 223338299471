import { CPLAT_API_ROUTES, useSuspenseFetchs } from '@/api';
import { useResponsive } from '@/hooks/useResponsive';
import { ROUTES } from '@/routes';
import { cn } from '@/utils/tailwind';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import WarningYellowIcon from '@/assets/icons/WarningYellowIcon.svg?react';
import usePostMessage from '@/hooks/usePostMessage';

export type selectedStoreKeyType = {
  MarketId?: string;
  StoreId?: string;
};

interface IMarketSidebar {
  storeList: Array<Store>;
  selectedStoreKey: selectedStoreKeyType;
  setSelectedStoreKey: React.Dispatch<React.SetStateAction<selectedStoreKeyType>>;
  enableTotal: boolean;
}

const MarketSidebar = ({
  storeList,
  selectedStoreKey,
  setSelectedStoreKey,
  enableTotal,
}: IMarketSidebar) => {
  const { isPCSize } = useResponsive();
  const navigate = useNavigate();
  const { navigateApp } = usePostMessage();

  const [{ data: marketInfoRes }] = useSuspenseFetchs([
    { url: CPLAT_API_ROUTES.getMarketModel, option: { method: 'POST' } },
  ]);
  const { data: marketInfoList } = marketInfoRes as AxiosResponse<MarketInfo[]>;
  const addMarket = marketInfoList.at(-1);

  const isSelectedStore = (item: Store) => {
    return (
      selectedStoreKey.MarketId === String(item.MarketId) &&
      selectedStoreKey.StoreId === item.StoreId
    );
  };

  const HasFurtherAction = storeList.find((item) => item.HasInquiry || item.HasFurtherAction);

  if (!isPCSize) {
    return (
      <div className="flex gap-[6px] overflow-x-auto whitespace-nowrap bg-white px-[20px]	pb-[12px] scrollbar-hide">
        {enableTotal && (
          <div
            className={cn(
              'flex min-w-fit items-center rounded-[20px] border-[1px] border-gray-150 p-[8px] pr-[14px]',
              selectedStoreKey.StoreId === ''
                ? 'bg-gray-800  text-white Body5S14'
                : 'text-gray-700 Body6M14',
            )}
            onClick={() =>
              setSelectedStoreKey({
                MarketId: '0',
                StoreId: '',
              })
            }
          >
            <img src={addMarket?.MarketIconUrl} alt="icon" className="h-[20px] w-[20px]" />
            <span className="ml-[6px]">전체</span>
            {HasFurtherAction && (
              <span className="ml-[2px] h-[4px] w-[4px] self-start rounded-full bg-red-300"></span>
            )}
          </div>
        )}

        {storeList.map((item: Store) => {
          const key = `${item.MarketId} ${item.StoreId}`;
          return (
            <div
              key={key}
              className={cn(
                'flex min-w-fit items-center rounded-[20px] border-[1px] border-gray-150 p-[8px]',
                isSelectedStore(item)
                  ? 'bg-gray-800  text-white Body5S14'
                  : 'text-gray-700 Body6M14',
                item.IsReleasedStatus ? 'pr-[8px]' : 'pr-[14px]',
              )}
              onClick={() =>
                setSelectedStoreKey({
                  MarketId: String(item.MarketId),
                  StoreId: item.StoreId,
                })
              }
            >
              <img src={item.MarketIconUrl} alt="icon" className="h-[20px] w-[20px]" />
              <span className="ml-[6px]">{item.StoreName}</span>
              {item.IsReleasedStatus ? (
                <span className="ml-[6px]">
                  <WarningYellowIcon />
                </span>
              ) : (
                <>
                  {(item.HasFurtherAction || item.HasInquiry) && (
                    <span className="ml-[2px] h-[4px] w-[4px] self-start rounded-full bg-red-300"></span>
                  )}
                </>
              )}
            </div>
          );
        })}
        <div
          className={cn(
            'flex min-w-fit items-center gap-[6px] rounded-[20px] border-[1px] border-gray-150 p-[8px] pr-[14px] text-gray-700 Body6M14',
          )}
          onClick={() => navigateApp('MarketInfo')}
        >
          <img src={addMarket?.MarketIconUrl} alt="icon" className="h-[20px] w-[20px]" />
          <span>다른 마켓 연동하기</span>
        </div>
      </div>
    );
  }

  if (isPCSize) {
    return (
      <div className="flex h-fit w-[240px] flex-col gap-[4px] rounded-[20px] border-[1px] border-gray-200 bg-white p-[12px]">
        {enableTotal && (
          <div
            className={cn(
              'flex cursor-pointer items-center gap-[6px] rounded-[10px] px-[12px] py-[8px] hover:bg-gray-50 active:bg-gray-50',
              selectedStoreKey.StoreId === '' && 'cursor-default bg-gray-100',
            )}
            onClick={() =>
              setSelectedStoreKey({
                MarketId: '0',
                StoreId: '',
              })
            }
          >
            <img src={addMarket?.MarketIconUrl} alt="icon" className="h-[16px] w-[16px]" />
            <div className="relative flex">
              <span
                className={cn(
                  selectedStoreKey.StoreId === ''
                    ? 'text-gray-900 Body4B14'
                    : 'text-gray-700 Body6M14',
                )}
              >
                전체
              </span>
              {HasFurtherAction && (
                <span className="ml-[2px] h-[4px] w-[4px] rounded-full bg-red-300"></span>
              )}
            </div>
          </div>
        )}
        {storeList.map((item: Store) => {
          const key = `${item.MarketId} ${item.StoreId}`;
          return (
            <div
              key={key}
              className={cn(
                'flex cursor-pointer items-center gap-[6px] rounded-[10px] px-[12px] py-[8px] hover:bg-gray-50 active:bg-gray-50',
                isSelectedStore(item) && 'cursor-default bg-gray-100',
              )}
              onClick={() =>
                setSelectedStoreKey({
                  MarketId: String(item.MarketId),
                  StoreId: item.StoreId,
                })
              }
            >
              <img src={item.MarketIconUrl} alt="icon" className="h-[16px] w-[16px]" />
              <div
                className={cn(
                  'relative flex',
                  isSelectedStore(item) ? 'text-gray-900 Body4B14' : 'text-gray-700 Body6M14',
                  item.IsReleasedStatus && 'w-full items-center justify-between',
                )}
              >
                {item.StoreName}
                {item.IsReleasedStatus ? (
                  <WarningYellowIcon />
                ) : (
                  <>
                    {(item.HasFurtherAction || item.HasInquiry) && (
                      <span className="ml-[2px] h-[4px] w-[4px] rounded-full bg-red-300"></span>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
        <div
          className={
            'flex cursor-pointer items-center gap-[3px] rounded-[10px] px-[12px] py-[8px] hover:bg-gray-50 active:bg-gray-50'
          }
          onClick={() => navigate(ROUTES.market)}
        >
          <img src={addMarket?.MarketIconUrl} alt="icon" className="h-[16px] w-[16px]" />
          <span className={'text-gray-700 Body6M14'}>다른 마켓 연동하기</span>
        </div>
      </div>
    );
  }
};

export default MarketSidebar;
