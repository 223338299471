import { useState } from 'react';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button';
import Dropzone from '@/components/molecules/Dropzone';
import TextField from '@/components/molecules/TextField';
import { cn } from '@/utils/tailwind';
import { useForm } from 'react-hook-form';
import { useToast } from '@/hooks/useToast';
import { CPLAT_API_ROUTES, UploadSystem, usePost } from '@/api';
import { useAppSelector } from '@/redux/store';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/molecules/Dialog';
import { ROUTES } from '@/routes';

type LabelProps = React.InputHTMLAttributes<HTMLDivElement> & {
  label: string;
  children: React.ReactNode;
  required?: boolean;
};

type FormData = {
  title: string;
  context: string;
  attachments: File[];
};

const InputWithLabel = ({ label, required, children, className, ...props }: LabelProps) => (
  <div className={cn('flex items-center space-x-[30px] text-gray-800 c-b1', className)} {...props}>
    <div className="flex w-[100px] items-center self-start pt-[7px]">
      <p>{label}</p>
      {required && <span className="ml-[2px] text-red-500 c-t3">*</span>}
    </div>
    {children}
  </div>
);

const renderImages = (attachments: File[], handleRemoveAttachment: (name: string) => void) => {
  return attachments.map((file, index) => (
    <div key={index} className="relative h-[160px] w-[160px] overflow-hidden rounded-[4px]">
      <img
        src={URL.createObjectURL(file)}
        alt={`attachment-${index}`}
        className="object-contain"
        onLoad={() => URL.revokeObjectURL(URL.createObjectURL(file))} // 불필요한 메모리 사용 방지
      />
      <X
        onClick={() => handleRemoveAttachment(file.name)}
        className="absolute right-0 top-0 cursor-pointer bg-gray-700 object-cover p-[4px] text-white"
      />
    </div>
  ));
};

const InquiryContainer = () => {
  const user = useAppSelector((state) => state.user);
  const { register, watch, setValue, formState } = useForm<FormData>({
    defaultValues: {
      title: '',
      context: '',
      attachments: [],
    },
  });
  const navigate = useNavigate();

  const { mutate: inquiry, isPending } = usePost(CPLAT_API_ROUTES.inquiry);
  const [isImgUploading, setIsImgUploading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const { toast } = useToast();

  const formValues = watch();
  const { context, attachments, title } = formValues;

  const handleDrop = (newFiles: File[]) => {
    const updatedFiles = newFiles.filter(
      (newFile) => !attachments.some((existingFile) => existingFile.name === newFile.name),
    );

    // 30MB 초과시 업로드 불가
    const isOverSize = newFiles.reduce((curr, file) => (curr += file.size), 0) >= 30 * 1024 * 1024;

    if (isOverSize) {
      toast({ title: '업로드 용량이 30MB를 초과할 수 없습니다.', variant: 'error' });
      return;
    }

    // attachments 배열에 새로운 파일을 추가
    setValue('attachments', [...attachments, ...updatedFiles]);
  };

  const handleRemoveAttachment = (fileName: string) => {
    setValue(
      'attachments',
      attachments.filter((attachment) => attachment.name !== fileName),
    );
  };

  const handleInquiry = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const urls: string[] = [];
    for (const attachment of attachments) {
      setIsImgUploading(true);
      const { status, data } = await UploadSystem.uploadImgAsync(attachment);
      const { data: cplatData } = data as CplatApiResponse<{ ImageUrl: string }[]>;

      if (status === 200) {
        urls.push(cplatData[0].ImageUrl);
      } else {
        toast({
          title: '이미지 업로드 중 오류가 발생하였습니다.',
          variant: 'error',
        });
      }
    }

    setIsImgUploading(false);

    const body = {
      cplatToken: user.CplatToken,
      title,
      context,
      attachments: urls.join(','),
      serviceType: '셀러박스',
    };

    inquiry(body, {
      onSuccess() {
        setIsOpen(true);
      },
      onError(err) {
        toast({
          title: '업로드 중 오류가 발생하였습니다.',
          description: `code : ${err.code} message: ${err.message}`,
          variant: 'error',
        });
      },
    });
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
    navigate(ROUTES.inquiryHistory);
  };

  return (
    <>
      <form className="mt-[24px] space-y-[30px] whitespace-nowrap" onSubmit={handleInquiry}>
        <InputWithLabel label="제목" required>
          <TextField
            {...register('title', {
              required: true,
            })}
            maxLength={30}
            placeholder="제목을 입력해 주세요."
            className="h-[50px] rounded-[4px] border"
          />
        </InputWithLabel>
        <InputWithLabel label="내용" required>
          <div className="relative w-full">
            <textarea
              {...register('context', {
                required: true,
              })}
              maxLength={500}
              placeholder="내용을 500자 이내로 작성해 주세요."
              className="bg-background flex h-[200px] w-full resize-none  rounded-[4px] border border-gray-300 px-3 py-[12px] text-sm text-gray-900 c-b2 focus:border-gray-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
            />
            <p className="absolute bottom-0 right-0 p-[10px] text-gray-750 c-c2">
              {context.length}/500
            </p>
          </div>
        </InputWithLabel>
        <InputWithLabel label="파일첨부">
          <Dropzone
            height="64px"
            onDrop={handleDrop}
            acceptedFileTypes={['image/jpeg', 'image/jpg', 'image/png']}
          />
        </InputWithLabel>
        <div className="ml-[120px] flex space-x-3 overflow-auto">
          {renderImages(attachments, handleRemoveAttachment)}
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            className="w-[160px]"
            isLoading={isPending || isImgUploading}
            disabled={!formState.isValid}
          >
            문의등록
          </Button>
        </div>
      </form>
      <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <DialogContent className="flex !w-[380px] flex-col items-center p-[30px] text-center">
          <DialogTitle>문의 등록이 완료되었어요</DialogTitle>
          <DialogDescription>
            답변이 완료되면 셀러박스 앱에서
            <br />
            푸시알림으로 안내드려요.
          </DialogDescription>
          <Button variant="secondary" className="w-[200px]" onClick={handleCloseDialog}>
            확인
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InquiryContainer;
