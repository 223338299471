import { useEffect, useRef, useState } from 'react';
import useRiveAnimation from '@/hooks/useRiveAnimation';
import Title from '@/assets/images/endYear/Title.png';
import AccumulatedAmount from '@/assets/images/endYear/AccumulatedAmount.png';
import ProductSale from '@/assets/images/endYear/ProductSale.png';
import ProductRefund from '@/assets/images/endYear/ProductRefund.png';
import FavoriteCategory from '@/assets/images/endYear/FavoriteCategory.png';
import ReviewCount from '@/assets/images/endYear/ReviewCount.png';
import UpdateItem from '@/assets/images/endYear/UpdateItem.png';
import { cn } from '@/utils/tailwind';
import { logEvent } from '@/etc/firebase';

const YearEndPage = () => {
  const riveComponent9Ref = useRef<HTMLDivElement | null>(null);
  const riveComponent2Ref = useRef<HTMLDivElement | null>(null);
  const riveComponent3Ref = useRef<HTMLDivElement | null>(null);
  const riveComponent4Ref = useRef<HTMLDivElement | null>(null);
  const riveComponent5Ref = useRef<HTMLDivElement | null>(null);
  const riveComponent8Ref = useRef<HTMLDivElement | null>(null);
  const riveComponent11Ref = useRef<HTMLDivElement | null>(null);
  const riveComponent10Ref = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { RiveComponent: RiveComponent1, rive: rive1 } = useRiveAnimation('1', true);
  const { RiveComponent: RiveComponent2, rive: rive2 } = useRiveAnimation('2', false);
  const { RiveComponent: RiveComponent3, rive: rive3 } = useRiveAnimation('3', false);
  const { RiveComponent: RiveComponent4, rive: rive4 } = useRiveAnimation('4', false);
  const { RiveComponent: RiveComponent5, rive: rive5 } = useRiveAnimation('5', false);
  const { RiveComponent: RiveComponent6 } = useRiveAnimation('6', true);
  const { RiveComponent: RiveComponent7 } = useRiveAnimation('7', true);
  const { RiveComponent: RiveComponent8, rive: rive8 } = useRiveAnimation('8', false);
  const { RiveComponent: RiveComponent9, rive: rive9 } = useRiveAnimation('9', false);
  const { RiveComponent: RiveComponent10, rive: rive10 } = useRiveAnimation('10', false);
  const { RiveComponent: RiveComponent11, rive: rive11 } = useRiveAnimation('11', false);

  useEffect(() => {
    if (rive1?.isPlaying) {
      logEvent('year_end_enter_view');
      setIsLoading(false);
    }
  }, [rive1?.isPlaying]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive9 && rive9.play();
          setTimeout(() => {
            rive9 && rive9.pause();
          }, 3000);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent9Ref.current) {
      observer.observe(riveComponent9Ref.current);
    }

    return () => {
      if (riveComponent9Ref.current) {
        observer.unobserve(riveComponent9Ref.current);
      }
    };
  }, [rive9]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive2 && rive2.play();
          setTimeout(() => {
            rive2 && rive2.pause();
          }, 1000);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent2Ref.current) {
      observer.observe(riveComponent2Ref.current);
    }

    return () => {
      if (riveComponent2Ref.current) {
        observer.unobserve(riveComponent2Ref.current);
      }
    };
  }, [rive2]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive3 && rive3.play();
          setTimeout(() => {
            rive3 && rive3.pause();
          }, 2000);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent3Ref.current) {
      observer.observe(riveComponent3Ref.current);
    }

    return () => {
      if (riveComponent3Ref.current) {
        observer.unobserve(riveComponent3Ref.current);
      }
    };
  }, [rive3]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive4 && rive4.play();
          setTimeout(() => {
            rive4 && rive4.pause();
          }, 3000);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent4Ref.current) {
      observer.observe(riveComponent4Ref.current);
    }

    return () => {
      if (riveComponent4Ref.current) {
        observer.unobserve(riveComponent4Ref.current);
      }
    };
  }, [rive4]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive5 && rive5.play();
          setTimeout(() => {
            rive5 && rive5.pause();
          }, 1500);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent5Ref.current) {
      observer.observe(riveComponent5Ref.current);
    }

    return () => {
      if (riveComponent5Ref.current) {
        observer.unobserve(riveComponent5Ref.current);
      }
    };
  }, [rive5]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive8 && rive8.play();
          setTimeout(() => {
            rive8 && rive8.pause();
          }, 2000);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent8Ref.current) {
      observer.observe(riveComponent8Ref.current);
    }

    return () => {
      if (riveComponent8Ref.current) {
        observer.unobserve(riveComponent8Ref.current);
      }
    };
  }, [rive8]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive11 && rive11.play();
          setTimeout(() => {
            rive11 && rive11.pause();
          }, 3000);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent11Ref.current) {
      observer.observe(riveComponent11Ref.current);
    }

    return () => {
      if (riveComponent11Ref.current) {
        observer.unobserve(riveComponent11Ref.current);
      }
    };
  }, [rive11]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          rive10 && rive10.play();
          logEvent('year_end_last_scroll_view');
          setTimeout(() => {
            rive10 && rive10.pause();
          }, 3000);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (riveComponent10Ref.current) {
      observer.observe(riveComponent10Ref.current);
    }

    return () => {
      if (riveComponent10Ref.current) {
        observer.unobserve(riveComponent10Ref.current);
      }
    };
  }, [rive10]);

  return (
    <div
      className={cn('relative h-screen overflow-y-auto overflow-x-hidden bg-black', {
        hidden: isLoading,
      })}
    >
      {/* 그라데이션 영역 */}
      <div className="absolute w-full">
        <div className="ml-[-179px] mt-[-206px] h-[508px] w-[508px] bg-greenRadialGradient"></div>
        <div className="mx-auto mr-[-208px] mt-[11px] h-[508px] w-[508px] bg-purpleRadialGradient"></div>
        <div className="ml-[-159px] mt-[95px] h-[508px] w-[508px] bg-orangeRadialGradient"></div>
        <div className="mx-auto mr-[-193px] mt-[135px] h-[508px] w-[508px] bg-yellowRadialGradient"></div>
        <div className="ml-[-191px] mt-[289px] h-[508px] w-[508px] bg-shallowRedRadialGradient"></div>
        <div className="mx-auto mr-[-175px] mt-[443px] h-[508px] w-[508px] bg-pinkRadialGradient"></div>
        <div className="ml-[-147px] mt-[149px] h-[508px] w-[508px] bg-blueRadialGradient"></div>
        <div className="mx-auto mr-[-129px] mt-[7px] h-[508px] w-[508px] bg-emeraldRadialGradient"></div>
        <div className="mx-auto mb-[140px] mt-[303px] h-[508px] w-[508px] bg-deepRedRadialGradient"></div>
      </div>

      {/* 애니메이션 영역 */}
      <div className="absolute h-full w-full z-1">
        <div className="h-[723px] w-full">
          <RiveComponent1 />
        </div>
        <div className="mt-[396px] h-[212px] w-full" ref={riveComponent9Ref}>
          <RiveComponent9 />
        </div>
        <div className="mt-[183px] h-[320px] w-full" ref={riveComponent2Ref}>
          <RiveComponent2 />
        </div>
        <div className="mt-[40px] h-[230px] w-full" ref={riveComponent3Ref}>
          <RiveComponent3 />
        </div>
        <div className="mt-[186px] h-[425px] w-full" ref={riveComponent4Ref}>
          <RiveComponent4 />
        </div>
        <div className="mt-[190px] h-[75px] w-full" ref={riveComponent5Ref}>
          <RiveComponent5 />
        </div>
        <div className="mt-[140px] h-[375px] w-full">
          <RiveComponent6 />
        </div>
        <div className="mt-[254px] h-[219px] w-full">
          <RiveComponent7 />
        </div>
        <div className="mt-[211px] h-[316px] w-full" ref={riveComponent8Ref}>
          <RiveComponent8 />
        </div>
        <div className="mt-[356px] h-[445px] w-full" ref={riveComponent11Ref}>
          <RiveComponent11 />
        </div>
        <div className="mt-[200px] h-[189px] w-full" ref={riveComponent10Ref}>
          <RiveComponent10 />
        </div>
      </div>

      {/* 텍스트 영역 */}
      <div className="absolute flex h-full w-full flex-col z-1">
        <img src={Title} width={265} height={247} className="mt-[200px] flex self-center" />
        <div className="mt-[241px] flex self-center whitespace-pre text-center font-Cafe24Moyamoya text-[20px] leading-[24.6px] text-white">{`셀러박스를 사용하는 셀러님들의\n실제 2024년 누적 데이터입니다.`}</div>
        <img
          src={AccumulatedAmount}
          width={180}
          height={257}
          className="mt-[78px] flex self-center"
        />
        <img src={ProductSale} width={178} height={92} className="mt-[326px] flex self-center" />
        <img
          src={ProductRefund}
          width={174}
          height={91}
          className="mx-auto ml-[45%] mt-[372px] flex self-center"
        />
        <img
          src={FavoriteCategory}
          width={262}
          height={98}
          className="mt-[233px] flex self-center"
        />
        <div className="mt-[515px] whitespace-pre text-center font-Cafe24Moyamoya text-[40px] leading-[49.2px] text-[#FF89D4]">{`셀러박스에서\n확인한 리뷰`}</div>
        <img src={ReviewCount} width={153} height={60} className="mt-[93px] flex self-center" />
        <div className="mt-[174px] whitespace-pre text-center font-Cafe24Moyamoya text-[40px] leading-[49.2px] text-white">{`AI 리뷰 답변`}</div>
        <div className="mt-[15px] whitespace-pre text-center font-Cafe24Moyamoya text-[40px] leading-[49.2px] text-white">{`2만 번\n만들었어요`}</div>
        <div className="mt-[220px] whitespace-pre text-center font-Cafe24Moyamoya text-[25px] leading-[30.75px] text-white ">{`1월 오픈`}</div>
        <div className="mt-[10px] whitespace-pre text-center font-Cafe24Moyamoya text-[40px] leading-[49.2px] text-[#BD8DFF]">{`상품 순위 모니터링`}</div>
        <div className="whitespace-pre text-center font-Cafe24Moyamoya text-[40px] text-white">{`누적 7,000건`}</div>
        <div className="mt-[246px] whitespace-pre text-center font-Cafe24Moyamoya text-[25px] leading-[30.75px] text-white">{`8월 오픈`}</div>
        <div className="mt-[10px] whitespace-pre text-center font-Cafe24Moyamoya text-[40px] leading-[49.2px] text-[#BD8DFF]">{`상품 발송 처리`}</div>
        <div className="whitespace-pre text-center font-Cafe24Moyamoya text-[40px] text-white">{`1,900개 완료`}</div>
        <img src={UpdateItem} width={322} height={167} className="mt-[388px] flex self-center" />
        <div className="mt-[82px] whitespace-pre text-center font-Cafe24Moyamoya text-[36px] leading-[59px] text-white">{`어떤게 바꼈냐구요?`}</div>
        <div className="mt-[492px]">
          <div className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-[#FFB16C]">{`2024년 한 해,`}</div>
          <div className="flex justify-center">
            <span className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-[#FFB16C]">{`셀러박스`}</span>
            <span className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-white">{`를 사랑해주셔서`}</span>
          </div>
          <div className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-white">{`감사합니다!`}</div>
        </div>
        <div className="mt-[285px]">
          <div className="flex justify-center">
            <span className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-[#A981FF]">{`2025년`}</span>
            <span className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-white">{`에도`}</span>
          </div>
          <div className="flex justify-center">
            <span className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-[#A981FF]">{`셀러박스`}</span>
            <span className="whitespace-pre text-center font-Cafe24Moyamoya text-[30px] leading-[36.9px] text-white">{`와 함께해요!`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearEndPage;
