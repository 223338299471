import { Link } from 'react-router-dom';

import CsIcon from '@/assets/icons/CsIcon.svg?react';
import ArrowRegularForwardIcon from '@/assets/icons/ArrowRegularForwardIcon.svg?react';
import { ROUTES } from '@/routes';
import { cn } from '@/utils/tailwind';

type InquiryButtonProps = {
  className?: string;
  to?: string;
};

const InquiryButton = ({ className, to }: InquiryButtonProps) => {
  return (
    <div className={cn(className, 'flex items-center')}>
      <div className="flex h-[56px] w-[56px] items-center justify-center rounded-[50%] bg-gray-150">
        <CsIcon />
      </div>
      <div className="ml-[8px] p-[4px]">
        <p className="text-gray-800 c-b1">마켓 연동에 어려움을 겪고 계신가요?</p>
        <div className="flex items-center">
          <Link
            to={to ? to : ROUTES.inquiry}
            className="text-gray-750 transition-colors active:text-gray-500"
          >
            1:1 문의 하기
          </Link>
          <div>
            <ArrowRegularForwardIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryButton;
