import { MenuType } from '@/pages/Lab/LabMainPage';
import ArrowRegularForwardIcon from '@/assets/icons/ArrowRegularForwardIcon.svg?react';

type ListFillProps = {
  item: MenuType;
};

const ListFill = ({ item }: ListFillProps) => {
  const handleClick = () => {
    const data = JSON.stringify({
      navigateParams: item.params,
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };

  return (
    <div
      className="flex items-center justify-between rounded-[20px] bg-gray-150 px-[20px] py-[22px]"
      onClick={handleClick}
    >
      <div>
        <div className="text-gray-800 Body3S15">{item.title}</div>
        <div className="text-gray-600 Body6M14">{item.description}</div>
      </div>
      <ArrowRegularForwardIcon />
    </div>
  );
};

export default ListFill;
