import EmptyFaceIcon from '@/assets/icons/EmptyFaceIcon.svg?react';
import OrderCard from './OrderCard';
import { OrderForCard } from '@/pages/ProductShipmentPage';
import { cn } from '@/utils/tailwind';
import { useResponsive } from '@/hooks/useResponsive';
import Button from '@/components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes';

type OrderListContainerType = {
  list: OrderForCard[];
  IsSelectedStoreReleasedStatus: boolean; // 선택된 마켓의 연동해제 여부
};

const OrderListContainer = ({ list, IsSelectedStoreReleasedStatus }: OrderListContainerType) => {
  const { isPCSize } = useResponsive();
  const navigate = useNavigate();

  if (IsSelectedStoreReleasedStatus) {
    return (
      <div className="flex h-full flex-col justify-center">
        <div className="flex flex-col items-center justify-center">
          <EmptyFaceIcon />
          <p className="mt-[26px] text-center text-gray-750 Body1S16">
            스토어 연동이 해제되어
            <br />
            주문 내역을 불러올 수 없어요
          </p>
          <Button
            type="button"
            variant={'secondary'}
            className="mt-[20px] h-[44px] w-[200px] px-[23px] py-[11px] Body3S15"
            onClick={() => {
              if (isPCSize) {
                navigate(ROUTES.market);
              } else {
                const data = JSON.stringify({
                  navigateParams: {
                    key: 'RegisteredMarket',
                    name: 'RegisteredMarket',
                  },
                });
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
              }
            }}
          >
            다시 연동하기
          </Button>
        </div>
      </div>
    );
  }

  if (list.length === 0) {
    return (
      <div className="flex h-full flex-col justify-center">
        <div className="flex flex-col items-center justify-center">
          <EmptyFaceIcon />
          <p className="mt-[26px] text-gray-750 Body1S16">처리할 내역이 없어요</p>
          {!isPCSize && (
            <Button
              type="button"
              variant={'secondary'}
              className="mt-[20px] h-[44px] px-[23px] py-[11px] Body3S15"
              onClick={() => {
                const data = JSON.stringify({
                  navigateParams: {
                    key: 'Order',
                    name: 'Order',
                    params: {
                      initialIndex: 0,
                      initialCategory: 0,
                    },
                  },
                });
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
              }}
            >
              모든 마켓의 주문 확인하기
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex flex-col overflow-x-hidden',
        isPCSize ? 'gap-[12px] py-[16px]' : 'gap-[20px] pb-[40px]',
      )}
    >
      {list.map((item) => {
        const orderKey = `${item.OrderNo}${item.OrderItemNo}`;
        return <OrderCard key={orderKey} data={item} />;
      })}
    </div>
  );
};

export default OrderListContainer;
