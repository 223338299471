import { forwardRef } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
// import { Check } from 'lucide-react'; // 자체 icon으로 변경 가능

import { cn } from '@/utils/tailwind';

import CheckBoxIcon from '@/assets/icons/CheckboxIcon.svg?react';
import CheckedBoxIcon from '@/assets/icons/CheckedBoxIcon.svg?react';

const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, checked, id, ...props }, ref) => {
  return (
    <CheckboxPrimitive.Root
      id={id}
      ref={ref}
      className={cn(
        'focus-visible:ring-ring peer h-[20px] w-[20px] shrink-0 rounded-[50%] border-gray-300 text-gray-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:cursor-default disabled:opacity-50 data-[state=checked]:border-primary data-[state=checked]:bg-primary data-[state=checked]:text-white',
        className,
      )}
      checked={checked}
      {...props}
    >
      {checked ? <CheckedBoxIcon /> : <CheckBoxIcon />}
      {/* <Check
        className={cn(
          'h-[18px] w-[18px]',
          'text-current',
          'data-state=unchecked:text-gray-200',
          'data-state=checked:text-white',
        )}
      /> */}
    </CheckboxPrimitive.Root>
  );
});

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;
