import { forwardRef, useEffect } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import ArrowSmallDownwardIcon from '@/assets/icons/ArrowSmallDownwardIcon.svg?react';
import StarLargeEmptyIcon from '@/assets/icons/StarLargeEmptyIcon.svg?react';
import StarLargeFillIcon from '@/assets/icons/StarLargeFillIcon.svg?react';
import { cn } from '@/utils/tailwind';

interface SelectItemProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> {
  children: React.ReactNode;
  className?: string;
  selected?: boolean;
  isPrefer?: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, selected, isPrefer, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item
        className={cn(
          'w-full cursor-pointer select-none Body3S15',
          selected ? 'text-orange-500' : 'text-gray-800',
          className,
        )}
        {...props}
        ref={forwardedRef}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  },
);
SelectItem.displayName = 'SelectItem';

export type Data = {
  label: string;
  value: string;
  isPrefer?: boolean;
};

type SelectProps = {
  placeholder?: string;
  dataList: Array<Data>;
  selectedValue: string;
  onSelectedValueChange: (value: string) => void;
  onPreferChange?: (code: string) => void;
  open?: boolean;
  onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
} & React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>;

const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      placeholder,
      dataList,
      className,
      selectedValue,
      onSelectedValueChange,
      onPreferChange,
      open,
      onOpenChange,
      ...props
    },
    forwardedRef,
  ) => {
    // 이벤트 핸들러 정의
    const handlePreferChange = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      value: string,
    ) => {
      event.stopPropagation();
      onPreferChange && onPreferChange(value);
    };

    // body 요소에 적용되는 'pointer-events: none' style 제거
    useEffect(() => {
      const bodyElement = document.querySelector('body');
      if (bodyElement) {
        bodyElement.style.pointerEvents = 'auto';
      }
      return () => {
        if (bodyElement) {
          bodyElement.style.pointerEvents = '';
        }
      };
    }, []);

    return (
      <SelectPrimitive.Root
        value={selectedValue}
        onValueChange={onSelectedValueChange}
        open={open}
        onOpenChange={onOpenChange}
      >
        <SelectPrimitive.Trigger
          ref={forwardedRef}
          className={cn(
            'flex w-full items-center justify-between border-b-[1px] border-gray-200 bg-white pb-[13px] pr-[12px] text-gray-800 Body1S16',
            className,
          )}
          {...props}
        >
          {selectedValue ? (
            <SelectPrimitive.Value />
          ) : (
            <div className="text-gray-400 Body1S16">{placeholder}</div>
          )}

          <SelectPrimitive.Icon className="ml-2">
            <ArrowSmallDownwardIcon />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Content
          className="z-10 mt-2 max-h-[250px] w-[300px] overflow-y-auto rounded-b-[10px] bg-gray-100 p-[10px]"
          position="popper"
          sideOffset={-8}
        >
          <SelectPrimitive.Viewport>
            {dataList.map((item) => (
              <div
                className="flex items-center gap-[8px] rounded-[8px] p-[10px] hover:bg-white"
                key={item.value}
              >
                <div
                  className="cursor-pointer"
                  onClick={(event) => handlePreferChange(event, item.value)}
                >
                  {item.isPrefer ? <StarLargeFillIcon /> : <StarLargeEmptyIcon />}
                </div>
                <SelectItem
                  key={item.value}
                  value={item.value}
                  selected={selectedValue === item.value}
                  isPrefer={item.isPrefer}
                >
                  {item.label}
                </SelectItem>
              </div>
            ))}
          </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
      </SelectPrimitive.Root>
    );
  },
);

Select.displayName = 'Select';

export default Select;
