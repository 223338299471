import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';

import '@/index.css';
import { store } from '@/redux/store';
import { queryClient } from '@/api/queries';
import { router } from '@/routes';

import '@/etc/firebase';
import '@/etc/sentry';
import 'react-spring-bottom-sheet/dist/style.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </Provider>,
);
