import { Card } from '@/components/atoms/Card';

import AlarmInfoIcon from '@/assets/icons/AlarmInfoIcon.svg?react';
import { cn } from '@/utils/tailwind';
import { Link } from 'react-router-dom';

type MarketLinkProps = {
  marketIconUrl: string;
  marketName: string;
  marketNameEnglish: string;
};

const MarketLink = ({ marketIconUrl, marketName, marketNameEnglish }: MarketLinkProps) => (
  <Link to={`/market/register/${marketNameEnglish}`}>
    <div className="flex h-[48px] w-[180px] cursor-pointer items-center space-x-[8px] rounded-[20px] bg-gray-100 p-[10px] hover:bg-gray-200">
      <img src={marketIconUrl} alt="" className="h-[28px] w-[28px]" />
      <p className="text-gray-700 c-b3">{marketName}</p>
    </div>
  </Link>
);

type DeprecatedMarketInfoCardPrpos = {
  marketList: MarketInfo[];
  selectedMarketName: string;
};

const DeprecatedMarketInfoCard = ({
  marketList,
  selectedMarketName,
}: DeprecatedMarketInfoCardPrpos) => {
  // new 마켓 이름 deprecated된 마켓 이름은 (지원종료)가 붙기 때문에 new를 매칭해서 찾아줘야 합니다.
  // EsmPlus의 경우 new가 없이 사라짐
  const originName =
    marketList.find((market) => market.MarketNameEnglish === `${selectedMarketName}-new`)
      ?.MarketName || 'G마켓/옥션';
  // 새로운 마켓 연동페이지로 안내
  // esm 같은경우 지마켓,옥션 선택지가 주어짐
  let urls: MarketInfo[] = [];

  if (selectedMarketName === 'esmplus') {
    urls = marketList.filter(
      (market) =>
        market.MarketNameEnglish === 'auction-new' || market.MarketNameEnglish === 'gmarket-new',
    );
  } else {
    urls = marketList.filter((market) => market.MarketNameEnglish === `${selectedMarketName}-new`);
  }

  return (
    <Card className={cn('w-[850px] space-x-[10px] p-[30px]')}>
      <div>
        <div className="flex items-center space-x-[10px]">
          <AlarmInfoIcon />
          <div>
            <h3 className="text-gray-900 c-t3">아이디/패스워드 연동 방식 지원 종료 안내</h3>
          </div>
        </div>
        <div className="ml-[30px] mt-[8px] text-gray-800 c-b6">
          원활한 마켓 데이터 수집과 더 나은 서비스 제공을 위해 아이디/패스워드를 통한 연동 방식
          지원이 종료되었어요.
        </div>
      </div>
      <div className="mt-[20px] flex space-x-2">
        <strong className="text-gray-900 c-b1">1.</strong>
        <div>
          <div>
            <strong className="text-gray-900 c-b1">
              기존 아이디/패스워드로 연동하신 경우
              <br /> {originName} 연동을 해제한 뒤
            </strong>
            셀링툴 설정 가이드를 참고하여 <strong className="text-gray-900 c-b1">재연동</strong>을
            진행해 주세요.
          </div>
          <div className="mt-[8px] text-gray-700 c-b6">
            <p>· 기존 계정의 연동을 해제하지 않을 경우 데이터가 중복되어 보여질 수 있어요.</p>
            <p>· 연동을 희망하는 옥션 계정의 개별 설정이 필요해요.</p>
          </div>
        </div>
      </div>
      <div className="mt-[20px] flex space-x-2">
        <strong className="text-gray-900 c-b1">2.</strong>
        <div>
          <div>
            <strong className="text-gray-900 c-b1">신규 연동의 경우</strong> 옥션 셀링툴 설정
            가이드를 참고하여 연동을 진행해 주세요.
          </div>
          <div className="mt-[8px] text-gray-700 c-b6">
            <p>· 아래 {originName} 버튼을 선택하여 셀링툴 설정 가이드를 확인할 수 있어요.</p>
          </div>
        </div>
      </div>
      <div className="mt-[8px] flex space-x-[12px]">
        {urls.map((url) => (
          <MarketLink
            key={url.MarketSeqNo}
            marketIconUrl={url.MarketIconUrl}
            marketNameEnglish={url.MarketNameEnglish}
            marketName={url.MarketName}
          />
        ))}
      </div>
    </Card>
  );
};

export default DeprecatedMarketInfoCard;
