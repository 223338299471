import Button from '@/components/atoms/Button';
import { Card, CardContent } from '@/components/atoms/Card';

import SellkeyIcon from '@/assets/icons/SellkeyIcon.svg?react';
import SellkeyPromotionImg from '@/assets/images/SellkeyPromotion.png';
import { Link } from 'react-router-dom';

const PromotionSellkeyCard = () => {
  return (
    <Card className="flex h-[410px]">
      <CardContent className="flex h-[100%] flex-col justify-between pl-[50px] pt-[60px]">
        <div className="space-y-[6px]">
          <p className="text-gray-700 c-b3">클릭 한 번으로 키워드 분석을 해보세요!</p>
          <h3 className="text-gray-900 c-t2 [&>strong]:font-[700] [&>strong]:text-primary-500">
            셀러님들의 <strong>성장</strong>을 위해 셀키가
            <br />
            <strong>핵심 정보</strong>만 담았어요.
          </h3>
        </div>
        <div className="pb-[26px]">
          <p className="mb-[12px] text-gray-700 c-b5">로그인 하고 셀키 무료 체험해 보세요</p>
          <Link to="https://sellkey.sellerbox.io/" target="_blank">
            <Button className="h-[48px] w-[132px] c-b4" variant="secondary">
              <SellkeyIcon /> <span className="ml-[4px] c-b4">셀키 바로가기</span>
            </Button>
          </Link>
        </div>
      </CardContent>
      <CardContent>
        <img className="mt-[49px] h-[339px] w-[465px]" src={SellkeyPromotionImg} />
      </CardContent>
    </Card>
  );
};

export default PromotionSellkeyCard;
