// src/redux/codeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CodeState {
  first: string;
  second: string;
  third: string;
  fourth: string;
}

const initialState: CodeState = {
  first: '',
  second: '',
  third: '',
  fourth: '',
};

const codeSlice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    setFirst: (state, action: PayloadAction<string>) => {
      state.first = action.payload;
    },
    setSecond: (state, action: PayloadAction<string>) => {
      state.second = action.payload;
    },
    setThird: (state, action: PayloadAction<string>) => {
      state.third = action.payload;
    },
    setFourth: (state, action: PayloadAction<string>) => {
      state.fourth = action.payload;
    },
    resetAll: (state) => {
      state.first = '';
      state.second = '';
      state.third = '';
      state.fourth = '';
    },
  },
});

export const { setFirst, setSecond, setThird, setFourth, resetAll } = codeSlice.actions;
export default codeSlice.reducer;
