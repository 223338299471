import { createSlice } from '@reduxjs/toolkit';

// 로딩 상태 타입 정의
export type LoadingProps = {
  isLoading: boolean;
};

// 초기 상태 설정
const initialState: LoadingProps = {
  isLoading: false,
};

// 슬라이스 생성
const LoadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state: LoadingProps) => {
      state.isLoading = true;
    },
    stopLoading: (state: LoadingProps) => {
      state.isLoading = false;
    },
  },
});

// 액션 및 리듀서 내보내기
export const { startLoading, stopLoading } = LoadingSlice.actions;
export default LoadingSlice.reducer;
