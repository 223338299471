import { useEffect, useState } from 'react';
import { type CheckedState } from '@radix-ui/react-checkbox';

import { Card } from '@/components/atoms/Card';
import TextField from '@/components/molecules/TextField';
import Input from '@/components/atoms/Input';
import Button from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox';
import { cn } from '@/utils/tailwind';

import PwSeeIcon from '@/assets/icons/PwSeeIcon.svg?react';
import PwHideIcon from '@/assets/icons/PwHideIcon.svg?react';
import InfoFillGrayIcon from '@/assets/icons/InfoFillGrayIcon.svg?react';

type TermsFormData = {
  Key: string;
  GuideMessage: string;
  ReferenceMessage: string;
  ReferenceUrl: string;
  IsInputMandatory: boolean;
  values: CheckedState;
};

export type FormInput = ({
  viewEncryptIcon?: boolean;
  hasToBeEncrypted?: boolean;
  name: string;
} & React.InputHTMLAttributes<HTMLInputElement>)[];

type FormCardProps = {
  inputList: FormInput;
  className?: string;
  errors?:
    | (Record<
        string,
        Partial<{
          type: string | number;
          message: string;
        }>
      > &
        Partial<{
          type: string | number;
          message: string;
        }>)
    | undefined;
  agreementList?: TermsFormData[];
  isLoading?: boolean;
  disabled?: boolean;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  onCheckedChange?: (name: string, checked: CheckedState) => void;
  onClickMoreTerms?: (name: string) => void;
  onRememberPassword?: () => void;
};

// TODO 마켓등록, 비밀번호 변경, 마켓요청 등 form card 추상화 하기
const FormCard = (props: FormCardProps) => {
  const {
    className,
    inputList,
    errors,
    agreementList,
    disabled,
    isLoading,
    onSubmit,
    onCheckedChange,
    onClickMoreTerms,
    onRememberPassword,
  } = props;

  const [hideList, setHideList] = useState(
    inputList
      .filter((item) => item.hasToBeEncrypted)
      .reduce(
        (acc, { name }) => ({
          ...acc,
          [name]: true,
        }),
        {} as { [key: string]: boolean },
      ),
  );

  useEffect(() => {
    setHideList(
      inputList
        .filter((item) => item.hasToBeEncrypted)
        .reduce(
          (acc, { name }) => ({
            ...acc,
            [name]: true,
          }),
          {} as { [key: string]: boolean },
        ),
    );
  }, [inputList]);

  return (
    <Card className={cn('w-[410px] rounded-[20px] p-[40px]', className)}>
      <form onSubmit={onSubmit}>
        <div className="relative space-y-[24px]">
          {inputList.map(({ hasToBeEncrypted, viewEncryptIcon, ...props }) =>
            viewEncryptIcon ? (
              <TextField
                {...props}
                key={props.name}
                type={hideList[props.name] ? 'password' : 'text'}
                variant="outline"
                className="c-b2"
                InputProps={{
                  endAdornment: hideList[props.name] ? (
                    <PwHideIcon
                      onClick={() =>
                        setHideList({ ...hideList, [props.name]: !hideList[props.name] })
                      }
                      className="cursor-pointer"
                    />
                  ) : (
                    <PwSeeIcon
                      onClick={() =>
                        setHideList({ ...hideList, [props.name]: !hideList[props.name] })
                      }
                      className="cursor-pointer"
                    />
                  ),
                }}
              />
            ) : (
              <Input {...props} key={props.name} variant="outline" className="c-b2" />
            ),
          )}
          {errors?.message && (
            <p className="absolute bottom-[-25px] left-0 text-red c-c1">{errors.message}</p>
          )}
        </div>
        {onRememberPassword && (
          <div className="mt-[26px]">
            <Button
              type="button"
              onClick={onRememberPassword}
              variant="secondary"
              className="bg-primary-100 text-primary c-b6 hover:bg-primary-200 active:bg-primary-100"
            >
              비밀번호 불러오기
            </Button>
            <div className="mt-[9px] flex items-center space-x-[4px]">
              <InfoFillGrayIcon />
              <p className="text-gray-700 c-c1">
                마켓 연동 시 셀러박스에 직접 입력했던 비밀번호를 가져와요
              </p>
            </div>
          </div>
        )}
        <div className={onRememberPassword ? 'mt-[21px]' : 'mt-[48px]'}>
          {agreementList &&
            agreementList.map(({ Key, values, GuideMessage, ReferenceMessage }) => (
              <div key={Key} className="flex items-center space-x-[8px]">
                <Checkbox
                  checked={values}
                  onCheckedChange={(checked) => onCheckedChange && onCheckedChange(Key, checked)}
                />
                <p className="align-bottom text-gray-750 c-b2">{GuideMessage}</p>
                <a
                  className="cursor-pointer align-bottom text-gray-600 underline c-b6"
                  onClick={() => onClickMoreTerms && onClickMoreTerms(Key)}
                >
                  {ReferenceMessage}
                </a>
              </div>
            ))}
          <Button
            type="submit"
            className="mt-[12px] h-[52px] w-full rounded-[16px] c-b1"
            isLoading={isLoading}
            disabled={disabled}
          >
            연동하기
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default FormCard;
