import { useState, useEffect } from 'react';
import { CPLAT_API_ROUTES, usePost, useSuspenseFetchs } from '@/api';
import { useAppDispatch } from '@/redux/store';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { setUser } from '@/redux/userSlice';
import { ROUTES } from '@/routes';
import { AES } from '@/utils/AES';

const AdminPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [{ data: userInfoForDevSideRes }] = useSuspenseFetchs([
    { url: CPLAT_API_ROUTES.getUserInfoForDevSide, option: { method: 'POST' } },
  ]);
  const {
    data: [{ ServiceUserList }],
  } = userInfoForDevSideRes as AxiosResponse<UserInfoForDevSide[]>;
  const { mutate: signInByPass } = usePost(CPLAT_API_ROUTES.signinByPass);
  const [_, setCplatToken] = useSessionStorage('cplatToken', '');

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(ServiceUserList);
  const { mutate: getStoreList } = usePost(CPLAT_API_ROUTES.getStoreList);

  useEffect(() => {
    setFilteredUsers(
      ServiceUserList.filter(
        (user) =>
          user.UserNo.toString().includes(searchTerm) ||
          user.UserName.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, ServiceUserList]);

  const handleSignin = (token: string) => {
    // 로그인 api 및 회원가입 라우팅
    signInByPass(
      { CplatToken: token, isTest: true, osType: 'web', currentVersion: APP_VERSION },
      {
        onSuccess(res) {
          const { code, data } = res.data as CplatApiResponse<UserData[]>;

          if (code === '200') {
            const encryptedToken = AES.encryptData(data[0].CplatToken);
            setCplatToken(encryptedToken);
            dispatch(setUser(data[0]));

            getStoreList(
              {
                cplatToken: data[0].CplatToken,
              },
              {
                onSuccess({ data }) {
                  const { code } = data;
                  if (code === '200') {
                    navigate(ROUTES.productShipment);
                  } else {
                    navigate(`${ROUTES.market}?origin=signin`);
                  }
                },
              },
            );

            return;
          }
        },
      },
    );
  };

  return (
    <div className="p-4">
      <input
        type="text"
        placeholder="UserNo 또는 UserName 검색"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 w-[400px] rounded border border-gray-900 p-2"
      />
      <div className="max-h-[600px] overflow-y-scroll rounded border border-gray-300 p-4">
        <div className="grid grid-cols-4 gap-4">
          {filteredUsers.map((item) => (
            <button
              key={item.UserNo}
              className="rounded border border-gray-200 p-4 text-start shadow-sm"
              onClick={() => {
                handleSignin(item.CplatToken);
              }}
            >
              <p>
                <strong>UserNo:</strong> {item.UserNo}
              </p>
              <p>
                <strong>UserName:</strong> {item.UserName}
              </p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
