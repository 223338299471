export type Status = '신규문의' | '처리완료';

export interface InquiryListContainerProps {
  activedStatus: Status;
  list: Array<Inquiry>;
  isLoading: boolean;
  IsReleasedStatus: boolean;
  isPCSize: boolean;
  handleInquiryList: () => void;
}

export interface InquiryComment {
  InquiryComment: string;
  InquiryCommentDate: string;
}

export interface Inquiry {
  SellerId: number;
  MarketId: number;
  MarketName: string;
  MarketNameEnglish: string;
  StoreId: string;
  StoreName: string;
  CoupangWingId: string;
  InquiryNo: string;
  QnaTypeCode: string;
  Title: string;
  Content: string;
  InquiryDate: string;
  CheckeDate: string;
  CheckedYn: string;
  ProductNo: string;
  ProductItemNo: string;
  ProductName: string;
  WriterName: string;
  InquiryType: string;
  AttachmentsList: Array<string>;
  EsmToken: string;
  InquiryCommentList: Array<InquiryComment>;
  MarketIconUrl: string;
  SellerAdminPageUrl: string;
}

export const InquiryInitial = {
  SellerId: 0,
  MarketId: 0,
  MarketName: '',
  MarketNameEnglish: '',
  StoreId: '',
  StoreName: '',
  CoupangWingId: '',
  InquiryNo: '',
  QnaTypeCode: '',
  Title: '',
  Content: '',
  InquiryDate: '',
  CheckeDate: '',
  CheckedYn: '',
  ProductNo: '',
  ProductItemNo: '',
  ProductName: '',
  WriterName: '',
  InquiryType: '',
  AttachmentsList: [],
  EsmToken: '',
  InquiryCommentList: [],
  MarketIconUrl: '',
  SellerAdminPageUrl: '',
};

export interface InquiryCommentTemplate {
  TemplateNo: number;
  Title: string;
  Content: string;
  RegisterDate: string;
}

export const inquiryCommentTemplateInitial = {
  TemplateNo: 0,
  Title: '',
  Content: '',
  RegisterDate: '',
};

export interface InquiryCommentParams {
  CplatToken: string;
  MarketId: number;
  StoreId: string;
  InquiryNo: string;
  ProductNo: string;
  AnswerContent: string;
  QnaTypeCode: string;
  Cafe24Title: string;
  CoupangWingId: string;
  EsmToken: string;
  LotteonProductItemNo: string;
}
