import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InquiryState = {
  marketId: number;
  storeId: string;
  inquiryNo: string;
};

const initialState: InquiryState = {
  marketId: 0,
  storeId: '',
  inquiryNo: '',
};

export const InquiryState = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    setinquiryParams: (state: InquiryState, action: PayloadAction<InquiryState>) => {
      return { ...state, ...action.payload };
    },
    initInquiryParams: () => initialState,
  },
});

export const { initInquiryParams, setinquiryParams } = InquiryState.actions;
const InquiryReducers = InquiryState.reducer;

export default InquiryReducers;
