export * from './queries';
export * from './axios';
export * from './uploadSystem';

export const CPLAT_API_ROUTES = {
  getMarketModel: '/service/get_market_model',
  getUserInfo: '/user/get_user_info',
  getRegisteredMarketInfo: '/user/get_registered_market_account_info',
  sendAuthCode: '/user/send_authentication_code',
  verifyAuthCode: '/user/verify_authentication_code',
  signUpByPhoneNo: '/user/sign_up_by_phone_no',
  signInByPhoneNo: '/user/sign_in_by_phone_no',
  registerMarketAccount: '/user/register_market_account',
  unregisterMarket: '/user/unregister_market_account',
  updateMarketAlias: '/user/update_market_store_alias',
  signout: '/user/update_push_alert_token',
  unsubscribe: '/user/unsubscribe',
  signinByPass: '/user/sign_in_by_pass', // 토큰 유효성 검사 및 유저 데이터 획득
  getUserInfoForDevSide: '/service/get_user_info_for_dev_side', // dev용 유저 리스트 가져오기
  hasStoreName: '/user/has_store_name', // 닉네임 중복체크 200 or 614
  setUserInfo: '/user/set_user_info', // 유저 정보 수정
  uploadImage: '/file/upload_image', // 이미지 업로드 흠..
  tempSignIn: '/user/valid_temp_auth_code_for_login', // app 에서 4자리 코드 로그인
  inquiry: '/user/set_ask_info',
  getInquiry: '/user/get_ask_info', // 질문 내역 가져오기
  setAuthCodeByMarket: '/user/set_authentication_code_by_market', // 2차 인증 완료 + 연동 완료
  getStoreList: '/order/get_store_list', // 발송처리 가능한 스토어 중 연동된 마켓 리스트
  getOrderList: '/order/get_order_list', // getStoreList로 조회한 마켓의 주문 리스트
  confirmOrder: '/order/confirm_order', // 주문확인
  setInvoice: '/order/set_invoice', // 발송처리
  confirmCancellation: '/order/confirm_cancellation', // 취소승인
  declineCancellation: '/order/decline_cancellation', // 취소거절(강제발송)
  cancelBySeller: '/order/cancel_by_seller', // 셀러에 의한 주문취소
  getShippingCompanyList: '/user/get_shipping_company_list', // 택배사 및 즐겨찾기 여부 조회
  setShippingCompanyList: '/user/set_shipping_company_list', // 택배사 즐겨찾기 설정
  setInquiryCommentTemplate: '/user/set_inquiry_comment_template', // 문의 템플릿 추가/수정/삭제
  getInquiryCommentTemplate: '/user/get_inquiry_comment_template', // 문의 템플릿 조회
  updateMarketData: '/user/update_market_data', // 주문 수동 수집
  getInquiryList: '/inquiry/get_inquiry_list', // 문의 목록 조회
  getInquiryDetail: '/inquiry/get_inquiry_detail', // 문의 상세 조회
  generateAIRecommendedAnswer: '/inquiry/generate_ai_recommended_answer', // ai 답변 생성
  registerInquiryComment: '/inquiry/register_inquiry_comment', // 문의 답변 등록
};
