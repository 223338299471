import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type ToastActionElement, type ToastProps } from '@/components/molecules/Toast';

const TOAST_LIMIT = 5;

export type ToasterToast = ToastProps & {
  id: string;
  icon?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: ToastActionElement;
};

export type ToastState = {
  toasts: ToasterToast[];
};

const initialState: ToastState = {
  toasts: [],
};

const ToastState = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state: ToastState, action: PayloadAction<ToasterToast>) => {
      state.toasts = [action.payload, ...state.toasts].slice(0, TOAST_LIMIT);
    },

    updateToast: (state: ToastState, { payload }: PayloadAction<ToasterToast>) => {
      const toast = state.toasts.find((t) => t.id === payload.id);
      if (toast) {
        Object.assign(toast, payload);
      }
    },

    dismissToast: (state, { payload: toastId }: PayloadAction<string | undefined>) => {
      if (toastId) {
        const toast = state.toasts.find((t) => t.id === toastId);
        if (toast) toast.open = false;
      } else {
        state.toasts.forEach((toast) => {
          toast.open = false;
        });
      }
    },

    removeToast: (state, { payload: toastId }: PayloadAction<string>) => {
      state.toasts = state.toasts.filter((t) => t.id !== toastId);
    },
  },
});

export const { addToast, updateToast, dismissToast, removeToast } = ToastState.actions;
const CartReducer = ToastState.reducer;

export default CartReducer;
