import Button from '@/components/atoms/Button';
import HeaderLayout from '@/components/layouts/HeaderLayout';
import { Link, useNavigate } from 'react-router-dom';
import ArrowOrangeForwardIcon from '@/assets/icons/ArrowOrangeForwardIcon.svg?react';
import AppIntstallImg from '@/assets/images/AppIntstallImg.png';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/molecules/Accordion';
import { CPLAT_API_ROUTES, useSuspenseFetchs } from '@/api';
import { AxiosResponse } from 'axios';
import InfoFillGrayIcon from '@/assets/icons/InfoFillGrayIcon.svg?react';
import BulletList from '@/components/molecules/BulletList';
import { ROUTES } from '@/routes';

const NOTICE_DATA = [
  {
    title: '쿠팡 묶음배송건 처리 안내',
    description: [
      '쿠팡 정책에 따라 묶음배송으로 발송되는 상품들은 일괄로 처리할 수 있어요.',
      '묶음배송이란?\n고객이 주문한 상품 중, 동일한 출고지에서 발송되어 합배송이 가능한 건을 의미해요.',
    ],
  },
  {
    title: '11번가 묶음배송건 처리 안내',
    description: [
      '11번가 정책에 따라 묶음배송으로 발송되는 상품들은 일괄로 처리할 수 있어요.',
      '묶음배송이란?\n고객이 주문한 상품 중, 동일한 출고지에서 발송되어 합배송이 가능한 건을 의미해요.',
    ],
  },
  {
    title: '판매자 주문 취소 처리 안내',
    description: [
      '결제 완료 상품의 주문을 취소할 경우 마켓 정책에 따라 상품 품절로 인한 주문 거절로 사유가 등록돼요.\n*각 마켓 정책에 따라 상품이 품절 상태로 변경될 수 있습니다',
      '기타 사유로 주문 취소가 필요할 경우 마켓 판매자 페이지에서 직접 처리를 진행해 주세요.',
    ],
  },
  {
    title: '취소 요청건 거절 처리 안내',
    description: [
      '구매자의 취소 요청을 거절할 경우 마켓 정책에 따라 배송 진행중 상태로 간주되어 발송 정보 등록이 반드시 필요하며, 상품 배송 시작에 의한 취소 거절로 사유가 등록돼요.',
      '기타 사유로 취소 요청을 거절할 경우 마켓 판매자 페이지에서 직접 처리를 진행해 주세요.',
    ],
  },
];

const ENABLE_MARKET_NAME_ENGLISH_LIST = [
  'smartstore',
  'coupang',
  'gmarket-new',
  'auction-new',
  '11st',
  'cafe24',
  'talkstore',
  'imweb',
  'shopby',
  'lotteon',
];

const ProductShipmentGuidePage = () => {
  const navigate = useNavigate();

  const [{ data: marketInfoRes }] = useSuspenseFetchs([
    { url: CPLAT_API_ROUTES.getMarketModel, option: { method: 'POST' } },
  ]);
  const { data: marketInfoList } = marketInfoRes as AxiosResponse<MarketInfo[]>;
  const enableMarketInfoList = marketInfoList.filter((item) =>
    ENABLE_MARKET_NAME_ENGLISH_LIST.includes(item.MarketNameEnglish),
  );

  return (
    <HeaderLayout
      className="flex flex-col"
      title="상품 발송 이용 안내"
      onBack={() => navigate(-1)}
      isBottomPadding
    >
      <div className="mt-[30px] flex w-[850px] flex-col self-center rounded-[20px] border-[1px] border-gray-200 bg-white p-[30px]">
        <p className="mb-[20px] text-gray-800 Title2B20">
          이제 셀러박스에서
          <br />
          10개 마켓의 주문을 처리할 수 있어요
        </p>
        <div className="mb-[30px] items-center space-y-[12px] self-center">
          <div className="flex justify-center gap-[24px] rounded-[10px] bg-gray-50 px-[21px] py-[20px]">
            {enableMarketInfoList.map((item) => (
              <img
                key={item.MarketIconUrl}
                src={item.MarketIconUrl}
                className="h-[50px] w-[50px]"
              />
            ))}
          </div>
          <p className="ml-[37px] text-gray-500 Body6M14">
            *스마트스토어는 커머스솔루션마켓 구독 완료한 계정만 이용 가능해요.
            <br />
            *지마켓, 옥션은 셀링툴 업체 설정을 완료한 계정만 이용 가능해요.
          </p>
        </div>
        <div className="mb-[20px]">
          <p className="mb-[10px]">아직 연동하지 않은 마켓이 있다면?</p>
          <Link to={ROUTES.market}>
            <Button variant={'ivory'} className="px-[10px]">
              마켓 추가연동
              <ArrowOrangeForwardIcon />
            </Button>
          </Link>
        </div>
        <div className="mb-[30px]">
          <p className="mb-[20px]">앱에서 발송 처리 및 매출 내역을 확인할 수 있어요</p>
          <img src={AppIntstallImg} alt="image" className="rounded-[4px]" />
        </div>
        <div>
          <h2 className="mb-[10px] text-gray-800 Body1S16">도움말</h2>
          <div>
            <Accordion className="space-y-[10px]" collapsible type="single">
              {NOTICE_DATA.map((item, index) => {
                return (
                  <AccordionItem value={index.toString()} key={index} className="border-0">
                    <AccordionTrigger className="rounded-[8px] bg-gray-100 py-[12px] pl-[10px] pr-[14px] hover:bg-gray-150 hover:no-underline">
                      <div className="flex items-center gap-[4px]">
                        <InfoFillGrayIcon />
                        {item.title}
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="mt-[4px] rounded-[8px] bg-gray-50 px-[10px] py-[12px] pb-0">
                      <BulletList data={item.description} />
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default ProductShipmentGuidePage;
