import { Card } from '@/components/atoms/Card';
import { type GuideLink } from '@/contents/marketGuide';
import { cn } from '@/utils/tailwind';
import LinkButton from '@/components/molecules/LinkButton';
import AlarmInfoIcon from '@/assets/icons/AlarmInfoIcon.svg?react';
import YouTube from 'react-youtube';

type LinkGuideProps = {
  guide: GuideLink;
  className?: string;
};

const LinkGuide = ({ guide, className }: LinkGuideProps) => {
  const { linkUrl, title, mainText, linkButtonText, videoId } = guide.contents;
  const isViewLinkButton = linkButtonText !== undefined && linkButtonText !== '';

  return (
    <Card key={linkUrl} className={cn(className, 'flex space-x-[10px] p-[30px]')}>
      <AlarmInfoIcon />
      <div>
        <p className="text-gray-900 c-b1">{title}</p>
        <div className="mt-[8px] text-gray-800 c-b6">{mainText}</div>
        {isViewLinkButton && (
          <div className="mt-[20px]">
            <LinkButton to={linkUrl as string}>{linkButtonText}</LinkButton>
          </div>
        )}
        {videoId && (
          <div className="mt-[16px] h-[188px] w-[335px] overflow-hidden rounded-[12px] bg-black">
            <YouTube
              videoId={videoId}
              opts={{
                width: '335',
                height: '188',
              }}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default LinkGuide;
