import OrignAES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const secretKey = 'cirlcle+sellerbox';

export class AES {
  static encryptData(data: unknown) {
    if (!data || data === '') return '';
    const ciphertext = OrignAES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext;
  }
  static decryptData(ciphertext: string) {
    if (!ciphertext || ciphertext === '') return '';
    const bytes = OrignAES.decrypt(ciphertext, secretKey);
    const decryptedData = JSON.parse(bytes.toString(Utf8));
    return decryptedData;
  }
}
