import ArrowRegularBackwardIcon from '@/assets/icons/ArrowRegularBackwardIcon.svg?react';
import { cn } from '@/utils/tailwind';

type HeaderLayoutProps = {
  children: React.ReactNode;
  renderRight?: React.ReactNode;
  title: string;
  onBack?: () => void;
  isBottomPadding?: boolean;
  className?: string;
};

const HeaderLayout = ({
  children,
  onBack,
  title,
  renderRight,
  isBottomPadding,
  className,
}: HeaderLayoutProps) => {
  return (
    <div
      className={cn(`mt-[80px] ${isBottomPadding ? 'pb-[160px]' : ''} text-gray-800`, className)}
    >
      <div className="flex items-center justify-between">
        {onBack ? (
          <div
            onClick={() => onBack && onBack()}
            className="flex cursor-pointer space-x-[12px] self-start transition-colors active:text-gray-600"
          >
            <ArrowRegularBackwardIcon className="text-[20px]" />
            <p className="Title3S18">{title}</p>
          </div>
        ) : (
          <p className="Title3S18">{title}</p>
        )}
        <div>{renderRight}</div>
      </div>
      {children}
    </div>
  );
};

export default HeaderLayout;
