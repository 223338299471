import { formatDate } from '@/utils/parser';
import EmptyFaceIcon from '@/assets/icons/EmptyFaceIcon.svg?react';
import BadgeSquare from '@/components/atoms/BadgeSquare';
import {
  Inquiry,
  InquiryComment,
  InquiryCommentTemplate,
  InquiryInitial,
  InquiryListContainerProps,
} from '@/types/inquiry';
import WarningTriangleRedIcon from '@/assets/icons/WarningTriangleRedIcon.svg?react';
import usePostMessage from '@/hooks/usePostMessage';
import Button from '@/components/atoms/Button';
import { cn } from '@/utils/tailwind';
import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import CloseModalBlackIcon from '@/assets/icons/CloseModalBlackIcon.svg?react';
import CircleCloseIcon from '@/assets/icons/CircleCloseIcon.svg?react';
import AIRecommendButton from '@/assets/images/inquiry/AIRecommendButton.png';
import TemplateButton from '@/assets/images/inquiry/TemplateButton.png';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useAppSelector } from '@/redux/store';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/molecules/DropdownMenu';
import Checkbox from '@/components/atoms/Checkbox';
import { useLocalStorage } from 'usehooks-ts';
import { useToast } from '@/hooks/useToast';
import TextField from '@/components/molecules/TextField';
import EmptyBoxIcon from '@/assets/icons/EmptyBoxIcon.svg?react';

export const getElapsedTime = (
  inquiryDate: string,
): {
  elapsedTimeText: string;
  elapsedTimeColor: 'primary' | 'red';
} => {
  const currentTime = new Date();
  const inquiryTime = new Date(inquiryDate);

  const elapsedMilliseconds = currentTime.getTime() - inquiryTime.getTime();

  const elapsedHours = Math.floor(elapsedMilliseconds / (1000 * 60 * 60));

  if (elapsedHours < 1) {
    return {
      elapsedTimeText: '방금 전',
      elapsedTimeColor: 'primary',
    };
  } else if (elapsedHours >= 1 && elapsedHours < 24) {
    return {
      elapsedTimeText: `${elapsedHours}시간 경과`,
      elapsedTimeColor: 'primary',
    };
  } else {
    return {
      elapsedTimeText: `${elapsedHours}시간 경과`,
      elapsedTimeColor: 'red',
    };
  }
};

const InquiryListContainer = ({
  activedStatus,
  list,
  isLoading,
  IsReleasedStatus,
  isPCSize,
  handleInquiryList,
}: InquiryListContainerProps) => {
  const { toast } = useToast();
  const { navigateApp, openExternalUrl } = usePostMessage();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isCoupangModalOpen, setIsCoupangModalOpen] = useState<boolean>(false);
  const [isActivedItem, setIsActivedItem] = useState<Inquiry>(InquiryInitial);
  const { elapsedTimeText, elapsedTimeColor } = getElapsedTime(isActivedItem.InquiryDate);
  const [answerInput, setAnswerInput] = useState<string>('');
  const user = useAppSelector((state) => state.user);
  const [inquiryCommentTemplateList, setInquiryCommentTemplateList] = useState<
    Array<InquiryCommentTemplate>
  >([]);
  const [isInquirySubmitNeverAsk, setIsInquirySubmitNeverAsk] = useLocalStorage(
    'isInquirySubmitNeverAsk',
    false,
  );
  const [coupangInput, setCoupangInput] = useState<string>('');
  const [isCoupangError, setIsCoupangError] = useState<boolean>(false);
  const [inquiryCommentList, setInquiryCommentList] = useState<Array<InquiryComment>>([]);

  const { mutate: generateAIRecommendedAnswer, isPending: isPendingGenerateAIRecommendedAnswer } =
    usePost(CPLAT_API_ROUTES.generateAIRecommendedAnswer);
  const { mutate: getInquiryCommentTemplate } = usePost(CPLAT_API_ROUTES.getInquiryCommentTemplate);
  const { mutate: registerInquiryComment, isPending: isPendingRegisterInquiryComment } = usePost(
    CPLAT_API_ROUTES.registerInquiryComment,
  );
  const { mutate: getInquiryDetail } = usePost(CPLAT_API_ROUTES.getInquiryDetail);

  const handleInquiryDetail = (item: Inquiry) => {
    getInquiryDetail(
      {
        cplatToken: user.CplatToken,
        MarketId: Number(item.MarketId),
        StoreId: item.StoreId,
        inquiryNo: item.InquiryNo,
      },
      {
        onSuccess(res) {
          const code = res.data.code;
          if (code === '200') {
            const InquiryCommentList = res.data.data[0].InquiryList[0].InquiryCommentList;
            setInquiryCommentList(InquiryCommentList);
          } else {
            setInquiryCommentList([]);
          }
        },
      },
    );
  };

  const handleGenerateAIRecommendedAnswer = () => {
    generateAIRecommendedAnswer(
      {
        cplatToken: user.CplatToken,
        productName: isActivedItem.ProductName,
        inquiryContent: isActivedItem.Content,
        inquiryType: isActivedItem.InquiryType,
      },
      {
        onSuccess(res) {
          const { AiRecommendedAnswer } = res.data.data[0];
          setAnswerInput(AiRecommendedAnswer);
        },
      },
    );
  };

  const handleGetInquiryCommentTemplate = () => {
    getInquiryCommentTemplate(
      {
        cplatToken: user.CplatToken,
      },
      {
        onSuccess(res) {
          const code = res.data.code;
          if (code === '200') {
            const InquiryCommentTemplateList = res.data.data[0].InquiryCommentTemplateList;
            setInquiryCommentTemplateList(InquiryCommentTemplateList);
          } else {
            setInquiryCommentTemplateList([]);
          }
        },
      },
    );
  };

  const handleRegisterInquiryComment = (CoupangWingId: string) => {
    registerInquiryComment(
      {
        cplatToken: user.CplatToken,
        MarketId: Number(isActivedItem.MarketId),
        StoreId: isActivedItem.StoreId,
        inquiryNo: isActivedItem.InquiryNo,
        ProductNo: isActivedItem.ProductNo,
        AnswerContent: answerInput,
        QnaTypeCode: isActivedItem.QnaTypeCode,
        Cafe24Title: isActivedItem.Title,
        CoupangWingId,
        EsmToken: isActivedItem.EsmToken,
        LotteonProductItemNo: isActivedItem.ProductItemNo,
      },
      {
        onSuccess(res) {
          const { code, message } = res.data;
          if (code === '200') {
            toast({
              icon: '✅',
              title: '답변 등록이 완료되었어요',
              variant: 'dark',
            });
            setIsSubmitModalOpen(false);
            setIsCoupangModalOpen(false);
            setIsDetailModalOpen(false);
            setAnswerInput('');
            setIsCoupangError(false);
            handleInquiryList();
          } else if (code === '765') {
            toast({
              icon: '⚠',
              title: '문의 내역을 다시 불러올게요',
              variant: 'dark',
            });
            setIsSubmitModalOpen(false);
            setIsCoupangModalOpen(false);
            setIsDetailModalOpen(false);
            setAnswerInput('');
            setIsCoupangError(false);
            handleInquiryList();
          } else if (code === '766') {
            if (coupangInput) {
              setIsCoupangError(true);
            } else {
              setIsCoupangModalOpen(true);
            }
          } else {
            toast({
              icon: '⚠',
              title: message,
              variant: 'dark',
            });
            setIsSubmitModalOpen(false);
            setIsCoupangModalOpen(false);
            setIsDetailModalOpen(false);
            setAnswerInput('');
            setIsCoupangError(false);
          }
        },
      },
    );
  };

  useEffect(() => {
    handleGetInquiryCommentTemplate();
  }, []);

  if (!isLoading) {
    return (
      // '최근 7일 영역 만큼을 뺀 height 값
      <div className="flex h-[calc(100%-46px)] flex-col">
        {/* 연동해제 여부 */}
        {!IsReleasedStatus ? (
          <>
            {list.length > 0 ? (
              <>
                {!isPCSize && (
                  <div className="px-[20px] py-[12px] text-gray-500 Body6M14">최근 7일 기준</div>
                )}
                <div
                  className={cn(
                    'flex flex-col gap-[20px] px-[20px]',
                    isPCSize && 'gap-[12px] py-[16px]',
                  )}
                >
                  {list.map((item: Inquiry, index) => {
                    const { elapsedTimeText, elapsedTimeColor } = getElapsedTime(item.InquiryDate);
                    return (
                      <div
                        key={`${item} ${index}`}
                        onClick={() => {
                          if (isPCSize) {
                            if (item.QnaTypeCode === '긴급문의') {
                              window.open(item.SellerAdminPageUrl, '_blank');
                              return;
                            }
                            setIsActivedItem(item);
                            handleInquiryDetail(item);
                            setIsDetailModalOpen(true);
                            return;
                          }
                          if (item.QnaTypeCode === '긴급문의') {
                            openExternalUrl(item.SellerAdminPageUrl);
                          } else {
                            navigateApp('InquiryDetail', {
                              marketId: item.MarketId,
                              storeId: item.StoreId,
                              inquiryNo: item.InquiryNo,
                            });
                          }
                        }}
                        className={cn(
                          'flex flex-col gap-[2px]',
                          isPCSize &&
                            'cursor-pointer rounded-[10px] border-[1px] border-gray-150 bg-gray-50 p-[12px]',
                        )}
                      >
                        <div className="flex items-center gap-[8px]">
                          <div className="flex items-center gap-[6px]">
                            <img
                              src={item.MarketIconUrl}
                              alt="icon"
                              className="h-[20px] w-[20px]"
                            />
                            <span className="text-gray-750 Body6M14">{item.StoreName}</span>
                          </div>
                          <span
                            className={cn('text-gray-600 Caption1M12', isPCSize && '!Body6M14')}
                          >
                            {formatDate(item.InquiryDate)}
                          </span>
                          {activedStatus === '신규문의' ? (
                            <>
                              {item.QnaTypeCode === '긴급문의' ? (
                                <BadgeSquare variant={'red'} size="md">
                                  <div className="flex items-center gap-[2px]">
                                    <WarningTriangleRedIcon />
                                    긴급문의
                                  </div>
                                </BadgeSquare>
                              ) : (
                                <BadgeSquare variant={elapsedTimeColor} size="md">
                                  <div className="flex items-center gap-[2px]">
                                    {elapsedTimeColor === 'red' && <WarningTriangleRedIcon />}
                                    {elapsedTimeText}
                                  </div>
                                </BadgeSquare>
                              )}
                            </>
                          ) : (
                            <BadgeSquare variant={'gray'} size="md">
                              처리완료
                            </BadgeSquare>
                          )}
                        </div>

                        <div
                          className={cn(
                            'truncate text-gray-800 Body6M14',
                            isPCSize && 'mt-[2px] !Body3S15',
                          )}
                        >
                          {item.QnaTypeCode === '긴급문의'
                            ? '긴급 문의가 접수됐어요'
                            : item.ProductName}
                        </div>

                        {item.QnaTypeCode === '긴급문의' ? (
                          <div className="text-gray-800 Body6M14">판매자 페이지에서 확인하기</div>
                        ) : (
                          <>
                            <div
                              className={cn(
                                'truncate text-gray-800 Caption1M12',
                                isPCSize && 'mt-[2px] !Body6M14',
                              )}
                            >
                              [{item.QnaTypeCode}] {item.Title}
                            </div>
                            <div
                              className={cn(
                                'truncate text-gray-750 Caption1M12',
                                isPCSize && '!Body6M14',
                              )}
                            >
                              {item.Content}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="px-[20px] py-[12px] text-gray-500 Body6M14">최근 7일 기준</div>
                <div className="flex h-full flex-col items-center justify-center gap-[20px]">
                  <EmptyFaceIcon />
                  <div className="text-gray-750 Body1S16">처리할 문의가 없어요</div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="flex h-full flex-col items-center justify-center gap-[20px]">
            <EmptyFaceIcon />
            <div className="whitespace-pre text-center text-gray-750 Body1S16">{`스토어 연동이 해제되어\n문의 내역을 불러올 수 없어요`}</div>
            <Button
              type="button"
              variant={'secondary'}
              className="h-[44px] w-[130px]"
              onClick={() => {
                navigateApp('MarketInfo');
              }}
            >
              다시 연동하기
            </Button>
          </div>
        )}
        <Dialog open={isDetailModalOpen}>
          <DialogContent
            className="w-[640px] gap-0 p-[20px] focus-visible:outline-none"
            isLoading={isPendingGenerateAIRecommendedAnswer || isPendingRegisterInquiryComment}
          >
            <div className="flex items-center justify-between">
              <div className="text-gray-800 Body1S16">{isActivedItem.ProductName}</div>
              <span
                className="cursor-pointer"
                onClick={() => {
                  if (answerInput.length > 0) {
                    setIsCancelModalOpen(true);
                  } else {
                    setIsDetailModalOpen(false);
                    setAnswerInput('');
                  }
                }}
              >
                <CloseModalBlackIcon />
              </span>
            </div>
            <div className="mt-[16px] h-[1px] w-full bg-gray-150"></div>
            <div className="mt-[16px] flex items-center gap-[8px]">
              <div className="flex items-center gap-[6px]">
                <img src={isActivedItem.MarketIconUrl} alt="icon" className="h-[20px] w-[20px]" />
                <span className="text-gray-750 Body6M14">{isActivedItem.StoreName}</span>
              </div>
              <div className="h-[18px] w-[1px] bg-gray-150"></div>
              <span className="text-gray-750 Body6M14">
                {formatDate(isActivedItem.InquiryDate)}
              </span>
              <div className="h-[18px] w-[1px] bg-gray-150"></div>
              {isActivedItem.CheckedYn === 'N' ? (
                <BadgeSquare variant={elapsedTimeColor} size="md">
                  <div className="flex items-center gap-[2px]">
                    {elapsedTimeColor === 'red' && <WarningTriangleRedIcon />}
                    {elapsedTimeText}
                  </div>
                </BadgeSquare>
              ) : (
                <BadgeSquare variant={'gray'} size="md">
                  처리완료
                </BadgeSquare>
              )}
            </div>
            <div className="mt-[16px] max-h-[315px] min-h-[100px] overflow-y-auto">
              <div>
                [{isActivedItem.QnaTypeCode}] {isActivedItem.Title}
              </div>
              <div>{isActivedItem.Content}</div>
              {Array.isArray(isActivedItem.AttachmentsList) &&
                isActivedItem.AttachmentsList.length > 0 && (
                  <div className="mt-[12px] flex items-center gap-[6px]">
                    {isActivedItem.AttachmentsList.map((item) => (
                      <img
                        src={item}
                        className="h-[160px] w-[160px] rounded-[4px] border-[1px] border-gray-150"
                      />
                    ))}
                  </div>
                )}
            </div>
            <div className="mt-[20px] w-full rounded-[20px] border-[1px] border-gray-200 p-[16px]">
              {isActivedItem.CheckedYn === 'N' ? (
                <>
                  <textarea
                    className=" h-[94px] w-full resize-none text-gray-900 Body6M14 focus-visible:outline-none"
                    placeholder="답변을 500자 이내로 작성해 주세요"
                    value={answerInput}
                    onChange={(e) => {
                      // 연속된 2개의 공백을 1개의 공백으로 치환, 공백 남용 방지
                      const cleanedInput = e.target.value.replace(/\s{2,}/g, ' ');
                      setAnswerInput(cleanedInput);
                    }}
                    maxLength={500}
                  ></textarea>
                  <div className="mt-[18px] flex w-full items-center justify-between">
                    <div className="flex items-center gap-[7px]">
                      <span
                        onClick={() => {
                          handleGenerateAIRecommendedAnswer();
                        }}
                        className="cursor-pointer"
                      >
                        <img src={AIRecommendButton} className="h-[36px]" />
                      </span>
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          className={cn(
                            inquiryCommentTemplateList.length === 0 && 'pointer-events-none',
                          )}
                        >
                          <span
                            className={cn(inquiryCommentTemplateList.length === 0 && 'opacity-30')}
                          >
                            <img src={TemplateButton} className="h-[36px]" />
                          </span>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="max-h-[240px] overflow-y-auto">
                          {inquiryCommentTemplateList.map((item) => (
                            <span
                              key={item.TemplateNo}
                              onClick={() => {
                                setAnswerInput(item.Content);
                              }}
                            >
                              <DropdownMenuItem>{item.Title}</DropdownMenuItem>
                            </span>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    <Button
                      type="button"
                      variant={'default'}
                      className="h-[38px] w-[61px]"
                      onClick={() => {
                        if (isInquirySubmitNeverAsk) {
                          handleRegisterInquiryComment(isActivedItem.CoupangWingId);
                        } else {
                          setIsSubmitModalOpen(true);
                        }
                      }}
                      disabled={answerInput.length < 10}
                    >
                      등록
                    </Button>
                  </div>
                </>
              ) : (
                <div className="h-full">
                  {/* 답변 O */}
                  {inquiryCommentList.length > 0 ? (
                    <div>
                      <div className="mb-[8px] text-gray-800 Body1S16">답변</div>
                      {inquiryCommentList.length > 0 && (
                        <div className="max-h-[132px] overflow-y-auto text-gray-700 Body2M16">
                          {inquiryCommentList.map((item) => (
                            <div key={item.InquiryCommentDate}>{item.InquiryComment}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    // 답변 X
                    <div className="flex h-full flex-col items-center justify-center gap-[20px] py-[60px]">
                      <EmptyBoxIcon />
                      <div className="text-gray-750 Body1S16">
                        판매자 페이지에서 답변을 완료했어요
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={isSubmitModalOpen}>
          <DialogContent
            className="max-w-[315px] gap-0 p-[20px] pt-[30px]"
            isLoading={isPendingRegisterInquiryComment}
          >
            <div className="mb-[30px] flex flex-col gap-[16px]">
              <div className="text-gray-900 Title3S18">답변을 등록할게요</div>
              <div className="whitespace-pre text-gray-750 Body6M14">{`등록 이후 수정 및 삭제가 필요할 경우\n마켓 판매자 페이지에서 진행해 주세요.`}</div>
              <div
                className="flex items-center gap-[8px]"
                onClick={() => {
                  setIsInquirySubmitNeverAsk(!isInquirySubmitNeverAsk);
                }}
              >
                <Checkbox checked={isInquirySubmitNeverAsk} />
                <span className="text-gray-800 Body8M14">다음부터 바로 등록하기</span>
              </div>
            </div>

            <div className="flex justify-center gap-[10px]">
              <Button
                type="button"
                variant={'secondary'}
                className="h-[44px] w-[130px]"
                onClick={() => {
                  setIsSubmitModalOpen(false);
                }}
              >
                닫기
              </Button>
              <Button
                type="button"
                variant={'default'}
                className="h-[44px] w-[130px]"
                onClick={() => {
                  handleRegisterInquiryComment(isActivedItem.CoupangWingId);
                }}
              >
                등록하기
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={isCancelModalOpen}>
          <DialogContent className="max-w-[315px] gap-0 p-[20px] pt-[30px]">
            <div className="mb-[30px] flex flex-col gap-[16px]">
              <div className="text-gray-900 Title3S18">답변 등록을 취소할까요?</div>
              <div className="text-gray-750 Body6M14">{`지금 등록을 취소할 경우 작성한 내용이 저장되지 않아요.`}</div>
            </div>

            <div className="flex justify-center gap-[10px]">
              <Button
                type="button"
                variant={'secondary'}
                className="h-[44px] w-[130px]"
                onClick={() => {
                  setIsCancelModalOpen(false);
                }}
              >
                닫기
              </Button>
              <Button
                type="button"
                variant={'default'}
                className="h-[44px] w-[130px]"
                onClick={() => {
                  setIsCancelModalOpen(false);
                  setIsDetailModalOpen(false);
                  setAnswerInput('');
                }}
              >
                취소하기
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={isCoupangModalOpen}>
          <DialogContent
            className="max-w-[315px] gap-0 p-[20px] pt-[30px]"
            isLoading={isPendingRegisterInquiryComment}
          >
            <div className="mb-[30px] flex flex-col gap-[16px]">
              <div className="text-gray-900 Title3S18">쿠팡 ID를 입력해 주세요</div>
              <div className="whitespace-pre text-gray-750 Body6M14">{`문의 답변 기능 사용을 위해 ID 등록이 필요해요.\n이 작업은 쿠팡 계정 별 최초 1번만 진행돼요.`}</div>
              <div>
                <TextField
                  variant={'filled'}
                  placeholder="아이디를 입력하세요"
                  value={coupangInput}
                  onChange={(e) => setCoupangInput(e.target.value)}
                  InputProps={{
                    endAdornment: coupangInput.length > 0 && (
                      <span>
                        <CircleCloseIcon
                          className="cursor-pointer"
                          onClick={() => setCoupangInput('')}
                        />
                      </span>
                    ),
                  }}
                />
                {isCoupangError && (
                  <div className="mt-[4px] text-red-600 Body5S14">아이디가 일치하지 않습니다.</div>
                )}
              </div>
            </div>

            <div className="flex justify-center gap-[10px]">
              <Button
                type="button"
                variant={'secondary'}
                className="h-[44px] w-[130px]"
                onClick={() => {
                  setIsCoupangModalOpen(false);
                  setCoupangInput('');
                }}
              >
                닫기
              </Button>
              <Button
                type="button"
                variant={'default'}
                className="h-[44px] w-[130px]"
                onClick={() => {
                  handleRegisterInquiryComment(coupangInput);
                }}
              >
                완료
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
};

export default InquiryListContainer;
