import { Link, useNavigate } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import HeadphoneIcon from '@/assets/icons/HeadphoneIcon.png';
import ArrowRegularForwardIcon from '@/assets/icons/ArrowRegularForwardIcon.svg?react';
import { ROUTES } from '@/routes';
import InquiryContainer from '@/containers/InquiryContainer';
import HeaderLayout from '@/components/layouts/HeaderLayout';

const InquiryPage = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(ROUTES.market);

  return (
    <HeaderLayout
      onBack={handleBack}
      title="1:1 문의하기"
      renderRight={
        <Link to={ROUTES.inquiryHistory}>
          <Button className="h-[52px] space-x-[12px] rounded-[31px] bg-gray-150 text-gray-700 hover:bg-gray-200 active:bg-gray-150">
            <img className="w-[20px]" src={HeadphoneIcon} />
            <p className="c-b1">문의 내역 보기</p>
            <ArrowRegularForwardIcon />
          </Button>
        </Link>
      }
    >
      <div className="mb-[150px] pl-[6px]">
        <p className="mt-[22px] text-gray-750 c-b2">
          문의내용은 업무시간 내에 최대한 답변드려요! <br />
          오류 등으로 저희가 보완할 부분이 있으면 답변이 지연될 수 있어요. <br />
          고객센터 운영 시간 - 평일 10:00 ~ 18:00
        </p>
        <InquiryContainer />
      </div>
    </HeaderLayout>
  );
};

export default InquiryPage;
