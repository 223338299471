import RegisteredMarketContainer from './RegisteredMarketContainer';
import MarketInfoListContainer from './MarketInfoListContainer';
import BadgeSquare from '@/components/atoms/BadgeSquare';
import ArrowOrangeForwardIcon from '@/assets/icons/ArrowOrangeForwardIcon.svg?react';
import Button from '@/components/atoms/Button';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/routes';

type MarketInfoContainerProps = {
  registedMarketList: RegisteredMarketInfo[];
  marketInfoList: MarketInfo[];
  isEmptyRegistedMarket: boolean;
};

const MarketInfoContainer = ({
  marketInfoList,
  registedMarketList,
  isEmptyRegistedMarket,
}: MarketInfoContainerProps) => {
  return (
    <div className="w-[1290px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h2 className="mr-[12px] text-gray-800 Body1S16">마켓 추가하기</h2>
          <BadgeSquare variant={'blue'} size="sm" className="mr-[8px]">
            PLUS
          </BadgeSquare>
          <p className="text-gray-600 Body6M14">상품 발송 및 문의글 처리가 가능해요</p>
        </div>
        <Link to={ROUTES.functionGuide}>
          <Button variant={'ivory'}>
            마켓별 지원 기능 확인하기
            <ArrowOrangeForwardIcon />
          </Button>
        </Link>
      </div>
      <div className="mt-[12px] pb-[140px]">
        {/* 마켓 추가하기 */}
        <MarketInfoListContainer marketInfoList={marketInfoList} isRegistered={false} />
        {/* 연동된 마켓 */}
        <RegisteredMarketContainer
          className="mt-[56px]"
          registeredMarketList={registedMarketList}
          isEmptyRegistedMarket={isEmptyRegistedMarket}
        />
      </div>
    </div>
  );
};

export default MarketInfoContainer;
