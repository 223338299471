import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '@/components/atoms/Button';
import { Card, CardContent } from '@/components/atoms/Card';
import Input from '@/components/atoms/Input';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/molecules/Avatar';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { CPLAT_API_ROUTES, UploadSystem, usePost } from '@/api';
import { useToast } from '@/hooks/useToast';
import { setUser } from '@/redux/userSlice';

import BasicUserprofileIcon from '@/assets/icons/BasicUserprofileIcon.svg?react';

type FormData = {
  newStoreName: string; // 원하는 스토어 이름 아직 중복검사 x
  checkedNewStoreName: string; // 중복검사를 통과한 새로운 스토어 이름
  profileImgUrl: string;
  profileFile?: File;
};

const MyProfileContainer = () => {
  const user = useAppSelector((state) => state.user);
  const { StoreName, StoreThumbnailImageUrl } = user;
  const { register, watch, setError, formState, setValue } = useForm<FormData>({
    defaultValues: {
      checkedNewStoreName: '',
      newStoreName: StoreName,
      profileImgUrl: StoreThumbnailImageUrl,
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isImgUploading, setIsImgUploading] = useState(false);

  const { toast } = useToast();

  const formValues = watch();
  const { newStoreName, checkedNewStoreName, profileImgUrl, profileFile } = formValues;
  const { errors } = formState;

  const { mutate: checkDuplicateStoreName } = usePost(CPLAT_API_ROUTES.hasStoreName);
  const { mutate: setUserInfo, isPending } = usePost(CPLAT_API_ROUTES.setUserInfo);
  const { mutate: getUserInfo, isPending: isGetUserPending } = usePost(
    CPLAT_API_ROUTES.getUserInfo,
  );

  const dispatch = useAppDispatch();

  const isChangeName = newStoreName === checkedNewStoreName;
  const isChangeImg = StoreThumbnailImageUrl !== profileImgUrl;

  const handleDuplicateStoreName = () => {
    if (newStoreName.length >= 3) {
      checkDuplicateStoreName(
        { storeName: newStoreName },
        {
          onSuccess(res) {
            const { code } = res.data as CplatApiResponse<string>;

            // 중복이 아닐 경우
            if (code === '200') {
              setError('newStoreName', { type: 'ok', message: '사용 가능한 닉네임입니다' });
              setValue('checkedNewStoreName', newStoreName);

              setTimeout(() => {
                setError('newStoreName', {});
              }, 3000);
            }

            // 중복일 경우
            if (code === '614') {
              setError('newStoreName', {
                type: 'duplicate',
                message: '이미 사용중인 닉네임입니다',
              });

              setTimeout(() => {
                setError('newStoreName', {});
              }, 3000);
            }
          },
        },
      );
    }
  };

  const handleChnageProfileImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast({
          title: '10MB 크기 이상 업로드할 수 없습니다.',
          variant: 'error',
        });
        return;
      }

      const url = URL.createObjectURL(e.target.files[0]);
      setValue('profileImgUrl', url);
      setValue('profileFile', e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    let body = { ...user };

    if (isChangeName) body = { ...body, StoreName: checkedNewStoreName };
    if (isChangeImg && profileFile) {
      setIsImgUploading(true);
      const { status, data } = await UploadSystem.uploadImgAsync(profileFile);

      if (status === 200) {
        const { data: cplatData } = data as CplatApiResponse<{ ImageUrl: string }[]>;
        body = { ...body, StoreThumbnailImageUrl: cplatData[0].ImageUrl };
        setIsImgUploading(false);
      } else {
        toast({
          title: '이미지 업로드 중 오류가 발생하였습니다.',
          variant: 'error',
        });
        setIsImgUploading(false);
        return;
      }
    }

    if (isChangeImg || isChangeName) {
      setUserInfo(body, {
        onSuccess(res) {
          const { code } = res.data as CplatApiResponse<undefined>;

          // 성공시 새로운 user info 패치
          if (code === '200') {
            getUserInfo(
              { cplatToken: user.CplatToken },
              {
                onSuccess(res) {
                  const { data } = res.data as CplatApiResponse<UserData[]>;
                  setError('newStoreName', {});
                  setValue('checkedNewStoreName', '');
                  setValue('profileFile', undefined);
                  setValue('profileImgUrl', data[0]?.StoreThumbnailImageUrl);

                  if (code === '200') {
                    if (data.length === 0) {
                      toast({
                        title: '인증이 만료되었습니다. sellerbox app을 통해 다시 인증해주세요.',
                        variant: 'error',
                      });
                    } else {
                      dispatch(setUser({ ...data[0] }));
                    }
                  } else {
                    toast({
                      title: '인증이 만료되었습니다. sellerbox app을 통해 다시 인증해주세요.',
                      variant: 'error',
                    });
                  }
                },
              },
            );
          } else {
            toast({
              title: '업로드 중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요',
              variant: 'error',
            });
          }
        },
      });
    }

    setIsOpen(false);
  };

  return (
    <>
      <div className="flex justify-center">
        <Card className="mt-[20px] w-[630px]">
          <CardContent className="p-[40px]">
            <div className="mt-[16px] flex">
              <p className="c-b1">프로필 사진 변경</p>
              <div className="ml-[45px] flex items-center">
                {formValues.profileImgUrl !== '' ? (
                  <Avatar className="h-[120px] w-[120px]">
                    <AvatarImage src={formValues.profileImgUrl} alt="프로필 이미지" />
                    <AvatarFallback>
                      <BasicUserprofileIcon />
                    </AvatarFallback>
                  </Avatar>
                ) : (
                  <Avatar className="flex h-[120px] w-[120px] items-center justify-center bg-gray-150">
                    <BasicUserprofileIcon />
                  </Avatar>
                )}
                <label className="ml-[20px] inline-flex h-[38px] w-[88px] cursor-pointer items-center justify-center rounded-[12px] border border-gray-200 bg-white text-gray-700 c-b5 hover:bg-gray-100 active:bg-white">
                  사진 변경
                  <input
                    accept="image/png image/jpeg image/jpg"
                    id="fileInput"
                    className="hidden"
                    type="file"
                    onChange={handleChnageProfileImg}
                  />
                </label>
              </div>
            </div>
            <div className="relative mt-[30px] flex items-center">
              <p className="whitespace-nowrap c-b1">닉네임 변경</p>
              <Input
                {...register('newStoreName', {
                  validate: (value) => value.length >= 15,
                })}
                className="ml-[77px] w-[240px]"
                variant="outline"
                maxLength={15}
              />
              <Button
                disabled={newStoreName === user.StoreName}
                onClick={handleDuplicateStoreName}
                className="ml-[12px] h-[38px] w-[85px] rounded-[12px] c-b5"
                variant="outline"
              >
                중복확인
              </Button>
              {errors.newStoreName?.message && (
                <p
                  className={`${
                    errors.newStoreName.type === 'duplicate' ? 'text-red-600' : 'text-blue-400'
                  } absolute bottom-[-24px] left-[150px] c-b6`}
                >
                  {errors.newStoreName.message}
                </p>
              )}
            </div>
            <div className="mt-[40px] flex justify-center space-x-[8px]">
              <Button variant="outline" className="h-[38px] w-[61px] rounded-[12px] c-b4">
                취소
              </Button>
              <Button
                disabled={!isChangeName && !isChangeImg}
                onClick={() => setIsOpen(true)}
                className="h-[38px] w-[61px] rounded-[12px] c-b4"
              >
                적용
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <Dialog open={isOpen} onOpenChange={(flag) => setIsOpen(flag)}>
        <DialogContent className="text-center">
          <p className="text-gray-900 c-t3">프로필을 적용하시겠습니까?</p>
          <p className="text-gray-700 c-b2">입력하신 내용으로 프로필이 변경됩니다.</p>
          <div className="flex justify-center space-x-[10px]">
            <Button variant="secondary" className="w-[110px]" onClick={() => setIsOpen(false)}>
              취소
            </Button>
            <Button
              isLoading={isPending || isImgUploading || isGetUserPending}
              className="w-[110px]"
              onClick={handleSubmit}
            >
              확인
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MyProfileContainer;
