import { type CheckedState } from '@radix-ui/react-checkbox';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox';
import { useAppDispatch } from '@/redux/store';
import { ROUTES } from '@/routes';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { setUser } from '@/redux/userSlice';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import { useSessionStorage } from 'usehooks-ts';
import { useToast } from '@/hooks/useToast';
import { AES } from '@/utils/AES';

type SignUpForm = {
  allTerms: CheckedState;
  serviceTerms: CheckedState;
  marketSyncAgreeYn: CheckedState;
  adAgreeYn: CheckedState;
};

type CheckState = {
  label: string;
  type: 'serviceTerms' | 'adAgreeYn' | 'marketSyncAgreeYn';
  require: boolean;
  url: string;
};

const CHECK_LIST: CheckState[] = [
  {
    label: '서비스 이용 약관 동의',
    type: 'serviceTerms',
    require: true,
    url: 'https://cplat-terms.s3.ap-northeast-2.amazonaws.com/SELLERBOX-terms-use.html',
  },
  {
    label: '개인정보 처리 방침',
    type: 'marketSyncAgreeYn',
    require: true,
    url: 'https://cplat-terms.s3.ap-northeast-2.amazonaws.com/SELLERBOX-terms-privacy.html',
  },
  {
    label: '이벤트/프로모션 정보 선택 동의',
    type: 'adAgreeYn',
    require: false,
    url: 'https://cplat-terms.s3.ap-northeast-2.amazonaws.com/SELLERBOX-terms-marketing.html',
  },
];

const SignUpContainer = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const { setValue, watch, trigger, getValues } = useForm<SignUpForm>({
    defaultValues: {
      allTerms: true,
      serviceTerms: true,
      marketSyncAgreeYn: true,
      adAgreeYn: true,
    },
  });

  const { mutate: signUp } = usePost(CPLAT_API_ROUTES.signUpByPhoneNo);
  const { mutate: signin } = usePost(CPLAT_API_ROUTES.signInByPhoneNo);

  const navigate = useNavigate();

  const [loading] = useState(false);
  const [isAgreeModalOpen, setIsAgreeModalOpen] = useState(false);
  const [termsUrl, setTermsUrl] = useState<string>('');

  const [cplatToken, setCplatToken] = useSessionStorage('cplatToken', '');
  const decryptedToken = AES.decryptData(cplatToken);

  // 모든 체크박스의 상태를 관찰
  const formValues = watch();

  // 모두 동의하기 체크박스 핸들러
  const handleAllTermsChange = (checked: CheckedState) => {
    setValue('allTerms', checked);
    setValue('serviceTerms', checked);
    setValue('marketSyncAgreeYn', checked);
    setValue('adAgreeYn', checked);
  };

  // 개별 체크박스 핸들러
  const handleIndividualCheckChange = async (checked: CheckedState, type: keyof SignUpForm) => {
    setValue(type, checked);
    // 모든 체크박스의 값이 true이면 전체 동의 체크박스도 true로 설정
    await trigger(); // 모든 필드를 다시 확인
    const allChecked = CHECK_LIST.every((item) => getValues(item.type));
    setValue('allTerms', allChecked);
  };

  // 로그인 핸들러
  const handleSignin = (token: any) => {
    signin(
      { verificationToken: token, osType: 'web', currentVersion: '1.0.0' },
      {
        onSuccess(res) {
          const { code, data } = res.data as CplatApiResponse<UserData[]>;

          if (code === '200') {
            const encryptedToken = AES.encryptData(data[0].CplatToken);
            setCplatToken(encryptedToken);
            dispatch(setUser(data[0]));
            navigate(`${ROUTES.market}?origin=signup`, { replace: true });
            toast({
              icon: '🎉',
              title: '셀러박스 회원 가입이 완료되었어요!',
              variant: 'dark',
            });
            return;
          }
        },
      },
    );
  };

  // 회원가입 핸들러
  const handleSignUp = () => {
    const { adAgreeYn } = formValues;
    signUp(
      {
        verificationToken: decryptedToken,
        MarketSyncAgreeYn: 'Y',
        AdAgreeYn: adAgreeYn ? 'Y' : 'N',
      },
      {
        onSuccess(res) {
          const { code } = res.data;

          // 회원가입 성공시, 로그인 처리 후 화면 이동
          if (code === '200') {
            handleSignin(decryptedToken);
            return;
          } else {
            toast({ icon: '⚠️', title: '에러 발생했습니다.', variant: 'dark' });
          }
        },
      },
    );
  };

  return (
    <>
      <ol className="mb-[28px] mt-[24px]">
        <li className="flex items-center space-x-[10px]">
          <Checkbox
            id={'allTerms'}
            checked={formValues.allTerms}
            onCheckedChange={handleAllTermsChange}
          />
          <label htmlFor="allTerms" className="cursor-pointer align-middle text-gray-800 Body1S16">
            전체 동의하기
          </label>
        </li>
        <p className="mt-[8px] text-start text-gray-750 Body6M14">
          셀러박스 서비스 이용을 위해 약관에 동의해 주세요
        </p>
        <div className="mt-[12px] flex flex-col gap-[12px] rounded-sm bg-gray-100 p-[16px]">
          {CHECK_LIST.map(({ label, type, require, url }) => (
            <li key={type} className="flex items-center text-gray-700">
              <Checkbox
                id={type}
                checked={formValues[type]}
                onCheckedChange={(flag) => {
                  handleIndividualCheckChange(flag, type);
                }}
              />
              <span className="ml-[6px] Caption1M12">[{require ? '필수' : '선택'}]</span>
              <label htmlFor={type} className="ml-[4px] cursor-pointer">
                <span className="Body5S14">{label}</span>
              </label>
              <span
                className="ml-[8px] cursor-pointer text-gray-600 underline underline-offset-2 Body6M14"
                onClick={() => {
                  setTermsUrl(url);
                  setIsAgreeModalOpen(true);
                }}
              >
                전문보기
              </span>
            </li>
          ))}
        </div>
      </ol>

      <div>
        <Button
          isLoading={loading}
          loadingClassName="w-[24px]"
          onClick={handleSignUp}
          className="h-[52px] w-full rounded-[15px] c-b1"
          disabled={!formValues.marketSyncAgreeYn || !formValues.serviceTerms || loading}
        >
          가입
        </Button>
      </div>
      <Dialog open={isAgreeModalOpen} onOpenChange={() => setIsAgreeModalOpen(!isAgreeModalOpen)}>
        <DialogContent className="flex h-[670px] !w-[660px] flex-col items-center p-[30px] pb-[20px] text-center">
          <iframe src={termsUrl} className="h-[100%] w-[100%] rounded-[16px]" />
          <Button
            variant={'secondary'}
            onClick={() => setIsAgreeModalOpen(false)}
            className="h-[44px] w-[200px] rounded-sm Body3S15"
          >
            확인
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignUpContainer;
