import { forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';

import { cn } from '@/utils/tailwind';

const buttonVariants = cva(
  'Body5S14 inline-flex items-center justify-center text-white rounded-sm text-white transition-colors disabled:transition-none focus-visible:outline-none whitespace-nowrap',
  {
    variants: {
      variant: {
        default: 'bg-primary hover:bg-primary-700 disabled:bg-gray-200 active:bg-primary',
        secondary:
          'bg-gray-150 text-gray-700 c-b3 hover:bg-gray-200 active:bg-gray-150 disabled:bg-gray-200 disabled:text-white',
        ghost: 'text-gray-700 hover:bg-accent hover:bg-gray-200',
        outline:
          'border text-gray-700 border-input hover:bg-gray-100 active:bg-white disabled:text-gray-300 disabled:bg-white',
        link: 'underline-offset-4 hover:underline text-primary',
        ivory: 'bg-primary-100 text-primary-500 hover:bg-primary-200 disabled:text-gray-300',
      },
      // TODO hight 너무 다양해서 정의하기 어려움..
      size: {
        default: 'h-10 px-4',
        sm: 'h-9 px-3 rounded-md',
        lg: 'h-11 px-8 rounded-md',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    isLoading?: boolean;
    loadingClassName?: string;
  };

/**
 * 테스트 설명
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, loadingClassName, variant, size, isLoading, disabled, children, ...props },
    ref,
  ) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isLoading ? true : disabled}
        {...props}
      >
        {isLoading ? (
          <span>
            <Loader2 className={cn('mr-2 w-[16px] animate-spin', loadingClassName)} />
          </span>
        ) : null}
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
