import { cn } from '@/utils/tailwind';
import { ReactNode, useRef } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

interface BottomSheetContainerProps {
  open: boolean;
  children: ReactNode;
  onDismiss: () => void;
  containerClassName: string;
}

const BottomSheetContainer = ({
  open,
  children,
  onDismiss,
  containerClassName,
}: BottomSheetContainerProps) => {
  const sheetRef = useRef<BottomSheetRef>(null);

  return (
    <BottomSheet
      ref={sheetRef}
      open={open}
      onDismiss={() => {
        setTimeout(() => onDismiss(), 100);
      }}
    >
      <div className={cn('px-[20px] pb-[40px] pt-[10px]', containerClassName)}>{children}</div>
    </BottomSheet>
  );
};

export default BottomSheetContainer;
