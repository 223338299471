import { CPLAT_API_ROUTES, usePost, useSuspenseFetchs } from '@/api';
import Button from '@/components/atoms/Button';
import Tab, { TabDataValues, TabItem } from '@/components/molecules/Tab';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import InfoFillGrayIcon from '@/assets/icons/InfoFillGrayIcon.svg?react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes';
import { useResponsive } from '@/hooks/useResponsive';
import { cn } from '@/utils/tailwind';
import MarketSidebar from '@/containers/productShipment/MarketSidebar';
import OrderListContainer from '@/containers/productShipment/OrderListContainer';
import {
  resetCheckedOrderList,
  setCheckedOrderList,
  setSelectedCategory,
} from '@/redux/orderSlice';
import { useModal } from '@/hooks/useModal';
import TextField from '@/components/molecules/TextField';
import CircleCloseIcon from '@/assets/icons/CircleCloseIcon.svg?react';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import Checkbox from '@/components/atoms/Checkbox';
import GlobalSpinner from '@/components/organisms/GlobalSpinner';
import { useToast } from '@/hooks/useToast';
import Select, { Data } from '@/components/molecules/Select';
import moment from 'moment';
import { startLoading, stopLoading } from '@/redux/loadingSlice';
import { logEvent } from '@/etc/firebase';
import { Tooltip } from 'react-tooltip';
import CloseGrayIcon from '@/assets/icons/CloseGrayIcon.svg?react';
import { useLocalStorage } from 'usehooks-ts';
import useCheckMembership from '@/hooks/useCheckMembership';
import MembershipIcon from '@/assets/icons/MembershipIcon.svg?react';

type OrderItem = {
  ProductName: string;
  ProductOption: string;
  SalesAmount: number;
  Quantity: number;
  ExternalProductInfo: string;
  ClaimNo: string;
  CancelCount: number;
  TmallDeliveryNo: string;
};

export interface OrderForCard extends Order {
  OrderItemList: OrderItem[];
}

type selectedStoreKeyType = {
  MarketId?: string;
  StoreId?: string;
};
interface ConfirmOrderResult {
  success: boolean;
  message?: string;
  orderNo?: string;
  error?: any;
}

const ProductShipmentPage = () => {
  /** hook, useState, useRef */
  const dispatch = useAppDispatch();
  const isMember = useCheckMembership();
  const navigate = useNavigate();
  const { isPCSize } = useResponsive();
  const [selectedTab, setSelectedTab] = useState<TabDataValues<typeof TAB_DATA>>('confirmOrder');
  const [isCoupangModalOpen, setIsCoupangModalOpen] = useState<boolean>(false);
  const [isSetInvoiceModalOpen, setIsSetInvoiceModalOpen] = useState<boolean>(false);
  const { openModal, closeModal } = useModal();
  const { toast } = useToast();
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isMembershipModal, setIsMembershipModal] = useState<boolean>(false);
  const [isTooltipOpenLocalStorage, setIsTooltipOpenLocalStorage] = useLocalStorage(
    'isTooltipOpen',
    true,
  );
  const containerRef1 = useRef<HTMLDivElement | null>(null);
  const containerRef2 = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const { checkedOrderList } = useAppSelector((state) => state.order);
  const user = useAppSelector((state) => state.user);

  /** useForm */
  // 쿠팡 ID 입력
  const { register, setValue, watch, reset, setError } = useForm<any>({
    defaultValues: {
      CoupangWingId: '',
    },
  });
  const CoupangWingId = watch('CoupangWingId');

  // 발송 정보 입력
  const {
    register: setInvoiceResister,
    formState: setInvoiceFormState,
    watch: setInvoiceWatch,
    setValue: setInvoiceSetValue,
    reset: setInvoiceReset,
    setError: setInvoiceSetError,
  } = useForm<any>({
    defaultValues: {
      invoiceNo: '',
      shippingCompanyCode: '',
    },
  });
  const { errors: setInvoiceError } = setInvoiceFormState;

  /** useQuery */
  const [
    { data: getStoreListRes, refetch: refetchGetStoreList },
    { data: getShippingCompanyListRes, refetch: refetchGetShippingCompanyList },
  ] = useSuspenseFetchs([
    {
      url: CPLAT_API_ROUTES.getStoreList,
      option: {
        method: 'POST',
        params: {
          cplatToken: user.CplatToken,
        },
      },
    },
    {
      url: CPLAT_API_ROUTES.getShippingCompanyList,
      option: {
        method: 'POST',
        params: {
          cplatToken: user.CplatToken,
        },
      },
    },
  ]);
  let storeList: Array<Store> = [];
  const { data: storeData } = getStoreListRes as AxiosResponse<StoreListData[]>;
  if (storeData?.length > 0) {
    const [{ StoreList }] = storeData;
    storeList = StoreList;
  }

  let shippingCompanyList: Array<Data> = [];
  let preferedShippingCompanyList: Array<string> = [];
  const { data: shippingCompanyData } = getShippingCompanyListRes as AxiosResponse<
    ShippingCompanyListData[]
  >;
  if (shippingCompanyData?.length > 0) {
    const [{ ShippingCompanyList }] = shippingCompanyData;
    shippingCompanyList = ShippingCompanyList.map((item) => ({
      value: item.ShippingCompanyCode,
      label: item.ShippingCompanyName,
      isPrefer: item.IsPrefer,
    }));
    preferedShippingCompanyList = ShippingCompanyList.filter((item) => item.IsPrefer).map(
      (item) => item.ShippingCompanyCode,
    );
  }

  // 선택한 스토어에 대한 key 값 (MarketId + StoreId)
  const [selectedStoreKey, setSelectedStoreKey] = useState<selectedStoreKeyType>(
    storeList.length > 0
      ? {
          MarketId: String(storeList[0].MarketId),
          StoreId: storeList[0].StoreId,
        }
      : {},
  );

  const getOrderListParams = selectedStoreKey
    ? {
        cplatToken: user.CplatToken,
        MarketId: selectedStoreKey.MarketId,
        StoreId: selectedStoreKey.StoreId,
      }
    : {
        cplatToken: user.CplatToken,
      };

  const [
    { data: getOrderListRes, refetch: refetchOrderList, isLoading: isLoadingOrderList },
    { data: registeredMarketRes },
  ] = useSuspenseFetchs([
    {
      url: CPLAT_API_ROUTES.getOrderList,
      option: {
        method: 'POST',
        params: getOrderListParams,
      },
    },
    {
      url: CPLAT_API_ROUTES.getRegisteredMarketInfo,
      option: {
        method: 'POST',
        params: {
          cplatToken: user.CplatToken,
        },
      },
    },
  ]);

  let orderList: Array<Order> = [];
  const { data: orderData } = getOrderListRes as AxiosResponse<OrderListData[]>;
  if (orderData.length > 0) {
    const [{ OrderList }] = orderData;
    orderList = OrderList;
  }

  let registeredMarketList: Array<RegisteredMarketInfo> = [];

  const { data } = registeredMarketRes as AxiosResponse<
    {
      RegisteredMarketList: RegisteredMarketInfo[];
    }[]
  >;
  if (data?.length > 0) {
    const [{ RegisteredMarketList }] = data;
    registeredMarketList = RegisteredMarketList;
  }

  const coupangMarketInfo = registeredMarketList?.find(
    (item) => item.MarketNameEnglish === 'coupang',
  ) || {
    CoupangWingId: '',
  };

  const groupedOrders = orderList.reduce((acc: OrderForCard[], item: Order) => {
    const {
      BundleItemCount,
      CoupangShipmentBoxNo,
      ProductName,
      ProductOption,
      SalesAmount,
      Quantity,
      ExternalProductInfo,
      ClaimNo,
      CancelCount,
      TmallDeliveryNo,
    } = item;

    const orderItem: OrderItem = {
      ProductName,
      ProductOption,
      SalesAmount,
      Quantity,
      ExternalProductInfo,
      ClaimNo,
      CancelCount,
      TmallDeliveryNo,
    };

    if (BundleItemCount <= 1) {
      acc.push({ ...item, OrderItemList: [orderItem] });
    } else {
      const existingGroup = acc.find(
        (group) => group.CoupangShipmentBoxNo === CoupangShipmentBoxNo,
      );
      if (existingGroup) {
        existingGroup.OrderItemList?.push(orderItem);
      } else {
        acc.push({ ...item, OrderItemList: [orderItem] });
      }
    }

    return acc;
  }, [] as OrderForCard[]);

  const checkedOrderInfo = groupedOrders.filter((item) =>
    checkedOrderList.includes(`${item.OrderNo}${item.OrderItemNo}`),
  );

  const confirmOrderParamsArray = checkedOrderInfo.map((item) => {
    return {
      cplatToken: user.CplatToken,
      marketId: Number(selectedStoreKey.MarketId),
      storeId: selectedStoreKey.StoreId,
      orderNo: item.OrderNo,
      orderItemList: [item.OrderItemNo],
      coupangShipmentBoxList: item.CoupangShipmentBoxNo === '' ? [] : [item.CoupangShipmentBoxNo],
      TmallDeliveryNo: item.TmallDeliveryNo,
      ProductNo: item.ProductNo,
      Quantity: item.Quantity,
    };
  });

  /** mutate */
  const { mutate: confirmOrder } = usePost(CPLAT_API_ROUTES.confirmOrder);
  const { mutate: setInvoice, isPending: isPendingSetInvoice } = usePost(
    CPLAT_API_ROUTES.setInvoice,
  );
  const { mutate: confirmCancellation } = usePost(CPLAT_API_ROUTES.confirmCancellation);
  const { mutate: declineCancellation, isPending: isPendingDeclineCancellation } = usePost(
    CPLAT_API_ROUTES.declineCancellation,
  );
  const { mutate: cancelBySeller } = usePost(CPLAT_API_ROUTES.cancelBySeller);
  const { mutate: updateOrderData, isPending: isPendingUpdateOrderData } = usePost(
    CPLAT_API_ROUTES.updateMarketData,
  );

  const { mutate: setShippingCompanyList } = usePost(CPLAT_API_ROUTES.setShippingCompanyList);

  /** handler */
  const handlePrefer = (selectedCode: string) => {
    let updatedPreferedShippingCompanyList;

    if (preferedShippingCompanyList.includes(selectedCode)) {
      updatedPreferedShippingCompanyList = preferedShippingCompanyList.filter(
        (code) => code !== selectedCode,
      );
    } else {
      updatedPreferedShippingCompanyList = [...preferedShippingCompanyList, selectedCode];
    }

    let body = {
      cplatToken: user.CplatToken,
      PreferShippingCompanyList: updatedPreferedShippingCompanyList,
    };
    setShippingCompanyList(body, {
      onSuccess() {
        refetchGetShippingCompanyList();
      },
    });
  };

  const handleUpdate = () => {
    const params = {
      cplatToken: user.CplatToken,
      marketNameEnglish: '',
      collectType: 'order',
      dateFrom: moment(new Date()).subtract(6, 'days').format('YYYY-MM-DD'),
      dateTo: moment(new Date()).format('YYYY-MM-DD'),
    };
    updateOrderData(params, {
      onSuccess() {
        refetchOrderList();
        refetchGetStoreList();
        toast({
          icon: '✅',
          title: '주문 수집이 완료되었어요',
          variant: 'dark',
        });
      },
      onError(error) {
        console.log(error);
      },
    });
    logEvent('update_order_data', params);
  };

  const handleConfirmOrders = async () => {
    dispatch(startLoading());
    try {
      const results: ConfirmOrderResult[] = await Promise.all(
        confirmOrderParamsArray.map(
          (params) =>
            new Promise<ConfirmOrderResult>((resolve) => {
              confirmOrder(params, {
                onSuccess(res) {
                  const { code, message } = res.data;

                  if (code === '200') {
                    resolve({ success: true });
                  } else {
                    resolve({ success: false, message, orderNo: params.orderNo });
                  }
                },
                onError(error) {
                  console.log('error');
                  console.log(error);
                  resolve({ success: false, error, orderNo: params.orderNo });
                },
              });
              logEvent('confirm_order', params);
            }),
        ),
      );

      const allSuccess = results.every((result) => result.success);

      if (allSuccess) {
        toast({
          icon: '✅',
          title: '주문 확인 처리가 완료되었어요',
          variant: 'dark',
        });
        closeModal();
        dispatch(stopLoading());
        refetchOrderList();
        refetchGetStoreList();
        dispatch(resetCheckedOrderList());
      } else {
        dispatch(resetCheckedOrderList());
        closeModal();
        dispatch(stopLoading());
        toast({
          icon: '⚠️',
          title: '주문 내역을 다시 불러올게요',
          variant: 'dark',
        });
        handleUpdate();
      }
    } catch (error) {
      console.log('error');
      console.log(error);
    }
  };

  const handleConfirmCancellation = async () => {
    dispatch(startLoading());
    const params = {
      cplatToken: user.CplatToken,
      marketId: Number(selectedStoreKey.MarketId),
      storeId: selectedStoreKey.StoreId,
      orderNo: checkedOrderInfo[0].OrderNo,
      orderItemList: [checkedOrderInfo[0].OrderItemNo],
      claimNo: checkedOrderInfo[0].ClaimNo,
      cancelCount: checkedOrderInfo[0].CancelCount,
    };

    confirmCancellation(params, {
      onSuccess(res) {
        const { code } = res.data;
        if (code === '200') {
          toast({
            icon: '✅',
            title: '취소 승인 처리가 완료되었어요',
            variant: 'dark',
          });
          closeModal();
          dispatch(stopLoading());
          refetchOrderList();
          refetchGetStoreList();
          dispatch(resetCheckedOrderList());
        } else {
          closeModal();
          dispatch(stopLoading());
          toast({
            icon: '⚠️',
            title: '주문 내역을 다시 불러올게요',
            variant: 'dark',
          });
          handleUpdate();
        }
      },
      onError(error) {
        console.log('error');
        console.log(error);
      },
      onSettled() {
        dispatch(stopLoading());
      },
    });
    logEvent('confirm_cancellation', params);
  };

  const handleSetInvoice = async () => {
    dispatch(startLoading());
    const params = {
      cplatToken: user.CplatToken,
      marketId: Number(selectedStoreKey.MarketId),
      storeId: selectedStoreKey.StoreId,
      orderNo: checkedOrderInfo[0].OrderNo,
      orderItemList: [checkedOrderInfo[0].OrderItemNo],
      coupangShipmentBoxNo: checkedOrderInfo[0].CoupangShipmentBoxNo,
      invoiceNo: setInvoiceWatch('invoiceNo'),
      shippingCompanyCode: setInvoiceWatch('shippingCompanyCode'),
      TmallDeliveryNo: checkedOrderInfo[0].TmallDeliveryNo,
      ProductNo: checkedOrderInfo[0].ProductNo,
      Quantity: checkedOrderInfo[0].Quantity,
    };
    setInvoice(params, {
      onSuccess(res) {
        const { code, message } = res.data;
        if (code === '200') {
          toast({
            icon: '✅',
            title: '발송 처리가 완료되었어요',
            variant: 'dark',
          });
          setIsSetInvoiceModalOpen(false);
          refetchOrderList();
          refetchGetStoreList();
          setInvoiceReset();
          dispatch(resetCheckedOrderList());
        } else {
          setInvoiceSetError('root', {
            message,
          });
        }
      },
      onError() {
        setInvoiceSetError('root', {
          message: '발송 정보 확인 후 유효한 운송장 번호를 입력해 주세요',
        });
      },
      onSettled() {
        dispatch(stopLoading());
      },
    });
    logEvent('set_invoice', params);
  };

  const handleDeclineCancellation = () => {
    dispatch(startLoading());
    const params = {
      cplatToken: user.CplatToken,
      marketId: Number(selectedStoreKey.MarketId),
      storeId: selectedStoreKey.StoreId,
      orderNo: checkedOrderInfo[0].OrderNo,
      orderItemList: [checkedOrderInfo[0].OrderItemNo],
      coupangShipmentBoxNo: checkedOrderInfo[0].CoupangShipmentBoxNo,
      invoiceNo: setInvoiceWatch('invoiceNo'),
      shippingCompanyCode: setInvoiceWatch('shippingCompanyCode'),
      claimNo: checkedOrderInfo[0].ClaimNo,
      ProductNo: checkedOrderInfo[0].ProductNo,
      Quantity: checkedOrderInfo[0].Quantity,
    };
    declineCancellation(params, {
      onSuccess(res) {
        const { code, message } = res.data;
        if (code === '200') {
          toast({
            icon: '✅',
            title: '발송 처리가 완료되었어요',
            variant: 'dark',
          });
          setIsSetInvoiceModalOpen(false);
          closeModal();
          refetchOrderList();
          refetchGetStoreList();
          setInvoiceReset();
          dispatch(resetCheckedOrderList());
        } else {
          setInvoiceSetError('root', {
            message,
          });
        }
      },
      onError() {
        setInvoiceSetError('root', {
          message: '발송 정보 확인 후 유효한 운송장 번호를 입력해 주세요',
        });
      },
      onSettled() {
        dispatch(stopLoading());
      },
    });
    logEvent('decline_cancellation', params);
  };

  const handleCancelBySeller = () => {
    dispatch(startLoading());
    const params = {
      cplatToken: user.CplatToken,
      marketId: Number(selectedStoreKey.MarketId),
      storeId: selectedStoreKey.StoreId,
      orderNo: checkedOrderInfo[0].OrderNo,
      orderItemList: [checkedOrderInfo[0].OrderItemNo],
      coupangShipmentBoxNo: checkedOrderInfo[0].CoupangShipmentBoxNo,
      quantity: checkedOrderInfo[0].BundleItemCount,
      coupangWingId: CoupangWingId,
    };
    cancelBySeller(params, {
      onSuccess(res) {
        const { code } = res.data;
        if (code === '200') {
          toast({
            icon: '✅',
            title: '취소 승인 처리가 완료되었어요',
            variant: 'dark',
          });
          setIsCoupangModalOpen(false);
          closeModal();
          dispatch(stopLoading());
          refetchOrderList();
          refetchGetStoreList();
          reset();
          dispatch(resetCheckedOrderList());
        } else {
          closeModal();
          dispatch(stopLoading());
          toast({
            icon: '⚠️',
            title: '주문 내역을 다시 불러올게요',
            variant: 'dark',
          });
          handleUpdate();
        }
      },
      onError(error) {
        console.log(error);
        setError('root', {
          message: '아이디가 일치하지 않습니다.',
        });
      },
    });
    logEvent('cancel_by_seller', params);
  };

  const filterOrderListByStatus = (
    orderList: OrderForCard[],
    status: TabDataValues<typeof TAB_DATA>,
  ) => {
    return orderList.filter((item) => item.OrderStatus === status);
  };

  const confirmOrderList = filterOrderListByStatus(groupedOrders, '결제완료');
  const setInvoiceList = filterOrderListByStatus(groupedOrders, '상품 준비 중');
  const setCancellationList = filterOrderListByStatus(groupedOrders, '취소 승인 요청');

  const originConfirmOrderList = orderList.filter((item) => item.OrderStatus === '결제완료');
  const originSetInvoiceList = orderList.filter((item) => item.OrderStatus === '상품 준비 중');
  const originSetCancellationList = orderList.filter(
    (item) => item.OrderStatus === '취소 승인 요청',
  );

  const TAB_DATA: TabItem[] = [
    {
      label: `주문 확인 ${
        originConfirmOrderList.length > 0 ? `${originConfirmOrderList.length}건` : ''
      }`,
      value: 'confirmOrder',
    },
    {
      label: `발송 처리 ${
        originSetInvoiceList.length > 0 ? `${originSetInvoiceList.length}건` : ''
      }`,
      value: 'setInvoice',
    },
    {
      label: `취소 처리 ${
        originSetCancellationList.length > 0 ? `${originSetCancellationList.length}건` : ''
      }`,
      value: 'setCancellation',
    },
  ];

  const FILTERED_ORDER_LIST: {
    [key: TabDataValues<typeof TAB_DATA>]: OrderForCard[];
  } = {
    confirmOrder: confirmOrderList,
    setInvoice: setInvoiceList,
    setCancellation: setCancellationList,
  };

  const isAllChecked = checkedOrderList.length === confirmOrderList.length;
  const allCheckHandle = () => {
    const allList = confirmOrderList.map((item) => `${item.OrderNo}${item.OrderItemNo}`);
    if (isAllChecked) {
      dispatch(resetCheckedOrderList());
    } else {
      dispatch(setCheckedOrderList(allList));
    }
  };

  const renderPCButton = () => {
    switch (selectedTab) {
      case 'confirmOrder':
        return (
          <div className="absolute right-[0px] top-[12px] flex">
            {isPCSize && confirmOrderList.length > 0 && (
              <div
                className={cn('mr-[12px] flex cursor-pointer items-center gap-[8px]')}
                onClick={allCheckHandle}
              >
                <Checkbox checked={isAllChecked} />
                <span className="text-gray-800 Body6M14">전체선택</span>
              </div>
            )}

            <Button
              variant={'outline'}
              className="mr-[8px] h-[38px] px-[18px] Body5S14"
              onClick={() => {
                if (!isMember) {
                  setIsMembershipModal(true);
                  return;
                }
                // 쿠팡인 경우, 아이디 저장 확인 후 요청 모달 띄우기
                if (selectedStoreKey.MarketId === '2' && coupangMarketInfo.CoupangWingId === '') {
                  setIsCoupangModalOpen(true);
                } else {
                  openModal({
                    buttonType: 'confirm',
                    title: '판매자 주문 취소를 진행할게요',
                    textList: [
                      '결제 완료 상품의 주문을 취소할 경우 마켓 정책에 따라 상품 품절로 인한 주문 거절로 사유가 등록돼요.\n*각 마켓 정책에 따라 상품이 품절 상태로 변경될 수 있습니다.',
                      '기타 사유로 주문 취소가 필요할 경우 마켓 판매자 페이지에서 직접 처리를 진행해 주세요.',
                    ],
                    confirmText: '취소하기',
                    onConfirm: () => {
                      handleCancelBySeller();
                    },
                  });
                }
              }}
              disabled={checkedOrderList.length !== 1}
            >
              주문취소
            </Button>
            <Button
              variant={'default'}
              className="h-[38px] px-[18px] Body4B14"
              onClick={() => {
                if (!isMember) {
                  setIsMembershipModal(true);
                  return;
                }
                openModal({
                  buttonType: 'confirm',
                  title: '주문 확인을 진행할게요',
                  description: '구매자에게 상품 준비중 또는 배송 대기중 상태로 보여져요.',
                  confirmText: '확인하기',
                  onConfirm: () => {
                    handleConfirmOrders();
                  },
                });
              }}
              disabled={checkedOrderList.length === 0}
            >
              <div className="flex items-center gap-[4px]">
                {checkedOrderList.length > 0 && (
                  <div className="flex h-[22px] w-[20px] items-center justify-center rounded-full bg-white">
                    <span className="text-orange-500 Body4B14">{checkedOrderList.length}</span>
                  </div>
                )}
                주문 확인
              </div>
            </Button>
          </div>
        );

      case 'setInvoice':
        return (
          <div className="absolute right-[0px] top-[12px] flex gap-[8px]">
            <Button
              variant={'default'}
              className="h-[38px] px-[18px] Body4B14"
              onClick={() => {
                if (!isMember) {
                  setIsMembershipModal(true);
                  return;
                }
                setIsSetInvoiceModalOpen(true);
              }}
              disabled={checkedOrderList.length === 0}
            >
              <div className="flex items-center gap-[4px]">발송 정보 입력</div>
            </Button>
          </div>
        );

      case 'setCancellation':
        return (
          <div className="absolute right-[0px] top-[12px] flex gap-[8px]">
            <Button
              variant={'outline'}
              className="h-[38px] px-[18px] Body5S14"
              onClick={() => {
                if (!isMember) {
                  setIsMembershipModal(true);
                  return;
                }
                openModal({
                  buttonType: 'confirm',
                  title: '취소 요청건 처리 안내',
                  textList: [
                    '구매자의 취소 요청을 거절할 경우 마켓 정책에 따라 배송 진행중 상태로 간주되어 발송 정보 등록이 반드시 필요해요. 해당 상품은 [상품 배송 시작에 의한 취소 거절]로 사유가 등록돼요.',
                    '기타 사유로 취소 요청을 거절할 경우 마켓 판매자 페이지에서 직접 처리를 진행해 주세요.',
                  ],
                  confirmText: '처리하기',
                  onConfirm: () => {
                    setIsSetInvoiceModalOpen(true);
                  },
                });
              }}
              disabled={checkedOrderList.length === 0 || selectedStoreKey.MarketId === '13'}
            >
              취소거절 {selectedStoreKey.MarketId === '13' && '[준비중]'}
            </Button>
            <Button
              variant={'default'}
              className="h-[38px] px-[18px] Body4B14"
              onClick={() => {
                if (!isMember) {
                  setIsMembershipModal(true);
                  return;
                }
                openModal({
                  buttonType: 'confirm',
                  title: '취소 승인을 진행할게요',
                  description:
                    '구매자에게 취소 요청 완료 상태로 보여지며,\n결제 방식에 따라 환불이 필요할 수 있어요.',
                  confirmText: '승인하기',
                  onConfirm: () => {
                    handleConfirmCancellation();
                  },
                });
              }}
              disabled={checkedOrderList.length === 0}
            >
              <div className="flex items-center gap-[4px]">취소확인</div>
            </Button>
          </div>
        );
    }
  };

  const renderMobileButton = () => {
    if (checkedOrderList.length === 0) {
      return null;
    }

    switch (selectedTab) {
      case 'confirmOrder':
        return (
          <div className="absolute bottom-[-44px] w-full">
            <div className="h-4 w-full bg-gradient-to-t from-white to-white/0"></div>
            <div className="flex w-full gap-[9px] bg-white px-[20px]">
              <Button
                variant={'secondary'}
                className="h-[44px] w-full px-[18px] py-[11px] Body3S15"
                onClick={() => {
                  if (!isMember) {
                    setIsMembershipModal(true);
                    return;
                  }
                  // 쿠팡인 경우, 아이디 저장 확인 후 요청 모달 띄우기
                  if (selectedStoreKey.MarketId === '2' && coupangMarketInfo.CoupangWingId === '') {
                    setIsCoupangModalOpen(true);
                  } else {
                    openModal({
                      buttonType: 'confirm',
                      title: '판매자 주문 취소를 진행할게요',
                      textList: [
                        '결제 완료 상품의 주문을 취소할 경우 마켓 정책에 따라 상품 품절로 인한 주문 거절로 사유가 등록돼요.\n*각 마켓 정책에 따라 상품이 품절 상태로 변경될 수 있습니다.',
                        '기타 사유로 주문 취소가 필요할 경우 마켓 판매자 페이지에서 직접 처리를 진행해 주세요.',
                      ],
                      confirmText: '취소하기',
                      onConfirm: () => {
                        handleCancelBySeller();
                      },
                    });
                  }
                }}
                disabled={checkedOrderList.length !== 1}
              >
                주문 취소
              </Button>
              <Button
                variant={'default'}
                className="h-[44px] w-full px-[18px] py-[11px] Body3S15"
                onClick={() => {
                  if (!isMember) {
                    setIsMembershipModal(true);
                    return;
                  }
                  openModal({
                    buttonType: 'confirm',
                    title: '주문 확인을 진행할게요',
                    description: '구매자에게 상품 준비중 또는 배송 대기중 상태로 보여져요.',
                    confirmText: '확인하기',
                    onConfirm: () => {
                      handleConfirmOrders();
                    },
                  });
                }}
                disabled={checkedOrderList.length === 0}
              >
                <div className="flex items-center gap-[4px]">
                  {checkedOrderList.length > 0 && (
                    <div className="flex h-[22px] w-[20px] items-center justify-center rounded-full bg-white">
                      <span className="text-orange-500 Body4B14">{checkedOrderList.length}</span>
                    </div>
                  )}
                  주문 확인
                </div>
              </Button>
            </div>
          </div>
        );

      case 'setInvoice':
        return (
          <div className="absolute bottom-[-44px] w-full">
            <div className="h-4 w-full bg-gradient-to-t from-white to-white/0"></div>
            <div className="flex w-full bg-white px-[20px]">
              <Button
                variant={'default'}
                className="h-[44px] w-full px-[18px] py-[11px] Body3S15"
                onClick={() => {
                  if (!isMember) {
                    setIsMembershipModal(true);
                    return;
                  }
                  setIsSetInvoiceModalOpen(true);
                  setIsSelectOpen(true);
                }}
                disabled={checkedOrderList.length === 0}
              >
                <div className="flex items-center gap-[4px]">발송 정보 입력</div>
              </Button>
            </div>
          </div>
        );

      case 'setCancellation':
        return (
          <div className="absolute bottom-[-44px] w-full">
            <div className="h-4 w-full bg-gradient-to-t from-white to-white/0"></div>
            <div className="flex w-full gap-[9px] bg-white px-[20px]">
              <Button
                variant={'secondary'}
                className="h-[44px] w-full px-[18px] py-[11px] Body3S15"
                onClick={() => {
                  if (!isMember) {
                    setIsMembershipModal(true);
                    return;
                  }
                  openModal({
                    buttonType: 'confirm',
                    title: '취소 요청건 처리 안내',
                    textList: [
                      '구매자의 취소 요청을 거절할 경우 마켓 정책에 따라 배송 진행중 상태로 간주되어 발송 정보 등록이 반드시 필요해요. 해당 상품은 [상품 배송 시작에 의한 취소 거절]로 사유가 등록돼요.',
                      '기타 사유로 취소 요청을 거절할 경우 마켓 판매자 페이지에서 직접 처리를 진행해 주세요.',
                    ],
                    confirmText: '처리하기',
                    onConfirm: () => {
                      setIsSetInvoiceModalOpen(true);
                      setIsSelectOpen(true);
                    },
                  });
                }}
                disabled={checkedOrderList.length === 0 || selectedStoreKey.MarketId === '13'}
              >
                취소 거절 {selectedStoreKey.MarketId === '13' && '[준비중]'}
              </Button>
              <Button
                variant={'default'}
                className="h-[44px] w-full px-[18px] py-[11px] Body3S15"
                onClick={() => {
                  if (!isMember) {
                    setIsMembershipModal(true);
                    return;
                  }
                  openModal({
                    buttonType: 'confirm',
                    title: '취소 승인을 진행할게요',
                    description:
                      '구매자에게 취소 요청 완료 상태로 보여지며,\n결제 방식에 따라 환불이 필요할 수 있어요.',
                    confirmText: '승인하기',
                    onConfirm: () => {
                      handleConfirmCancellation();
                    },
                  });
                }}
                disabled={checkedOrderList.length === 0}
              >
                <div className="flex items-center gap-[4px]">취소 확인</div>
              </Button>
            </div>
          </div>
        );
    }
  };

  useEffect(() => {
    if (containerRef1.current && containerRef2.current) {
      if (checkedOrderList.length > 0) {
        setContainerHeight(
          containerRef1.current.offsetHeight + containerRef2.current.offsetHeight + 44 + 20,
        );
      } else {
        setContainerHeight(containerRef1.current.offsetHeight + containerRef2.current.offsetHeight);
      }
    }
  }, [isPCSize, selectedTab, checkedOrderList.length]);

  useEffect(() => {
    dispatch(setSelectedCategory(selectedTab));
    dispatch(resetCheckedOrderList());
  }, [selectedTab]);

  useEffect(() => {
    dispatch(resetCheckedOrderList());

    if (confirmOrderList.length > 0) {
      setSelectedTab('confirmOrder');
      return;
    }
    if (setInvoiceList.length > 0) {
      setSelectedTab('setInvoice');
      return;
    }
    if (setCancellationList.length > 0) {
      setSelectedTab('setCancellation');
      return;
    }
  }, [selectedStoreKey]);

  useEffect(() => {
    if (isMember) {
      handleUpdate();
    }
  }, []);

  return (
    <div className="relative">
      {/* header */}
      <div className={cn('flex items-center justify-between', isPCSize && 'mb-[23px] mt-[72px]')}>
        {isPCSize && (
          <div className="flex items-center">
            <h1 className="mr-[12px] text-gray-800 Title2B20">상품 발송</h1>
            <p className="mr-[9px] text-gray-600 Body6M14">
              마켓에 접수된 주문건을 즉시 처리해 보세요
            </p>
            <Button
              variant={'ghost'}
              className="gap-[2px] bg-white px-[10px] text-gray-700 Body6M14"
              onClick={() => navigate(ROUTES.productShipmentGuide)}
            >
              <InfoFillGrayIcon />
              이용 방법 확인하기
            </Button>
          </div>
        )}
        {isPCSize && (
          <div className="flex items-center gap-[4px]">
            <span>최근 7일 기준</span>
            <Button
              id={'updateOrderDataPC'}
              variant={'outline'}
              className="h-[34px] bg-white px-[10px] text-gray-700 Body6M14"
              onClick={() => handleUpdate()}
            >
              주문 수집
            </Button>
            <Tooltip
              anchorSelect="#updateOrderDataPC"
              place={'bottom-end'}
              clickable
              isOpen={isTooltipOpenLocalStorage}
              style={tooltipStyle}
            >
              <div className="flex items-center gap-[4px]">
                <span className="Body7S14">신규 주문 내역으로 업데이트 할 수 있어요</span>
                <CloseGrayIcon
                  className="cursor-pointer"
                  onClick={() => setIsTooltipOpenLocalStorage(false)}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      {/* sidebar + content */}
      <div className={cn('gap-[20px]', isPCSize && 'flex')}>
        <div ref={containerRef1}>
          <MarketSidebar
            storeList={storeList}
            selectedStoreKey={selectedStoreKey}
            setSelectedStoreKey={setSelectedStoreKey}
            enableTotal={false}
          />
        </div>
        <div className="mb-[140px] w-full rounded-[20px] bg-white">
          <div ref={containerRef2}>
            {isPCSize ? (
              <div className="rounded-t-[20px] border-[1px] border-gray-200 px-[20px]">
                <Tab
                  data={TAB_DATA}
                  selectedTab={selectedTab}
                  handleSelectTab={setSelectedTab}
                  tabContainerClassName="px-0 border-b-0"
                  tabClassName="Body1S16 pb-[20px]"
                  tabWidthAuto={true}
                  rightContent={renderPCButton()}
                />
              </div>
            ) : (
              <div className="flex gap-[20px] bg-gray-100 px-[20px] py-[12px]">
                {TAB_DATA.map((item) => (
                  <div
                    key={item.value}
                    onClick={() => setSelectedTab(item.value)}
                    className={cn(
                      selectedTab === item.value
                        ? 'text-gray-900 Body5S14'
                        : 'text-gray-600 Body6M14',
                    )}
                  >
                    {item.label.replace('건', '')}
                  </div>
                ))}
              </div>
            )}

            {!isPCSize && (
              <div className="flex flex-col gap-[4px] px-[20px] pb-[12px] pt-[10px]">
                <div className="flex items-center justify-between">
                  <span className="text-gray-750 Caption1M12">최근 7일 기준</span>
                  <Button
                    id={'updateOrderDataMobile'}
                    variant={'outline'}
                    className="h-[34px] bg-white px-[10px] text-gray-700 Body6M14"
                    onClick={() => handleUpdate()}
                  >
                    주문 수집
                  </Button>
                  <Tooltip
                    anchorSelect="#updateOrderDataMobile"
                    place={'bottom-end'}
                    clickable
                    isOpen={isTooltipOpenLocalStorage}
                    style={tooltipStyle}
                  >
                    <div className="flex items-center gap-[4px]">
                      <span className="Body7S14">신규 주문 내역으로 업데이트 할 수 있어요</span>
                      <CloseGrayIcon
                        className="cursor-pointer"
                        onClick={() => setIsTooltipOpenLocalStorage(false)}
                      />
                    </div>
                  </Tooltip>
                </div>
                {selectedTab === 'confirmOrder' && confirmOrderList.length > 0 && (
                  <div
                    className={cn('mb-[4px] flex w-fit cursor-pointer items-center gap-[8px]')}
                    onClick={allCheckHandle}
                  >
                    <Checkbox checked={isAllChecked} />
                    <span className="text-gray-800 Body6M14">전체선택</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={cn(
              `flex-col overflow-y-auto rounded-b-[20px] border-[1px] border-t-0 border-gray-200 px-[20px]`,
              !isPCSize && `rounded-none border-0 scrollbar-hide`,
            )}
            style={{ height: !isPCSize ? `calc(100vh - ${containerHeight}px)` : '630px' }}
          >
            <OrderListContainer
              list={FILTERED_ORDER_LIST[selectedTab]}
              IsSelectedStoreReleasedStatus={
                storeList.find((item) => item.StoreId === selectedStoreKey.StoreId)
                  ?.IsReleasedStatus || false
              }
            />
          </div>

          {!isPCSize && renderMobileButton()}
        </div>
      </div>

      {/* 쿠팡 ID 입력 모달 */}
      <Dialog open={isCoupangModalOpen}>
        <DialogContent className="max-w-[340px] p-[20px] pt-[30px]">
          <form className="flex flex-col gap-[30px]">
            <div>
              <div className="mb-[16px] text-gray-900 Title3S18">쿠팡 ID를 입력해 주세요</div>
              <div className="mb-[16px] text-gray-750 Body6M14">
                판매자 취소 기능 사용을 위해 ID 등록이 필요해요.
                <br />이 작업은 쿠팡 계정 별 최조 1번만 진행돼요.
              </div>

              <TextField
                {...register('CoupangWingId')}
                variant={'filled'}
                placeholder="아이디를 입력하세요"
                InputProps={{
                  endAdornment: CoupangWingId ? (
                    <CircleCloseIcon
                      className="cursor-pointer"
                      onClick={() => setValue('CoupangWingId', '')}
                    />
                  ) : null,
                }}
              />
              {/* {errors.root?.message && (
                <p className="mt-[4px] text-red-600 Body5S14">{errors.root.message}</p>
              )} */}
            </div>

            <div className="flex justify-center space-x-[10px]">
              <Button
                type="button"
                variant={'secondary'}
                className="w-[145px]"
                onClick={() => setIsCoupangModalOpen(false)}
              >
                닫기
              </Button>
              <Button
                type="button"
                variant={'default'}
                className="w-[145px]"
                onClick={() => {
                  openModal({
                    buttonType: 'confirm',
                    title: '주문 취소를 진행할게요',
                    description: '마켓 정책에 따라 상품의 품절로 인한 주문 취소 처리로 진행돼요.',
                    onConfirm: () => {
                      handleCancelBySeller();
                    },
                  });
                }}
              >
                완료
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {/* 발송 정보 입력 모달 */}
      <Dialog open={isSetInvoiceModalOpen}>
        <DialogContent
          className={cn('top-[35%] max-w-[340px] p-[20px] pt-[30px]')}
          isLoading={isPendingSetInvoice || isPendingDeclineCancellation}
        >
          <form className="flex flex-col gap-[30px]">
            <div>
              <div className="mb-[16px] text-gray-900 Title3S18">발송 정보 입력</div>
              {selectedTab === 'setCancellation' ? (
                <div className="mb-[16px] text-gray-750 Body6M14">
                  구매자에게 상품 배송 시작으로 인한 취소 요청 거절로 안내되며, 배송중 상태로
                  보여져요.
                </div>
              ) : (
                <div className="mb-[16px] text-gray-750 Body6M14">
                  구매자에게 입력한 발송 정보가 제공되며,
                  <br />
                  배송중 상태로 보여져요.
                </div>
              )}

              <div className="text-gray-750 Caption1M12">택배사</div>
              <Select
                className="mb-[16px]"
                placeholder="택배사를 선택하세요"
                dataList={shippingCompanyList}
                selectedValue={setInvoiceWatch('shippingCompanyCode')}
                onSelectedValueChange={(value) => setInvoiceSetValue('shippingCompanyCode', value)}
                onPreferChange={(code: string) => {
                  handlePrefer(code);
                }}
                open={isSelectOpen}
                onOpenChange={(open) => {
                  setTimeout(() => setIsSelectOpen(open), 100); // 닫기 클릭 방지
                }}
              />

              <div className="text-gray-750 Caption1M12">운송장 번호</div>
              <TextField
                {...setInvoiceResister('invoiceNo', {
                  onChange: (e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9a-zA-Z]/g, '');
                    setInvoiceSetValue('invoiceNo', numericValue);
                  },
                })}
                variant={'outline'}
                placeholder="운송장 번호를 입력하세요"
                type="tel"
                disabled={isPendingSetInvoice || isPendingDeclineCancellation}
                maxLength={20}
              />

              {setInvoiceError.root?.message && (
                <p className="mt-[4px] text-red-600 Body5S14">{setInvoiceError.root.message}</p>
              )}
            </div>
            <div className="flex justify-center space-x-[10px]">
              <Button
                type="button"
                variant={'secondary'}
                className="w-[145px]"
                onClick={() => {
                  setIsSetInvoiceModalOpen(false);
                  setInvoiceReset();
                }}
              >
                닫기
              </Button>
              <Button
                type="button"
                variant={'default'}
                className="w-[145px]"
                onClick={() => {
                  selectedTab === 'setCancellation'
                    ? handleDeclineCancellation()
                    : handleSetInvoice();
                }}
                disabled={!setInvoiceWatch('shippingCompanyCode') || !setInvoiceWatch('invoiceNo')}
              >
                발송처리
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={isMembershipModal}>
        <DialogContent className="max-w-[315px] p-[20px] pt-[30px]">
          <div>
            <MembershipIcon className="mb-[10px]" />
            <div className="mb-[16px] text-gray-900 Title3S18">상품 발송 처리를 간편하게</div>
            <div className="mb-[30px] text-gray-750 Body6M14">
              멤버십을 가입하고 상품의 발송 처리를
              <br />
              언제 어디서나 빠르게 완료해 보세요!
            </div>
            <Button
              type="button"
              variant={'default'}
              className="mb-[19px] w-full"
              onClick={() => {
                setIsMembershipModal(false);
                if (isPCSize) {
                  navigate(ROUTES.membership);
                } else {
                  const data = JSON.stringify({
                    navigateParams: {
                      key: 'MembershipSubscribe',
                      name: 'MembershipSubscribe',
                    },
                  });
                  window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
                }
              }}
            >
              멤버십 안내 확인하기
            </Button>
            <div
              className="body5S14 cursor-pointer text-center text-gray-750 hover:text-gray-800"
              onClick={() => {
                setIsMembershipModal(false);
              }}
            >
              닫기
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {(isLoadingOrderList || isPendingUpdateOrderData) && <GlobalSpinner />}
    </div>
  );
};

const tooltipStyle = {
  paddingLeft: 10,
  paddingRight: 4,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 10,
  zIndex: 10,
};

export default ProductShipmentPage;
