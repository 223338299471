import { useEffect, useState } from 'react';

import Skeleton from '@/components/atoms/Skeleton';
import { Card, CardContent } from '@/components/atoms/Card';
import { cn } from '@/utils/tailwind';

import CoupangGuideForCompanyImg from '@/assets/images/CoupangGuide.png';
import CoupangGuideForDirectImg from '@/assets/images/CoupangGuideDirect.png';

type ImgCardCoupangGuideImgCard = {
  className?: string;
};

const CoupangGuideImgCard = ({ className }: ImgCardCoupangGuideImgCard) => {
  const [loaded, setLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'company' | 'direct'>('company');
  const guideImg = selectedTab === 'company' ? CoupangGuideForCompanyImg : CoupangGuideForDirectImg;

  useEffect(() => {
    const img = new Image();
    img.src = guideImg;

    img.onload = () => {
      setLoaded(true);
    };

    if (img.complete) {
      setLoaded(true);
    }
  }, [guideImg]);

  const handleSelectTab = (tab: 'company' | 'direct') => setSelectedTab(tab);

  return loaded ? (
    <Card>
      <CardContent className={cn('p-[30px]', className)}>
        <div className="mx-[-30px] flex space-x-[30px] border-b border-b-gray-200 pb-[9px] text-gray-600 c-b1">
          <p
            className={`relative h-[24px] cursor-pointer pl-[30px] ${
              selectedTab === 'company' && 'text-gray-700'
            } transition-colors`}
            onClick={() => handleSelectTab('company')}
          >
            업체 선택
            {selectedTab === 'company' && (
              <div className="absolute bottom-[-10px] left-0 right-0 ml-[30px] h-[2px] border-b border-b-gray-700" />
            )}
          </p>
          <p
            className={`relative h-[24px] cursor-pointer ${
              selectedTab === 'direct' && 'text-gray-700'
            } transition-colors`}
            onClick={() => handleSelectTab('direct')}
          >
            직접 입력
            {selectedTab === 'direct' && (
              <div className="absolute bottom-[-10px] left-0 right-0 h-[2px] border-b border-b-gray-700" />
            )}
          </p>
        </div>
        <img src={guideImg} alt="이미지" className="mt-[30px]" />
      </CardContent>
    </Card>
  ) : (
    <Skeleton className="w-full rounded-[20px] bg-gray-200" />
  );
};

export default CoupangGuideImgCard;
