import { Dispatch } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from '@/redux/store';
import { ToasterToast, addToast, dismissToast, removeToast, updateToast } from '@/redux/toastSlice';
import { useEffect } from 'react';

const TOAST_REMOVE_DELAY = 6000;

// toast id  시작값  랜더링과 상관없는 값이기에 변수로 설정
let count = 0;

/**
 * toast id를 1씩 증가시켜서 return 하는 함수
 */
const createToastId = () => {
  count = (count + 1) % Number.MAX_VALUE;
  return count.toString();
};

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>();

/**
 * leave animation을 실행후 일정시간 뒤에 data를 제거하는 함수
 * TODO hover일때 컴포넌트는 제거되지 않는 기능을 제공하는데 해당 함수로 인해 강제로 제거되는 문제
 */
const addToRemoveQueue = (toastId: string, dispatch: Dispatch) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch(removeToast(toastId));
  }, TOAST_REMOVE_DELAY);

  toastTimeouts.set(toastId, timeout);
};

type Toast = Omit<ToasterToast, 'id'>;

const toastWrapper = (dispatch: Dispatch) => (toast: Toast) => {
  const id = createToastId();

  const update = (props: ToasterToast) => dispatch(updateToast({ ...props }));
  const dismiss = () => dispatch(dismissToast(id));

  dispatch(
    addToast({
      ...toast,
      id,
    }),
  );

  return {
    id,
    dismiss,
    update,
  };
};

// TODO toast 조금씩 다르게 존재하면 전역 + 지역 두개가 존재해야 할듯... 그러면 상태관리도 state 가 필요해질 수도 흠...
export const useToast = () => {
  const { toasts } = useAppSelector((state) => state.toast);
  const dispatch = useAppDispatch();

  useEffect(() => {
    toasts.forEach(({ open, id }) => {
      if (!open) {
        // leave 애니메이션을 주기위해서 일정시간 지난후 제거
        addToRemoveQueue(id, dispatch);
      }
    });
  }, [toasts, dispatch]);

  return {
    toasts,
    toast: toastWrapper(dispatch),
    dismiss: (toastId?: string) => dispatch(dismissToast(toastId)),
  };
};
