import Button from '@/components/atoms/Button';
import { Card } from '@/components/atoms/Card';

import LandingImg from '@/assets/images/LandingImg2.png';
import DownLoadQR from '@/assets/images/AppDownloadQR.png';
// import SellerBoxIcon from '@/assets/icons/SellerBoxIcon.svg?react';
import AppStoreIcon from '@/assets/icons/AppStoreIcon.svg?react';
import PlayStoreIcon from '@/assets/icons/PlayStoreIcon.svg?react';
import SignUpContainer from '@/containers/auth/SignUpContainer';

/**
 * TODO 라우팅 할지 조건부 렌더링할지 고민하기.... 체크박스 표시
 */
const SignUpPage = () => {
  return (
    <div className="flex pt-[160px]">
      <Card className="flex h-[460px] w-[410px] flex-col justify-between whitespace-nowrap p-[40px]">
        <SignUpContainer />
      </Card>
      <Card className="relative ml-[30px] flex w-[850px]  pb-[35px] pl-[50px] pt-[60px]">
        <div className="flex flex-col justify-between">
          <div>
            {/* <SellerBoxIcon /> */}
            <h3 className="mt-[28px] text-gray-750 c-b3">마켓 별 정산 예정 금액을 확인하세요</h3>
            <h2 className="mt-[6px] text-gray-900 c-t1">
              <mark className="bg-white text-blue-400">정산 예정 금액을 확인하고</mark>
              <br />
              스마트하게 자금을 관리하세요
            </h2>
          </div>
          <div>
            <p className="text-gray-700 c-b1">아직 앱을 다운받지 않으셨나요?</p>
            <div className="flex items-center space-x-[8px]">
              <Button className="h-[48px] w-[132px] whitespace-nowrap bg-gray-150 text-gray-700 c-b4 hover:bg-gray-200">
                <PlayStoreIcon />
                <span className="ml-1">Google Play</span>
              </Button>
              <Button className="h-[48px] w-[120px] whitespace-nowrap bg-gray-150 text-gray-700 c-b4 hover:bg-gray-200">
                <AppStoreIcon /> <span className="ml-1">App Store</span>
              </Button>
              <img src={DownLoadQR} alt="앱 다운로드 QR 이미지" />
            </div>
          </div>
        </div>
        <div className="absolute right-0 top-0">
          <img src={LandingImg} alt="홍보 이미지" />
        </div>
      </Card>
    </div>
  );
};

export default SignUpPage;
