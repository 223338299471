/** 초 단위(s) -> 'M:SS' 포맷으로 파싱 */
export const secondsParser = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // 두 자리로 맞추기 위해 padStart를 사용
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${minutes}:${formattedSeconds}`;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const year = String(date.getFullYear()).slice(2);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const formatDateKorean = (dateString: string): string => {
  const date = new Date(dateString);

  const year = String(date.getFullYear()).slice(0, 4);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}년 ${month}월 ${day}일`;
};

export const autoComma = (amount: number | string): string => {
  if (typeof amount == 'number') {
    return amount.toLocaleString('ko-KR');
  } else {
    return amount;
  }
};
