import Lottie from 'lottie-react';

import SellerBoxAnimationData from '@/assets/animation/LoadingBox.json';
import { cn } from '@/utils/tailwind';

type GlobalSpinnerProps = {
  className?: string;
};

const GlobalSpinner = ({ className }: GlobalSpinnerProps) => {
  return (
    <div
      className={cn(
        'fixed left-0 top-0 flex h-[100vh] w-[100vw] items-center justify-center',
        className,
      )}
    >
      <Lottie className="w-[150px]" animationData={SellerBoxAnimationData} loop />
    </div>
  );
};

export default GlobalSpinner;
