import axios, { type AxiosInstance } from 'axios';

import { baseURL } from './axios';
import { CPLAT_API_ROUTES } from '.';

export class UploadSystem {
  static #axios: AxiosInstance = axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  static async uploadImgAsync(file: File) {
    const formData = new FormData();
    formData.append('FormFile', file);
    return this.#axios.post(CPLAT_API_ROUTES.uploadImage, formData);
  }
}
