import { cn } from '@/utils/tailwind';
import React, { useRef, useEffect, useState, ReactNode } from 'react';

export type TabItem = {
  label: string;
  value: string;
};

export type TabDataValues<T extends TabItem[]> = T[number]['value'];

type TabProps<T extends TabItem[]> = {
  data: T;
  selectedTab: TabDataValues<T>;
  handleSelectTab: React.Dispatch<React.SetStateAction<TabDataValues<T>>>;
  tabContainerClassName?: string;
  tabClassName?: string;
  /** 탭 너비 자동/고정 여부 - 고정인 경우 158px */
  tabWidthAuto?: boolean;
  rightContent?: ReactNode;
};

const Tab = <T extends TabItem[]>({
  data,
  selectedTab,
  handleSelectTab,
  tabContainerClassName,
  tabClassName,
  tabWidthAuto,
  rightContent,
}: TabProps<T>) => {
  let indicatorWidth = tabWidthAuto ? '95px' : '138px'; // 활성화 탭 강조 표시 너비
  const [indicatorStyle, setIndicatorStyle] = useState({ left: '0px', width: indicatorWidth });
  const tabsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const selectedIndex = data.findIndex((item) => item.value === selectedTab);
    const selectedTabElement = tabsRef.current[selectedIndex];

    if (selectedTabElement) {
      const { offsetLeft, clientWidth } = selectedTabElement;
      const tabCenter = offsetLeft + clientWidth / 2;
      // 0건일 때는 indicator width 줄이기
      if (!data[selectedIndex].label.includes('건')) {
        indicatorWidth = '65px';
      }
      const indicatorLeft = tabCenter - parseInt(indicatorWidth.replace('px', ''), 10) / 2;
      setIndicatorStyle({ left: `${indicatorLeft}px`, width: indicatorWidth });
    }
  }, [selectedTab, data]);

  return (
    <div className="relative">
      <div
        className={cn(
          'flex justify-between border-b border-b-gray-200 px-[24px] pt-[20px]',
          tabContainerClassName,
        )}
      >
        {/* Tab */}
        <div className={cn('flex', tabWidthAuto && 'gap-[40px]')}>
          {data.map((item, index) => (
            <p
              key={item.value}
              className={cn(
                `relative cursor-pointer pb-[12px] text-center transition-colors Title3S18 ${
                  selectedTab === item.value ? 'text-gray-900' : 'text-gray-500'
                }`,
                tabClassName,
                tabWidthAuto ? 'w-auto' : 'w-[158px]',
              )}
              onClick={() => handleSelectTab(item.value as TabDataValues<T>)}
              ref={(el) => (tabsRef.current[index] = el!)}
            >
              {item.label}
            </p>
          ))}
        </div>
        {/* 탭 오른쪽 컨텐츠 */}
        {rightContent}
      </div>

      {/* Indicator */}
      <div
        className="absolute bottom-0 h-[2px] bg-gray-700 transition-all duration-300"
        style={{
          left: indicatorStyle.left,
          width: indicatorStyle.width,
        }}
      />
    </div>
  );
};

export default Tab;
