import BadgeSquare from '@/components/atoms/BadgeSquare';
import BulletList from '@/components/molecules/BulletList';
interface InfoContainerProps {
  title: string;
  isPass: boolean;
  name?: string;
  list?: Array<string>;
  description?: string;
  useBadge?: boolean;
}

const InfoContainer = ({
  title,
  isPass,
  name,
  list,
  description,
  useBadge = true,
}: InfoContainerProps) => {
  return (
    <div>
      <div className="mb-[10px] flex items-center gap-[8px]">
        <span className="text-gray-800 Body3S15">{title}</span>
        {useBadge && (
          <>
            {isPass ? (
              <BadgeSquare variant={'blue'} size="md">
                적합
              </BadgeSquare>
            ) : (
              <BadgeSquare variant={'red'} size="md">
                나쁨
              </BadgeSquare>
            )}
          </>
        )}
      </div>
      {name && (
        <div className="mb-[12px] rounded-[10px] bg-gray-100 p-[10px] text-gray-800 Body6M14">
          {name}
        </div>
      )}
      {list && <BulletList data={list} className="gap-0" />}
      <div className="whitespace-pre-line text-gray-700 Body6M14">{description}</div>
    </div>
  );
};

export default InfoContainer;
