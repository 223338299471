import { CPLAT_API_ROUTES, usePost } from '@/api';
import Button from '@/components/atoms/Button';
import MarketSidebar, { selectedStoreKeyType } from '@/containers/productShipment/MarketSidebar';
import { useResponsive } from '@/hooks/useResponsive';
import { useAppSelector } from '@/redux/store';
import { cn } from '@/utils/tailwind';
import { useEffect, useState } from 'react';
import InquiryListContainer from '@/containers/inquiry/InquiryListContainer';
import { Inquiry, Status } from '@/types/inquiry';
import moment from 'moment';
import { useToast } from '@/hooks/useToast';
import GlobalSpinner from '@/components/organisms/GlobalSpinner';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes';
import InfoFillGrayIcon from '@/assets/icons/InfoFillGrayIcon.svg?react';

const InquiryListPage = () => {
  const { toast } = useToast();
  const { isPCSize } = useResponsive();
  const user = useAppSelector((state) => state.user);
  const [storeList, setStoreList] = useState<Array<Store>>([]);
  const [selectedStoreKey, setSelectedStoreKey] = useState<selectedStoreKeyType>({
    MarketId: '0',
    StoreId: '',
  });
  const [activedStatus, setActivedStatus] = useState<Status>('신규문의');
  const stutusList: Array<Status> = ['신규문의', '처리완료'];
  const [inquiryList, setInquiryList] = useState<Array<Inquiry>>([]);
  const [unCheckedInquiryList, setUnCheckedInquiryList] = useState<Array<Inquiry>>([]);
  const [checkedInquiryList, setCheckedInquiryList] = useState<Array<Inquiry>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const selectedStoreIsReleasedStatus =
    storeList.find((item) => item.StoreId === selectedStoreKey.StoreId)?.IsReleasedStatus || false;
  const navigate = useNavigate();

  const { mutate: getInquiryList, isPending: isPendingGetInquiryList } = usePost(
    CPLAT_API_ROUTES.getInquiryList,
  );

  const handleInquiryList = () => {
    getInquiryList(
      {
        cplatToken: user.CplatToken,
        dateFrom: moment(new Date()).subtract(6, 'days').format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        onSuccess(res) {
          const code = res.data.code;
          if (code === '200') {
            console.log(res.data.data);
            const { StoreList, InquiryList } = res.data.data[0];
            setStoreList(StoreList);
            setInquiryList(InquiryList);
          }
        },
        onSettled() {
          setIsLoading(false);
        },
      },
    );
  };

  const getActivedList = (selectedStoreKey: selectedStoreKeyType, inquiryList: Array<Inquiry>) => {
    if (selectedStoreKey.MarketId === '0' && selectedStoreKey.StoreId === '') {
      return inquiryList;
    } else {
      const filteredList = inquiryList.filter(
        (item: Inquiry) =>
          item.StoreId === selectedStoreKey.StoreId &&
          String(item.MarketId) === selectedStoreKey.MarketId,
      );
      return filteredList;
    }
  };

  useEffect(() => {
    handleInquiryList();
  }, []);

  useEffect(() => {
    const activedList = getActivedList(selectedStoreKey, inquiryList);
    const unCheckedInquiryList = activedList.filter((item: Inquiry) => item.CheckedYn === 'N');
    const checkedInquiryList = activedList.filter((item: Inquiry) => item.CheckedYn === 'Y');
    setUnCheckedInquiryList(unCheckedInquiryList);
    setCheckedInquiryList(checkedInquiryList);
  }, [selectedStoreKey, inquiryList]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      if (data['updateMarketData']) {
        // 전체 스토어 조회
        setSelectedStoreKey({
          MarketId: '0',
          StoreId: '',
        });
        const text = data['updateMarketData'];
        if (text === 'Y') {
          toast({
            icon: '✅',
            title: '답변 등록이 완료되었어요',
            variant: 'dark',
          });
          handleInquiryList();
        } else {
          toast({
            icon: '⚠️',
            title: text,
            variant: 'dark',
          });
        }
      }
    };

    window.addEventListener('message', handleMessage);
    document.addEventListener('message', handleMessage as EventListener);
    return () => {
      window.removeEventListener('message', handleMessage);
      document.addEventListener('message', handleMessage as EventListener);
    };
  }, []);

  return (
    <div className="relative">
      {!isPendingGetInquiryList && (
        <>
          {/* header */}
          <div
            className={cn('flex items-center justify-between', isPCSize && 'mb-[23px] mt-[72px]')}
          >
            {isPCSize && (
              <div className="flex items-center">
                <h1 className="mr-[12px] text-gray-800 Title2B20">문의</h1>
                <p className="mr-[9px] text-gray-600 Body6M14">
                  AI 추천 답변을 통해 빠르고 정확한 답변을 작성해 보세요
                </p>
                <Button
                  variant={'ghost'}
                  className="gap-[2px] bg-white px-[10px] text-gray-700 Body6M14"
                  onClick={() => navigate(ROUTES.inquiryGuide)}
                >
                  <InfoFillGrayIcon />
                  이용 방법 확인하기
                </Button>
              </div>
            )}
          </div>
          <main className={cn('flex h-screen flex-col', isPCSize && 'flex-row')}>
            <div className={cn('flex-shrink-0')}>
              <MarketSidebar
                storeList={storeList}
                selectedStoreKey={selectedStoreKey}
                setSelectedStoreKey={setSelectedStoreKey}
                enableTotal={true}
              />
              {/* 모바일 헤더 */}
              {!isPCSize && (
                <div className="flex items-center justify-between bg-gray-100">
                  <div className="flex gap-[12px] px-[20px] py-[12px]">
                    {stutusList.map((item) => (
                      <div
                        key={item}
                        onClick={() => setActivedStatus(item)}
                        className={cn(
                          activedStatus === item
                            ? 'text-gray-900 Body5S14'
                            : 'text-gray-600 Body6M14',
                        )}
                      >
                        <span>
                          {item}{' '}
                          {item === '신규문의'
                            ? unCheckedInquiryList.length
                            : checkedInquiryList.length}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {/* PC */}
            {isPCSize ? (
              <div className="ml-[20px] w-[1030px]">
                <div className="p flex items-center justify-between rounded-t-[20px] border-[1px] border-b-0 bg-gray-100 bg-white">
                  <div className="flex gap-[12px] px-[20px] py-[20px]">
                    {stutusList.map((item) => (
                      <div
                        key={item}
                        onClick={() => setActivedStatus(item)}
                        className={cn(
                          'cursor-pointer',
                          activedStatus === item
                            ? 'text-gray-900 Body1S16'
                            : 'text-gray-600 Body1S16',
                        )}
                      >
                        {item}{' '}
                        {item === '신규문의'
                          ? unCheckedInquiryList.length
                          : checkedInquiryList.length}
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center gap-[16px] pr-[20px]">
                    <span className="text-gray-800 Body6M14">최근 7일 기준</span>
                  </div>
                </div>
                <div className="h-[690px] overflow-y-auto rounded-b-[20px] border-[1px] border-gray-200 bg-white">
                  <InquiryListContainer
                    activedStatus={activedStatus}
                    list={activedStatus === '신규문의' ? unCheckedInquiryList : checkedInquiryList}
                    isLoading={isLoading}
                    IsReleasedStatus={selectedStoreIsReleasedStatus}
                    isPCSize={true}
                    handleInquiryList={handleInquiryList}
                  />
                </div>
              </div>
            ) : (
              // 모바일
              <div className="flex-grow overflow-y-auto bg-white">
                <InquiryListContainer
                  activedStatus={activedStatus}
                  list={activedStatus === '신규문의' ? unCheckedInquiryList : checkedInquiryList}
                  isLoading={isLoading}
                  IsReleasedStatus={selectedStoreIsReleasedStatus}
                  isPCSize={false}
                  handleInquiryList={handleInquiryList}
                />
              </div>
            )}
          </main>
        </>
      )}

      {isPendingGetInquiryList && <GlobalSpinner />}
    </div>
  );
};

export default InquiryListPage;
