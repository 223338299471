import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { CPLAT_API_ROUTES, usePost } from '@/api';
import Button from '@/components/atoms/Button';
import { useAppSelector } from '@/redux/store';
import EN_TO_KO_MARKET_NAME_MAP from '@/contents/englishToKoreanMarketNames';

const COMMERCE_SOLUTION_URL = 'https://sell.smartstore.naver.com/#/commerce-solution/solution-list';
const SHOP_BY_URl =
  'https://admin.shopby.co.kr/pro/app/basic/list?mallNo=78585&appStatus=ACTIVE&page=1';
const CAFE24_URL = 'https://chdb57.cafe24.com/disp/admin/shop1/myapps/list';

const getUnregisterUrl = (marketName: string) => {
  switch (marketName) {
    case '스마트스토어':
      return COMMERCE_SOLUTION_URL;
    case '샵바이':
      return SHOP_BY_URl;
    case '카페24':
      return CAFE24_URL;
    default:
      return '';
  }
};

type UnregisterDialogContainerProps = {
  onClose: () => void;
  checkedMarket: RegisteredMarketInfo;
  onResetChecked: () => void;
};

const UnregisterDialogContainer = ({
  onClose,
  checkedMarket,
  onResetChecked,
}: UnregisterDialogContainerProps) => {
  const user = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();

  console.log(checkedMarket);

  // 스마트스토어는 커머스솔루션 마켓에서 연동해제 해야 한다.
  // shopby 여기도 해결해야 한다.
  const isExternalRegisterMarket =
    checkedMarket.MarketNameEnglish === 'smartstore' ||
    checkedMarket.MarketNameEnglish === 'shopby' ||
    checkedMarket.MarketNameEnglish === 'cafe24';

  const { mutate: unregisterMarket, isPending: isUnregisterPending } = usePost(
    CPLAT_API_ROUTES.unregisterMarket,
  );

  const handleUnregisterMarket = () => {
    const { MarketName, MarketId, VendorId } = checkedMarket;
    const body = {
      MarketName: checkedMarket.MarketNameEnglish,
      MarketLoginInfo: JSON.stringify({ MarketName, MarketId, VendorId }),
      CplatToken: user.CplatToken,
    };

    unregisterMarket(body, {
      onSuccess(res) {
        const { code } = res.data as CplatApiResponse<undefined>;

        if (code === '200') {
          queryClient
            .refetchQueries({
              queryKey: [CPLAT_API_ROUTES.getRegisteredMarketInfo],
            })
            .then(() => {
              onResetChecked();
              onClose();
            });
        }

        if (code === '712') {
          onResetChecked();
          onClose();
        }
      },
    });
  };

  const handleUnregisterSmartStore = () => {
    onClose();
    onResetChecked();
  };

  return isExternalRegisterMarket ? (
    <div className="flex flex-col items-center">
      <p className="text-gray-900 c-t3">마켓 연동을 해지하시겠어요?</p>
      <p className="mt-[16px] text-center text-gray-700 c-b2">
        {EN_TO_KO_MARKET_NAME_MAP[checkedMarket.MarketNameEnglish] || '마켓'} 연동 해제를 원하실
        경우
        <br />
        판매자 센터에서 솔루션을 해지해주세요
      </p>
      <div className="mt-[40px] space-x-[10px]">
        {/** TODO shopby 여기 링크도 수정해야 한다.  */}
        <Link to={getUnregisterUrl(checkedMarket.MarketName)} target="_blank">
          <Button onClick={handleUnregisterSmartStore} variant="secondary" className="w-[110px]">
            확인
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center">
      <p className="text-gray-900 c-t3">마켓 연동을 해지하시겠어요?</p>
      <p className="mt-[16px] text-center text-gray-700 c-b2">
        더 이상 해당 마켓의 정보를 볼 수 없어요.
        <br />
        그래도 해지하시겠어요?
      </p>
      <div className="mt-[40px] space-x-[10px]">
        <Button
          disabled={isUnregisterPending}
          onClick={handleUnregisterMarket}
          variant="secondary"
          className="w-[110px]"
        >
          연동해지
        </Button>
        <Button onClick={onClose} className="w-[110px]">
          취소
        </Button>
      </div>
    </div>
  );
};

export default UnregisterDialogContainer;
