import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export type ModalProps = {
  isOpenModal?: boolean;
  buttonType?: 'confirm' | 'alert';
  onConfirm?: () => void;
  confirmText?: string;
  title?: string;
  description?: string;
  textList?: Array<string>;
  formContent?: ReactNode;
};

const initialState: ModalProps = {
  isOpenModal: false,
  confirmText: '확인',
  title: '',
  description: '',
  textList: [],
};

const ModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (state: ModalProps, action: PayloadAction<ModalProps>) => {
      return { ...state, ...initialState, ...action.payload, isOpenModal: true };
    },
    close: (state: ModalProps) => {
      return { ...state, isOpenModal: false };
    },
  },
});

export const { open, close } = ModalSlice.actions;

export default ModalSlice.reducer;
