import { Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { type AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '@/redux/store';
import { CPLAT_API_ROUTES, useSuspenseFetchs } from '@/api';
import HeaderLayout from '@/components/layouts/HeaderLayout';
import PromotionSellkeyCard from '@/components/organisms/PromotionSellkeyCard';
import LinkGuide from '@/components/organisms/LinkGuide';
import MARKET_GUIDE_DATA, { type GuideLink } from '@/contents/marketGuide';
import { ROUTES } from '@/routes';
import ChangePasswordContainer from '@/containers/market/ChangePasswordContainer';
import { useToast } from '@/hooks/useToast';
import GlobalSpinner from '@/components/organisms/GlobalSpinner';

const ChangeMarketPasswordPage = () => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const { toast, toasts } = useToast();

  const id = atob(params.get('id') || '');

  const marketEnName = location.pathname.split('/').at(-1);

  if (!marketEnName) {
    navigate(ROUTES.error);
    return null;
  }

  const guideDatas = MARKET_GUIDE_DATA[marketEnName];

  if (guideDatas === undefined) {
    if (toasts.length === 0) toast({ title: '잘못된 경로입니다.', variant: 'error' });
    navigate(ROUTES.market);
    return null;
  }

  const guide = guideDatas.find((guide) => guide.type === 'linkGuide') as GuideLink;

  const [{ data: marketInfoRes }, { data: registeredMarketRes }] = useSuspenseFetchs([
    { url: CPLAT_API_ROUTES.getMarketModel, option: { method: 'POST' } },
    {
      url: CPLAT_API_ROUTES.getRegisteredMarketInfo,
      option: {
        method: 'POST',
        params: {
          cplatToken: user.CplatToken,
        },
      },
    },
    { url: CPLAT_API_ROUTES.getMarketModel, option: { method: 'POST' } },
  ]);

  const { data: marketInfoList } = marketInfoRes as AxiosResponse<MarketInfo[]>;
  const { data: list } = registeredMarketRes as AxiosResponse<
    {
      RegisteredMarketList: RegisteredMarketInfo[];
    }[]
  >;

  const registeredMarketList = list[0].RegisteredMarketList;

  const registeredMarket = registeredMarketList.find(
    (registeredMarket) =>
      registeredMarket.MarketNameEnglish === marketEnName && registeredMarket.MarketId === id,
  ) as RegisteredMarketInfo;

  const marketInfo = marketInfoList.find(
    (info) => info.MarketNameEnglish === marketEnName,
  ) as MarketInfo;

  if (!registeredMarket || !marketInfo) {
    // toast  라우팅
    if (toasts.length === 0) toast({ title: '잘못된 경로입니다.', variant: 'error' });
    navigate(ROUTES.market);
    return;
  }

  const { MarketName } = marketInfo as MarketInfo;

  return (
    <HeaderLayout title={`${MarketName} 연동`} onBack={() => navigate(-1)}>
      <Suspense fallback={<GlobalSpinner />}>
        <div className="mt-[14px] flex space-x-[30px]">
          <div className="pl-[6px]">
            <ChangePasswordContainer marketInfo={marketInfo} registeredMarket={registeredMarket} />
          </div>
          <div>
            <LinkGuide
              guide={{
                type: 'linkGuide',
                contents: {
                  title: '계정이 기억나지 않으신가요? 판매자 페이지에서 확인해 보세요',
                  mainText: '',
                  linkButtonText: `${MarketName} 판매자 페이지`,
                  linkUrl: guide.contents.linkUrl,
                },
              }}
            />
            <div className="mt-[30px]">
              <PromotionSellkeyCard />
            </div>
          </div>
        </div>
      </Suspense>
    </HeaderLayout>
  );
};

export default ChangeMarketPasswordPage;
