import { Card } from '@/components/atoms/Card';
import MarketInfoCard from '@/components/organisms/MarketInfoCard';
import { ROUTES } from '@/routes';
import { ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

type MarketInfoListContainerProps = {
  marketInfoList: MarketInfo[];
  isRegistered: boolean;
};

const MarketInfoListContainer = ({ marketInfoList }: MarketInfoListContainerProps) => {
  const navigate = useNavigate();
  const handleRoute = (path: string) =>
    navigate({
      pathname: ROUTES.marketRegister + `/${path}`,
    });

  const addMarket = marketInfoList.at(-1);

  return (
    <Card className={'grid w-[1290px] grid-cols-7 gap-[12px] p-[28px] pt-[26px]'}>
      {marketInfoList.slice(0, marketInfoList.length - 1).map((item) => (
        <MarketInfoCard key={item.MarketSeqNo} data={{ ...item }} onClick={handleRoute} />
      ))}
      <Link to={ROUTES.reqAddMarket} className="col-span-2">
        <div className="flex items-center rounded-[8px] bg-gray-50 px-[12px] pb-[10px] pt-[8px] leading-none hover:bg-gray-200">
          <img className="w-[30px]" src={addMarket?.MarketIconUrl} />
          <div>
            <h3 className="ml-[8px] text-gray-700 Body5S14">연동을 원하는 마켓이 없나요?</h3>
            <div className="flex items-center">
              <p className="ml-[8px] text-gray-700 transition-colors Body6M14 active:text-gray-500">
                마켓 추가 요청하기
              </p>
              <ChevronRight width="16px" className="ml-[2px] " />
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default MarketInfoListContainer;
