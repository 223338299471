import { CPLAT_API_ROUTES, usePost } from '@/api';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import BottomSheetContainer from '@/components/molecules/BottomSheet';
import usePostMessage from '@/hooks/usePostMessage';
import { useAppSelector } from '@/redux/store';
import { templateInquiryState } from '@/store/atom';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

const TemplatesInquiryDetail = () => {
  const inputRefTitle = useRef<HTMLTextAreaElement>(null);
  const inputRefContent = useRef<HTMLTextAreaElement>(null);
  const user = useAppSelector((state) => state.user);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const { goBackApp, enableSubmit, enableEdit } = usePostMessage();
  const inquiryCommentTemplateData = useRecoilValue(templateInquiryState);
  const [deleteCheckModalOpen, setDeleteCheckModalOpen] = useState<boolean>(false);

  const { mutate: setInquiryCommentTemplate } = usePost(CPLAT_API_ROUTES.setInquiryCommentTemplate);

  const handleSetInquiryCommentTemplate = (
    TemplateNo: number,
    UseYn: 'Y' | 'N',
    titleData: string,
    contentData: string,
  ) => {
    setInquiryCommentTemplate(
      {
        cplatToken: user.CplatToken,
        TemplateNo, // 신규 - 0, 수정 및 삭제 - 기존값
        Title: titleData,
        Content: contentData,
        UseYn, // 신규 및 수정 - "Y", 삭제 - "N"
      },
      {
        onSuccess() {
          if (TemplateNo === 0 && UseYn === 'Y') {
            goBackApp('register');
          } else if (TemplateNo > 0 && UseYn === 'Y') {
            goBackApp('update');
          } else {
            goBackApp('delete');
          }
        },
      },
    );
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      let titleData = '';
      let contentData = '';
      if (inputRefTitle.current) {
        titleData = inputRefTitle.current.value;
      }
      if (inputRefContent.current) {
        contentData = inputRefContent.current.value;
      }

      if (data['templateInquirySubmit']) {
        switch (data['templateInquirySubmit']) {
          case 'register':
            handleSetInquiryCommentTemplate(0, 'Y', titleData, contentData);
            break;
          case 'delete':
            setDeleteCheckModalOpen(true);
            break;
          case 'update':
            handleSetInquiryCommentTemplate(
              inquiryCommentTemplateData.TemplateNo,
              'Y',
              titleData,
              contentData,
            );
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);
    document.addEventListener('message', handleMessage as EventListener);
    return () => {
      window.removeEventListener('message', handleMessage);
      document.addEventListener('message', handleMessage as EventListener);
    };
  }, []);

  useEffect(() => {
    if (inquiryCommentTemplateData.TemplateNo > 0) {
      setTitle(inquiryCommentTemplateData.Title);
      setContent(inquiryCommentTemplateData.Content);
    }
  }, [inquiryCommentTemplateData]);

  useEffect(() => {
    if (title.length > 0 && content.length > 0) {
      enableSubmit('Y');
    } else {
      enableSubmit('N');
    }
  }, [title, content]);

  return (
    <div className="flex h-screen flex-col bg-white px-[20px]">
      <Input
        ref={inputRefTitle}
        className="mt-[4px] rounded-none border-0 text-gray-800 focus:border-0"
        variant={'outline'}
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          enableEdit('Y');
        }}
        placeholder="제목을 입력해 주세요."
      />
      <div className="h-[1px] w-full bg-gray-150"></div>
      <div className="flex h-full flex-grow flex-col">
        <textarea
          ref={inputRefContent}
          className="h-full pt-[20px] text-gray-700 Body2M16 focus-visible:outline-none"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
            enableEdit('Y');
          }}
          placeholder="내용을 300자 이내로 작성해 주세요."
          maxLength={300}
        ></textarea>
      </div>
      <BottomSheetContainer
        open={deleteCheckModalOpen}
        onDismiss={() => setDeleteCheckModalOpen(false)}
        containerClassName="px-[20px] py-[30px]"
      >
        <div className="mb-[16px] text-gray-900 Title2B20">템플릿을 삭제합니다</div>
        <div className="mb-[40px] whitespace-pre text-gray-750 Body2M16">
          {`템플릿을 삭제하면 복구할 수 없어요.\n그래도 삭제하시겠어요?`}
        </div>
        <div className="flex justify-center gap-[9px]">
          <Button
            type="button"
            variant={'secondary'}
            className="h-[52px] w-full"
            onClick={() => setDeleteCheckModalOpen(false)}
          >
            취소
          </Button>
          <Button
            type="button"
            variant={'default'}
            className="h-[52px] w-full"
            onClick={() => {
              handleSetInquiryCommentTemplate(
                inquiryCommentTemplateData.TemplateNo,
                'N',
                title,
                content,
              );
              setDeleteCheckModalOpen(false);
            }}
          >
            확인
          </Button>
        </div>
      </BottomSheetContainer>
    </div>
  );
};

export default TemplatesInquiryDetail;
