import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, X } from 'lucide-react';

import { ROUTES } from '@/routes';
import SellerBoxLogoRow from '@/assets/icons/SellerBoxLogoRow.svg?react';
import SellerBoxLogoFill from '@/assets/icons/SellerBoxLogoFill.svg?react';
import IconButton from '@/components/atoms/IconButton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/molecules/DropdownMenu';
import { useAppSelector } from '@/redux/store';
import SignOutContainer from '@/containers/auth/SignOutContainer';
import { cn } from '@/utils/tailwind';
import Button from '@/components/atoms/Button';
import InstallModal from '@/assets/images/InstallModal.png';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import { useLocalStorage } from 'usehooks-ts';

type SignInMenuItemProps = {
  storeName: string;
};

const SignInMenuItem = ({ storeName }: SignInMenuItemProps) => {
  return (
    <li className="!ml-[80px] flex justify-between border-l-[1px] pl-[31px] Body1S16">
      <DropdownMenu>
        <DropdownMenuTrigger className="cursor-pointer outline-none">
          <div className="flex items-center space-x-[4px]">
            <p className="transition-colors hover:text-primary">{storeName} 님</p>
            <ChevronDown width="16px" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-[10px]">
          <Link to={ROUTES.myProfile}>
            <DropdownMenuItem className="cursor-pointer">프로필 수정</DropdownMenuItem>
          </Link>
          <Link to={ROUTES.inquiry}>
            <DropdownMenuItem className="cursor-pointer">1:1 문의하기</DropdownMenuItem>
          </Link>
          <DropdownMenuItem>
            <SignOutContainer />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};

type GnbProps = {
  className?: string;
};

const Gnb = ({ className }: GnbProps) => {
  const { pathname } = useLocation();
  const { StoreName, CplatToken } = useAppSelector((state) => state.user);
  const isSignin = CplatToken && CplatToken !== '';
  const [isBannerVisible, setIsBannerVisible] = useLocalStorage('isBannerVisible', true);
  const [isInstallModalOpen, setIsInstallModalOpen] = useState<boolean>(false);
  const isBannerActive = isBannerVisible && pathname === '/market';

  const isPathMatching = (path: string) => pathname.startsWith(path);

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  return (
    <>
      {/* 앱 설치 유도 배너 */}
      {isBannerActive && (
        <div className="fixed left-0 top-0 z-50 flex w-full bg-primary-200 p-[9px] text-white">
          <div className="mx-auto flex w-[1290px] items-center justify-between">
            <div
              className="flex cursor-pointer items-center"
              onClick={() => {
                setIsInstallModalOpen(true);
              }}
            >
              <SellerBoxLogoFill />
              <p className="ml-[4px] mr-[16px] text-black text-gray-800 Body1S16">
                셀러박스 앱에서 더 많은 기능을 사용해 보세요
              </p>
              <Button variant={'ivory'} className="h-[34px] px-[10px] py-[4px]">
                앱 설치하기
              </Button>
            </div>
            <button onClick={handleCloseBanner} className="text-gray-800">
              <X />
            </button>
          </div>
        </div>
      )}
      {/* GNB */}
      <nav
        className={cn(
          'fixed left-0 top-0 z-40 flex h-[88px] w-[100vw] items-center bg-white text-gray-800',
          className,
          isBannerActive ? 'mt-[50px]' : '',
        )}
      >
        <div className="mx-auto flex w-[1290px] items-center justify-between">
          <IconButton>
            <Link to={ROUTES.market}>
              <SellerBoxLogoRow />
            </Link>
          </IconButton>
          <ol className="relative flex space-x-[80px] text-gray-800 Body1S16">
            <Link to={ROUTES.market}>
              <li className="relative">
                마켓 연동
                {isPathMatching('/market') && (
                  <div className="transition-width absolute bottom-[-32px] left-0 h-[2px] w-[60px] bg-black" />
                )}
              </li>
            </Link>
            <Link to={ROUTES.productShipment}>
              <li className="relative">
                상품 발송
                {isPathMatching('/productShipment') && (
                  <div className="transition-width absolute bottom-[-32px] left-0 h-[2px] w-[60px] bg-black" />
                )}
              </li>
            </Link>
            <Link to={ROUTES.inquiryList}>
              <li className="relative">
                문의 답변
                {isPathMatching('/inquiryList') && (
                  <div className="transition-width absolute bottom-[-32px] left-0 h-[2px] w-[60px] bg-black" />
                )}
              </li>
            </Link>
            {isSignin && <SignInMenuItem storeName={StoreName} />}
          </ol>
        </div>
      </nav>
      <div className={isBannerActive ? 'h-[150px]' : 'h-[100px]'} />
      {/* 앱 설치 유도 팝업 */}
      <Dialog
        open={isInstallModalOpen}
        onOpenChange={() => {
          setIsInstallModalOpen(false);
        }}
      >
        <DialogContent className="items-left gap-0 p-[30px] pb-[20px] text-start">
          <h1 className={cn('mb-[22px] text-gray-900 Title3S18LH26')}>
            실시간 주문/리뷰 알림, 마켓 별 매출 데이터 등
            <br />
            셀러박스 앱에서 더 많은 기능을 사용해 보세요!
          </h1>

          <img src={InstallModal} loading="lazy" />
          <Button
            variant={'secondary'}
            onClick={() => {
              setIsInstallModalOpen(false);
            }}
            className="mt-[40px] h-[44px] w-[200px] justify-self-center rounded-sm Body3S15"
          >
            확인
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Gnb;
