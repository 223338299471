import { useLocation, useNavigate } from 'react-router-dom';

import MarketRegisterContainer from '@/containers/market/MarketRegisterContainer';
import PromotionSellkeyCard from '@/components/organisms/PromotionSellkeyCard';
import MARKET_GUIDE_DATA, { type GuideLink, type GuideImage } from '@/contents/marketGuide';
import LinkGuide from '@/components/organisms/LinkGuide';
import ImgCard from '@/components/molecules/ImgCard';

import { CPLAT_API_ROUTES, useFetch } from '@/api';
import { useAppSelector } from '@/redux/store';
import HeaderLayout from '@/components/layouts/HeaderLayout';
import { ROUTES } from '@/routes';
import ExternalRegisterMarketPage from './ExternalRegisterMarketPage';
import DeprecatedMarketInfoCard from '@/components/organisms/DeprecatedMarketInfoCard';
import { DEPRECATED_MARKET } from '@/contents/deprecatedMarket';
import ErrorPage from './ErrorPage';
import CoupangGuideImgCard from '@/components/organisms/CoupangGuideImgCard';
import Street11GuideImgCard from '@/components/organisms/Street11GuideImgCard';
import LotteonGuideImgCard from '@/components/organisms/LotteonGuideImgCard';

const MarketRegisterPage = () => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  // TODO 추후 적절한 loading ui 가 들어오면 suspenseFetch 로 수정
  const { data: res, isLoading } = useFetch<CplatApiResponse<MarketInfo[]>>(
    CPLAT_API_ROUTES.getMarketModel,
    {
      method: 'POST',
      params: { cplatToken: user.CplatToken },
    },
  );

  if (!res || isLoading) return null;

  const { data: list } = res;

  const marketPathName = location.pathname.split('/').at(-1);
  const data = list.find((item) => item.MarketNameEnglish === marketPathName) as MarketInfo;
  const { MarketName, MarketNameEnglish, MarketTextboxOptionalList, MarketAuthCheckOptionalList } =
    data;

  // 외부에서 마켓 연동을 해야 하는 마켓 분기 처리 현재 (스마트스토어, 샵바이, 카페 24)
  const isExternalRegisterMarket =
    MarketNameEnglish === 'smartstore' ||
    MarketNameEnglish === 'shopby' ||
    MarketNameEnglish === 'cafe24';

  if (isExternalRegisterMarket)
    return (
      <ExternalRegisterMarketPage marketName={MarketName} marketNameEnglish={MarketNameEnglish} />
    );

  const marketGuide = MARKET_GUIDE_DATA[MarketNameEnglish];

  if (!marketGuide) return <ErrorPage isError className="w-full" />;

  const imgGuide = marketGuide.filter((guide) => guide.type === 'guideImg') as GuideImage[];
  const linkGuide = marketGuide.filter((guide) => guide.type === 'linkGuide') as GuideLink[];

  const isEmptyGuideImg = imgGuide.length <= 0;

  const handleBack = () => {
    navigate(ROUTES.market);
  };

  // 기존 esm, 지마켓, 옥션은 deprecated 되었음 new가 붙는 name이 연동가능한 마켓(gmarket-new)
  const isDeprecated = DEPRECATED_MARKET.includes(marketPathName || '');

  return (
    <HeaderLayout title={`${MarketName} 연동`} isBottomPadding onBack={handleBack}>
      {isDeprecated ? (
        <div className="mt-[12px] flex w-full justify-center">
          <DeprecatedMarketInfoCard marketList={list} selectedMarketName={marketPathName || ''} />
        </div>
      ) : (
        <div className="mt-[12px] flex space-x-[30px]">
          <MarketRegisterContainer
            marketName={MarketName}
            marketNameEnglish={MarketNameEnglish}
            marketTextboxOptionalList={MarketTextboxOptionalList}
            marketAuthCheckOptionalList={MarketAuthCheckOptionalList}
          />
          <div className="w-[844px] space-y-[30px]">
            {linkGuide.map((guide) => (
              <LinkGuide guide={guide} key={guide.contents.linkUrl} />
            ))}
            {isEmptyGuideImg ? (
              <PromotionSellkeyCard />
            ) : (
              <>
                {imgGuide.map(({ guideImageUrl }) => {
                  switch (MarketName) {
                    case '쿠팡':
                      return <CoupangGuideImgCard key={guideImageUrl} />;

                    case '11번가':
                      return <Street11GuideImgCard />;

                    case '롯데온':
                      return <LotteonGuideImgCard />;

                    default:
                      return <ImgCard key={guideImageUrl} src={guideImageUrl} />;
                  }
                })}
              </>
            )}
          </div>
        </div>
      )}
    </HeaderLayout>
  );
};

export default MarketRegisterPage;
