import { cn } from '@/utils/tailwind';

type BulletListType = {
  data: Array<string>;
  className?: string;
};

const BulletList = ({ data, className }: BulletListType) => {
  return (
    <div className={cn('flex flex-col gap-[22px] text-gray-800 Body6M14', className)}>
      {data.map((text, textIndex) => (
        <div key={text} className="flex items-start">
          <span className="mx-[7px]">•</span>
          <p key={textIndex}>
            {text.split('\n').map((line, lineIndex) => (
              <span key={lineIndex}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
      ))}
    </div>
  );
};

export default BulletList;
