import { Card } from '@/components/atoms/Card';
import LinkButton from '@/components/molecules/LinkButton';

import AlarmInfoIcon from '@/assets/icons/AlarmInfoIcon.svg?react';
import SmartStoreGuideTopImg from '@/assets/images/SmartstoreLoginGuideTop.png';
import SmartStoreGuideBottomImg from '@/assets/images/SmartstoreLoginGuideBottom.png';
import ShopbyGuideTopImg from '@/assets/images/ShopbyLoginGuideTop.png';
import ShopbyGuideBottomImg from '@/assets/images/ShopbyLoginGuideBottom.png';
import Cafe24TopImg from '@/assets/images/Cafe24TopGuide.png';
import Cafe24BottomImg from '@/assets/images/Cafe24BottomGuide.png';

import HeaderLayout from '@/components/layouts/HeaderLayout';
import { useNavigate } from 'react-router-dom';

const SMART_COMMERCE_SOLUTION_URL =
  'https://solution.smartstore.naver.com/ko/solution/10XxRcUYSy4N7fiZ5BYNMh/detail';

const SMART_COMMERCE_SOLUTION_SELLER_PAGE_URL =
  'https://sell.smartstore.naver.com/#/member/auth/management';

type Contents = {
  [key: string]: {
    topGuide: {
      title: string;
      subTitle: string;
      text: string;
      buttonText: string;
      link: string;
      guideImg: string;
    };
    bottomGuide: {
      title: string;
      subTitle: string;
      text: string;
      buttonText: string;
      link: string;
      guideImg: string;
    };
  };
};

const CONTENTS: Contents = {
  smartstore: {
    topGuide: {
      title: '커머스 솔루션 마켓 추가하기',
      subTitle: '커머스 솔루션 마켓 구독을 반드시 진행해 주세요!',
      text: '스마트스토어 연동을 위해 커머스 솔루션 내 셀러박스 구독이 필수적으로 필요해요.',
      buttonText: '커머스 솔루션 마켓 이동',
      link: SMART_COMMERCE_SOLUTION_URL,
      guideImg: SmartStoreGuideTopImg,
    },
    bottomGuide: {
      title: '셀러박스 주매니저 등록하기',
      subTitle: '셀러박스를 주매니저로 등록해 주세요!',
      text: '매니저 초대를 완료하지 않을 경우, 리뷰 수집 및 답글 작성 기능 사용이 제한돼요.',
      buttonText: '판매자 페이지 이동',
      link: SMART_COMMERCE_SOLUTION_SELLER_PAGE_URL,
      guideImg: SmartStoreGuideBottomImg,
    },
  },
  shopby: {
    topGuide: {
      title: '샵바이 앱스토어 접속',
      subTitle: '앱스토어에서 셀러박스 신청을 진행해 주세요!',
      text: '샵바이 앱스토어 페이지는 PC에서만 접속할 수 있어요',
      buttonText: '샵바이 앱스토어 이동',
      link: 'https://apps.nhn-commerce.com/apps/1207',
      guideImg: ShopbyGuideTopImg,
    },
    bottomGuide: {
      title: '샵바이 관리자 페이지 접속',
      subTitle: '관리자 페이지에서 셀러박스 실행을 완료해 주세요!',
      text: '샵바이 관리자 페이지는 PC에서만 접속할 수 있어요',
      buttonText: '판매자 페이지 이동',
      link: 'https://admin.shopby.co.kr/pro/app/basic/list?mallNo=78585&appStatus=ACTIVE&page=1',
      guideImg: ShopbyGuideBottomImg,
    },
  },
  cafe24: {
    topGuide: {
      title: '카페24 앱스토어 접속',
      subTitle: '앱스토어에서 셀러박스 신청을 진행해 주세요!',
      text: '카페24 앱스토어 페이지는 모바일에서도 접속할 수 있어요',
      buttonText: '카페24 앱스토어 이동',
      link: 'https://store.cafe24.com/kr/apps/13737',
      guideImg: Cafe24TopImg,
    },
    bottomGuide: {
      title: '카페24 관리자 페이지 접속',
      subTitle: '관리자 페이지에서 셀러박스 실행을 완료해 주세요!',
      text: '카페24 관리자 페이지는 모바일에서도 접속할 수 있어요',
      buttonText: '관리자 페이지 이동',
      link: 'https://chdb57.cafe24.com/disp/admin/shop1/myapps/list',
      guideImg: Cafe24BottomImg,
    },
  },
};

type ExternalRegisterMarketPageProps = {
  marketNameEnglish: string;
  marketName: string;
};

const ExternalRegisterMarketPage = ({
  marketName,
  marketNameEnglish,
}: ExternalRegisterMarketPageProps) => {
  const content = CONTENTS[marketNameEnglish];
  const navigate = useNavigate();

  return (
    <HeaderLayout title={`${marketName} 연동`} isBottomPadding onBack={() => navigate(-1)}>
      <div className="mx-auto mt-[16px] flex flex-col items-center space-y-[30px]">
        <Card className="w-[850px] p-[30px]">
          <div className="flex justify-between">
            <div>
              <h3 className="text-gray-900 c-t3">
                <span className="mr-[8px] rounded-[4px] bg-red-100 px-[6px] py-[4px] align-bottom text-red-300 c-b4">
                  1단계
                </span>
                {content.topGuide.title}
              </h3>
              <div className="mt-[22px] flex">
                <AlarmInfoIcon />
                <div className="ml-[8px]">
                  <h2 className="flex text-gray-900 c-t3">{content.topGuide.subTitle}</h2>
                  <p className="mt-[8px] w-[335px] text-gray-800 c-b2">{content.topGuide.text}</p>
                  <div className="mt-[42px]">
                    <LinkButton to={content.topGuide.link}>
                      {content.topGuide.buttonText}
                    </LinkButton>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                className="h-[227px] w-[375px]"
                src={content.topGuide.guideImg}
                alt="마켓연동 가이드 이미지"
              />
            </div>
          </div>
        </Card>
        <Card className="w-[850px] p-[30px]">
          <h3 className="text-gray-900 c-t3">
            <span className="mr-[8px] rounded-[4px] bg-red-100 px-[6px] py-[4px] align-bottom text-red-300 c-b4">
              2단계
            </span>
            {content.bottomGuide.title}
          </h3>
          <div className="mt-[22px] flex">
            <AlarmInfoIcon />
            <div className="ml-[8px]">
              <h2 className="flex text-gray-900 c-t3">{content.bottomGuide.subTitle}</h2>
              <p className="mt-[8px] text-gray-800 c-b2">{content.bottomGuide.text}</p>
              <div className="mt-[35px]">
                <LinkButton to={content.bottomGuide.link}>
                  {content.bottomGuide.buttonText}
                </LinkButton>
              </div>
            </div>
          </div>
          <img
            className="mt-[37px]"
            src={content.bottomGuide.guideImg}
            alt="마켓연동 가이드 이미지"
          />
        </Card>
      </div>
    </HeaderLayout>
  );
};

export default ExternalRegisterMarketPage;
