import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';

import AuthReducer from './authSlice';
import ToastReducer from './toastSlice';
import UserReducer from './userSlice';
import CodeReducer from './codeSlice';
import OrderReducer from './orderSlice';
import ModalReducer from './modalSlice';
import LoadingReducer from './loadingSlice';
import InquiryReducers from './inquirySlice';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    toast: ToastReducer,
    user: UserReducer,
    code: CodeReducer, // 로그인 코드 4자리
    order: OrderReducer,
    modal: ModalReducer,
    loading: LoadingReducer,
    inquiry: InquiryReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  enhancers: [createReduxEnhancer()],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
