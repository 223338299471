import { ModalProps, close, open } from '@/redux/modalSlice';
import { useAppDispatch } from '@/redux/store';

export const useModal = () => {
  const dispatch = useAppDispatch();

  const openModal = (modalInfo: ModalProps) => {
    dispatch(open(modalInfo));
  };

  const closeModal = () => {
    dispatch(close());
  };

  return { openModal, closeModal };
};
