import { useNavigate } from 'react-router';
import { startTransition, useState } from 'react';

import Button from '@/components/atoms/Button';
import { Card } from '@/components/atoms/Card';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import RegisteredCard from '@/components/organisms/RegisteredCard';

import { type CheckedState } from '@radix-ui/react-checkbox';
import UnregisterDialogContainer from './UnregisterDialogContainer';
import UpdateMarketAliasDialogContainer from './UpdateMarketAliasDialogContainer';

import { ROUTES } from '@/routes';

type MarketDialogType = 'alias' | 'unRegister' | 'password' | 'guide' | '';

const EDIT_BUTTONS: { type: MarketDialogType; buttonText: string }[] = [
  { type: 'alias', buttonText: '마켓 별칭 변경' },
  { type: 'password', buttonText: '비밀번호 수정' },
  { type: 'unRegister', buttonText: '연동 해지' },
];

// 스마트스토어는 edit 버튼이 다르다 (가이드보기)
const EDIT_BUTTONS_FOR_EXTERNAL_MARKET: { type: MarketDialogType; buttonText: string }[] = [
  // { type: 'alias', buttonText: '마켓 별칭 변경' },
  { type: 'guide', buttonText: '가이드 보기' },
  { type: 'unRegister', buttonText: '연동 해지' },
];

type MarketRegisterDialogsProps = {
  checkedMarket: RegisteredMarketInfo;
  type: MarketDialogType;
  onClose: () => void;
  handleResetCheckedMarket: () => void;
};

const MarketRegisterDialogs = ({
  type,
  onClose,
  checkedMarket,
  handleResetCheckedMarket,
}: MarketRegisterDialogsProps) => {
  switch (type) {
    case 'alias':
      return (
        <UpdateMarketAliasDialogContainer
          onResetChecked={handleResetCheckedMarket}
          onClose={onClose}
          checkedMarket={checkedMarket}
        />
      );

    case 'unRegister':
      return (
        <UnregisterDialogContainer
          onResetChecked={handleResetCheckedMarket}
          onClose={onClose}
          checkedMarket={checkedMarket}
        />
      );
    default:
      onClose();
      return null;
  }
};

type RegisteredMarketContainerProps = {
  className?: string;
  registeredMarketList: RegisteredMarketInfo[];
  isEmptyRegistedMarket: boolean;
};

type CheckedMarketState = {
  id: string;
  checked: CheckedState;
  marketInfo: RegisteredMarketInfo;
};

const DEFAUT_REGISTERED_MARKET_INFO: any = {
  AccessKey: '',
  HasToChangePassword: false,
  MarketIconUrl: '',
  MarketId: '',
  MarketName: '',
  MarketNameEnglish: '',
  MarketPassword: '',
  MarketType: '',
  SecretKey: '',
  VendorId: '',
  StoreComment: '',
  MarketAlias: '',
  IsAliasEnable: false,
};

const initState = {
  id: '',
  checked: false,
  marketInfo: DEFAUT_REGISTERED_MARKET_INFO,
};

const RegisteredMarketContainer = ({
  className,
  registeredMarketList,
  isEmptyRegistedMarket,
}: RegisteredMarketContainerProps) => {
  const navigate = useNavigate();

  const [checkedMarket, setCheckedMarket] = useState<CheckedMarketState>(initState);
  const [type, setType] = useState<MarketDialogType>('');
  const [iseOpen, setIsOpen] = useState(false);

  const editButtonDisable = !checkedMarket.checked || isEmptyRegistedMarket;
  // 스마트 스토어는 마켓별칭, 비밀번호 변경 불가능
  const checkSmartStoreButtonDisable = (type: MarketDialogType) =>
    checkedMarket.marketInfo.MarketNameEnglish === 'smartstore' &&
    (type === 'alias' || type === 'password');

  /**
   * id는 MarketId  + MarketNameEnglish
   * 이유는 같은 마켓으로 여러개의 마켓을 등록할 수 있음 -> MarketNameEnglish 만 사용 불가
   * MarketId 는 사용자의 이커머스 Id임 -> 다른 마켓에서 중복될 수 있음
   */
  const handleSelectMarket = (id: string, checked: CheckedState) => {
    startTransition(() => {
      setCheckedMarket({
        id,
        checked,
        marketInfo:
          registeredMarketList.find(
            (market) => market.MarketNameEnglish + market.MarketId === id,
          ) || DEFAUT_REGISTERED_MARKET_INFO,
      });
    });
  };

  // TODO 스마트스토어 연동해지는 별도의 다이얼 로그로
  const handleEditButtonClick = (type: MarketDialogType) => {
    // 비빌번호는 dialog가 아니라 라우팅
    switch (type) {
      case 'password':
        navigate(
          `${ROUTES.changePassword}/${checkedMarket.marketInfo.MarketNameEnglish}?id=${btoa(
            checkedMarket.marketInfo.MarketId,
          )}`,
        );
        break;

      case 'guide':
        navigate(`${ROUTES.marketRegister}/${MarketNameEnglish}`);
        break;

      default:
        setType(type);
        setIsOpen(true);
    }
  };

  const handleCloseDialog = () => setIsOpen(false);
  const handleResetCheckedMarket = () => setCheckedMarket(initState);

  const { MarketNameEnglish } = checkedMarket.marketInfo;
  const isExternalRegisterMarket =
    MarketNameEnglish === 'smartstore' ||
    MarketNameEnglish === 'shopby' ||
    MarketNameEnglish === 'cafe24';

  return (
    <div className={className}>
      <hgroup className="flex items-center justify-between">
        <h2 className="text-gray-800 c-b1">
          연동된 마켓
          {!isEmptyRegistedMarket && (
            <span className="ml-[8px]">
              <strong className="font-[700] text-primary-500">{registeredMarketList.length}</strong>
              개
            </span>
          )}
        </h2>
        <div className="flex space-x-[8px]">
          {/** 여기 shopby 수정 */}
          {isExternalRegisterMarket
            ? EDIT_BUTTONS_FOR_EXTERNAL_MARKET.map(({ buttonText, type }) => (
                <Button
                  onClick={() => handleEditButtonClick(type)}
                  disabled={editButtonDisable || checkSmartStoreButtonDisable(type)}
                  key={type}
                  className="h-[34px] bg-primary-100 px-[10px] text-primary c-b6 hover:bg-primary-200 active:bg-primary-100 disabled:text-gray-300"
                >
                  {buttonText}
                </Button>
              ))
            : EDIT_BUTTONS.map(({ buttonText, type }) => (
                <Button
                  onClick={() => handleEditButtonClick(type)}
                  disabled={editButtonDisable}
                  key={type}
                  className="h-[34px] bg-primary-100 px-[10px] text-primary c-b6 hover:bg-primary-200 active:bg-primary-100 disabled:text-gray-300"
                >
                  {buttonText}
                </Button>
              ))}
        </div>
      </hgroup>
      {isEmptyRegistedMarket ? (
        <Card className={'mt-[12px] flex w-[1290px] items-center justify-center'}>
          <p className="py-[43px] text-gray-800 c-b5">연동된 마켓이 없어요</p>
        </Card>
      ) : (
        <Card className={'mt-[12px] grid w-[1290px] grid-cols-4 gap-y-[20px] p-[30px]'}>
          {registeredMarketList.map((item) => (
            <RegisteredCard
              key={item.MarketNameEnglish + item.MarketAlias}
              {...item}
              checked={
                checkedMarket.id === item.MarketNameEnglish + item.MarketId
                  ? checkedMarket.checked
                  : false
              }
              onCheckedChange={handleSelectMarket}
            />
          ))}
        </Card>
      )}
      {checkedMarket.id !== '' && (
        <Dialog open={iseOpen} onOpenChange={(flag) => setIsOpen(flag)}>
          <DialogContent className="max-w-[440px]">
            <MarketRegisterDialogs
              handleResetCheckedMarket={handleResetCheckedMarket}
              checkedMarket={checkedMarket.marketInfo}
              type={type}
              onClose={handleCloseDialog}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default RegisteredMarketContainer;
