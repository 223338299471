import Button from '@/components/atoms/Button';
import HeaderLayout from '@/components/layouts/HeaderLayout';
import { Link, useNavigate } from 'react-router-dom';
import ArrowOrangeForwardIcon from '@/assets/icons/ArrowOrangeForwardIcon.svg?react';
import AppIntstallImg from '@/assets/images/AppIntstallImg.png';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/molecules/Accordion';
import { CPLAT_API_ROUTES, useSuspenseFetchs } from '@/api';
import { AxiosResponse } from 'axios';
import InfoFillGrayIcon from '@/assets/icons/InfoFillGrayIcon.svg?react';
import BulletList from '@/components/molecules/BulletList';
import { ROUTES } from '@/routes';

const NOTICE_DATA = [
  {
    title: '문의 수집 정책 안내',
    description: [
      '셀러박스에서는 미답변 상태의 문의글만 수집하여 보여드리고 있어요.',
      '답변이 완료된 문의글 확인은 마켓 판매자 페이지에서 진행해 주세요.',
    ],
  },
  {
    title: '긴급문의(메시지) 처리 안내',
    description: [
      '긴급문의의 경우 마켓 내 등록 여부만 제공하며, 마켓 판매자 페이지에서 직접 처리가 필요해요.',
      '긴급문의란?\n마켓에서 판매자에게 직접 발송하는 긴급 메시지를 의미해요.',
    ],
  },
  {
    title: '답변 등록 이후 정책 안내',
    description: [
      '셀러박스에서는 작성된 문의글의 수정 및 삭제가 불가능하며, 답변을 등록할 경우 내역에서 삭제돼요.',
      '추가 답변 혹은 수정 및 삭제가 필요할 경우 마켓 판매자 페이지에서 진행해 주세요.',
    ],
  },
];

const ENABLE_MARKET_NAME_ENGLISH_LIST = [
  'smartstore',
  'coupang',
  'gmarket-new',
  'auction-new',
  '11st',
  'cafe24',
  'talkstore',
  'shopby',
  'lotteon',
];

const InquiryGuidePage = () => {
  const navigate = useNavigate();

  const [{ data: marketInfoRes }] = useSuspenseFetchs([
    { url: CPLAT_API_ROUTES.getMarketModel, option: { method: 'POST' } },
  ]);
  const { data: marketInfoList } = marketInfoRes as AxiosResponse<MarketInfo[]>;
  const enableMarketInfoList = marketInfoList.filter((item) =>
    ENABLE_MARKET_NAME_ENGLISH_LIST.includes(item.MarketNameEnglish),
  );

  return (
    <HeaderLayout
      className="flex flex-col"
      title="문의 답변 이용 안내"
      onBack={() => navigate(-1)}
      isBottomPadding
    >
      <div className="mt-[30px] flex w-[850px] flex-col self-center rounded-[20px] border-[1px] border-gray-200 bg-white p-[30px]">
        <p className="mb-[20px] text-gray-800 Title2B20">
          이제 셀러박스에서
          <br />
          9개 마켓의 문의글을 처리할 수 있어요
        </p>
        <div className="mb-[30px] items-center space-y-[12px] self-center">
          <div className="flex justify-center gap-[24px] rounded-[10px] bg-gray-50 px-[21px] py-[20px]">
            {enableMarketInfoList.map((item) => (
              <img
                key={item.MarketIconUrl}
                src={item.MarketIconUrl}
                className="h-[50px] w-[50px]"
              />
            ))}
          </div>
          <p className="text-gray-500 Body6M14">
            *아임웹은 문의 답변을 처리할 수 있도록 준비중에 있어요.
          </p>
        </div>
        <div className="mb-[20px]">
          <p className="mb-[10px]">아직 연동하지 않은 마켓이 있다면?</p>
          <Link to={ROUTES.market}>
            <Button variant={'ivory'} className="px-[10px]">
              마켓 추가연동
              <ArrowOrangeForwardIcon />
            </Button>
          </Link>
        </div>
        <div className="mb-[30px]">
          <p className="mb-[20px]">앱에서 발송 처리 및 매출 내역을 확인할 수 있어요</p>
          <img src={AppIntstallImg} alt="image" className="rounded-[4px]" />
        </div>
        <div>
          <h2 className="mb-[10px] text-gray-800 Body1S16">도움말</h2>
          <div>
            <Accordion className="space-y-[10px]" collapsible type="single">
              {NOTICE_DATA.map((item, index) => {
                return (
                  <AccordionItem value={index.toString()} key={index} className="border-0">
                    <AccordionTrigger className="rounded-[8px] bg-gray-100 py-[12px] pl-[10px] pr-[14px] hover:bg-gray-150 hover:no-underline">
                      <div className="flex items-center gap-[4px]">
                        <InfoFillGrayIcon />
                        {item.title}
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="mt-[4px] rounded-[8px] bg-gray-50 px-[10px] py-[12px] pb-0">
                      <BulletList data={item.description} />
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default InquiryGuidePage;
