import { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSessionStorage } from 'usehooks-ts';

import { CPLAT_API_ROUTES, usePost } from '@/api';
import SellerBoxLogoColumn from '@/assets/icons/SellerBoxLogoColumn.svg?react';
import SquareNumberOne from '@/assets/icons/SquareNumberOne.svg?react';
import SquareNumberTwo from '@/assets/icons/SquareNumberTwo.svg?react';

import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { setUser } from '@/redux/userSlice';
import { ROUTES } from '@/routes';
import useGetToastFormat from '@/hooks/useGetToastFormat';
import { AES } from '@/utils/AES';
import { setFirst, setSecond, setThird, setFourth, resetAll } from '@/redux/codeSlice';

type FormData = {
  first: string;
  second: string;
  third: string;
  fourth: string;
};

const AUTH_INPUTS = [
  { key: 'first' },
  { key: 'second' },
  { key: 'third' },
  { key: 'fourth' },
] as const;

type KeyType = (typeof AUTH_INPUTS)[number]['key'];

const CodeSigninContainer = () => {
  const dispatch = useAppDispatch();
  const codeState = useAppSelector((state) => state.code);
  const [isPending, setIsPending] = useState(false);

  const { mutate: codeSignIn } = usePost(CPLAT_API_ROUTES.tempSignIn);
  const { mutate: signInByPass } = usePost(CPLAT_API_ROUTES.signinByPass);
  const { mutate: getStoreList } = usePost(CPLAT_API_ROUTES.getStoreList);
  const { register, watch, handleSubmit, setValue, formState, setError } = useForm<FormData>({
    defaultValues: codeState,
  });
  const formValues = watch();
  const { errors } = formState;

  const navigate = useNavigate();

  const { errorToast } = useGetToastFormat();

  const inputRefs = AUTH_INPUTS.map(() => useRef<HTMLInputElement>(null));

  const [_, setSessionStorageCplatToken] = useSessionStorage('cplatToken', '');

  // 4자리 입력시 자동 로그인  but 4자리로 하드코딩
  useEffect(() => {
    const { first, second, third, fourth } = formValues;
    const isComplete = (first + second + third + fourth).length === 4 && !isPending;

    if (isComplete) onSubmit(formValues);
  }, [formValues.first, formValues.second, formValues.third, formValues.fourth]);

  /**
   * 번호를 입력시 다음 input focus 해주는 함수
   */
  const moveFocusInput = useCallback(
    (key: string, e: React.ChangeEvent<HTMLInputElement>, direction: 'next' | 'prev' = 'next') => {
      const { target } = e;
      const currentIndex = AUTH_INPUTS.findIndex((input) => input.key === key);

      if (direction === 'next' && target.value.length >= target.maxLength) {
        const nextIndex = currentIndex + 1;
        const nextInputRef = inputRefs[nextIndex];
        nextInputRef?.current?.focus();
      } else if (direction === 'prev' && currentIndex > 0) {
        const prevIndex = currentIndex - 1;
        const prevInputRef = inputRefs[prevIndex];
        prevInputRef?.current?.focus();
      }
    },
    [],
  );

  const isDisable = Object.values(formValues)
    .filter((v) => typeof v === 'string')
    .some((v) => v === '');

  const handleChange = (key: KeyType, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const inputVal = value.slice(value.length - 1).toLocaleUpperCase();
    setValue(key, inputVal);
    dispatch(
      {
        first: setFirst,
        second: setSecond,
        third: setThird,
        fourth: setFourth,
      }[key](inputVal),
    );
    if (e.target.value === '') moveFocusInput(key, e, 'prev');
    else moveFocusInput(key, e, 'next');
  };

  const handleKeyDown = (key: KeyType, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && e.currentTarget.value === '') {
      e.preventDefault(); // 기본 백스페이스 동작을 취소
      // @ts-ignore
      moveFocusInput(key, e, 'prev');
    }
  };

  const onSubmit: SubmitHandler<FormData> = (values, e) => {
    e?.preventDefault();
    const authCode = Object.values(values)
      .filter((v) => typeof v === 'string')
      .reduce((acc: string, curr) => (acc += curr), '');
    setIsPending(true);

    codeSignIn(
      { AuthCode: authCode },
      {
        onSuccess(res) {
          const { code, data } = res.data as CplatApiResponse<{ CplatToken: string }[]>;

          if (code === '200') {
            const CplatToken = data[0].CplatToken;
            setSessionStorageCplatToken(AES.encryptData(CplatToken));
            signInByPass(
              { CplatToken, isTest: false, osType: 'web', currentVersion: APP_VERSION },
              {
                onSuccess(res) {
                  const { code, data, message } = res.data as CplatApiResponse<UserData[]>;

                  if (code === '200') {
                    dispatch(setUser(data[0]));
                    dispatch(resetAll());
                    getStoreList(
                      {
                        cplatToken: CplatToken,
                      },
                      {
                        onSuccess({ data }) {
                          const { code } = data;
                          if (code === '200') {
                            navigate(ROUTES.productShipment);
                          } else {
                            navigate(`${ROUTES.market}?origin=signin`, { replace: true });
                          }
                        },
                      },
                    );
                  } else {
                    errorToast(code, message);
                  }
                },
              },
            );
          } else if (code === '723') {
            setIsPending(false);
            setError('root', { message: '코드가 올바르지 않습니다. 다시 입력해 주세요.' });

            setTimeout(() => {
              setError('root', {});
            }, 3000);
          } else {
            setIsPending(false);
            errorToast(code);
          }
        },
        onError(error) {
          setIsPending(false);
          console.error(error);
        },
      },
    );
  };

  return (
    <div className="mt-[33px] flex w-[570px] flex-col items-center text-center">
      <SellerBoxLogoColumn />
      <h2 className="mt-[28px] text-gray-800 Title2B20">코드 번호를 입력해 주세요</h2>
      <div className="mt-[32px] flex w-[329px] flex-col gap-[8px] rounded-sm bg-gray-100 px-[8px] py-[9px]">
        <div className="flex items-center gap-[4px]">
          <SquareNumberOne />
          <p className="text-gray-750 Caption1M12">
            [셀러박스 앱] - [더보기] - [셀러박스 로그인] 클릭해 주세요
          </p>
        </div>
        <div className="flex items-center gap-[4px]">
          <SquareNumberTwo />
          <p className="text-gray-750 Caption1M12">
            앱 화면에 보이는 4자리 코드 번호를 아래 칸에 입력해 주세요
          </p>
        </div>
      </div>

      <form className="mb-[99px] mt-[30px]" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex space-x-[11px]">
          {AUTH_INPUTS.map(({ key }, i) => (
            <Input
              {...(register(key),
              {
                ref: inputRefs[i],
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleChange(key, e),
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(key, e),
              })}
              key={key}
              value={formValues[key]}
              className={`h-[80px] w-[74px] rounded-[8px] text-center  ${
                formValues[key].length >= 1 ? 'bg-gray-150' : 'bg-white'
              } border-[1px] border-gray-200 !CodeB40`}
            />
          ))}
        </div>
        <div className="relative">
          {errors.root?.message && (
            <p className="absolute left-0 top-0 mt-[4px] flex text-red-600 c-c1">
              코드가 올바르지 않습니다. 다시 입력해 주세요.
            </p>
          )}
        </div>
        <Button
          isLoading={isPending}
          type="submit"
          disabled={isDisable || isPending}
          className="mt-[32px] h-[52px] w-full rounded-[16px] c-b1"
        >
          로그인
        </Button>
      </form>
    </div>
  );
};

export default CodeSigninContainer;
