import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import EN_TO_KO_MARKET_NAME_MAP from '@/contents/englishToKoreanMarketNames';

// TODO new 처리해야 한다.
function DocumentTitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getTitleForPath(location.pathname);
    document.title = pageTitle || 'sellerbox';
  }, [location]);

  return null;
}

const getTitleForPath = (path: string) => {
  if (['/', '/signin'].includes(path)) {
    return '셀러박스';
  } else if (/^\/market\/register\/([^\/]+)$/i.test(path)) {
    const marketName =
      EN_TO_KO_MARKET_NAME_MAP[path.match(/^\/market\/register\/([^\/]+)$/i)![1]] || '';
    return `${marketName} 연동`;
  } else if (/^\/market\/register\/changePassword\/([^\/]+)$/i.test(path)) {
    return '비밀번호 수정';
  } else if (path === '/my/inquiry') {
    return '1:1문의하기';
  } else if (path === '/my/inquiry/history') {
    return '문의 내역 보기';
  } else if (path === '/my/profile') {
    return '프로필 수정';
  } else if (path === '/market/add') {
    return '마켓 추가 요청';
  } else if (path === '/market') {
    return '마켓 연동';
  } else if (path === '/productShipment') {
    return '상품 발송';
  } else {
    return '셀러박스';
  }
};

export default DocumentTitleUpdater;
