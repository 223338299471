import { useRef, useState, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import BubbleContainer from './_components/BubbleContainer';
import Input from '@/components/atoms/Input';
import { cn } from '@/utils/tailwind';
import BotBubble from './_components/BotBubble';
import { cplatApiV2Sellkey } from '@/api';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/redux/store';
import { useToast } from '@/hooks/useToast';
/**
 * id : 디폴트 메시지 0, 생성된 메시지 Date.now() + 1
 * text : 메시지 텍스트
 * sender : user, bot 여부
 * subText : 생성된 고려요소
 * list : 생성된 키워드
 * remainCount : 동일 검색어에 대한 호출 가능 잔여 횟수
 */
export interface Message {
  id: number;
  text: string;
  sender: 'user' | 'bot';
  subText?: string;
  list?: Array<string>;
  remainCount?: number;
}

export interface ProductName {
  product_name: string;
  Consideration?: string;
  Keywords?: Array<string>;
}

const ChangeNamePage = () => {
  const [searchParams] = useSearchParams();
  const cplatToken = searchParams.get('cplatToken');
  const user = useAppSelector((state) => state.user);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [storedMessages, setStoredMessages] = useLocalStorage<Message[]>('change_name_messages', [
    {
      id: 0,
      text: '도매몰 또는 해외 상품명을 붙여넣어보세요.\nAI가 알맞은 상품명으로 바꿔드려요.',
      sender: 'bot',
    },
  ]);

  // 24시간 지난 메시지 제거하고 보여주기
  const filteredMessages = storedMessages.filter((storedMessage: Message) => {
    if (storedMessage.id === 0) return true;
    const isWithin24Hours = new Date().getTime() - storedMessage.id <= 86400000;
    return isWithin24Hours;
  });

  const [messages, setMessages] = useState<Message[]>(filteredMessages);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isActive = !isLoading && input.trim();
  const { toast } = useToast();
  const [productNameListData, setProductNameListData] = useState<Array<ProductName>>([]);

  const botMessages = messages.filter((message) => message.sender === 'bot');
  const latestBotMessageId =
    botMessages.length > 0 ? Math.max(...botMessages.map((msg) => msg.id)) : null;

  const getProductName = async () => {
    try {
      setIsLoading(true);
      const {
        data: { code, data },
      } = await cplatApiV2Sellkey.post('sellkey/get_optimization_product_name', {
        CplatToken: cplatToken || user.CplatToken,
        ProductName: input.replace('\n', ''),
      });

      if (code === '200') {
        const { ProductNameList } = data[0];
        setProductNameListData(ProductNameList);
        const botMessage: Message = {
          id: Date.now() + 1,
          text: ProductNameList[0].product_name,
          sender: 'bot',
          subText: ProductNameList[0].Consideration,
          list: ProductNameList[0].Keywords,
          remainCount: 4,
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } else {
        if (inputRef.current) {
          inputRef.current.blur();
        }
        toast({
          icon: '⚠️',
          title: '일시적으로 오류가 발생하였습니다.\n오류가 반복되면 1:1 문의를 남겨주세요.',
          variant: 'dark',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (isActive) {
      const newMessage: Message = { id: Date.now(), text: input, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput('');
      getProductName();
    }
  };

  const adjustHeight = (element: HTMLTextAreaElement) => {
    element.style.height = '20px'; // 초기값
    const newHeight = Math.min(element.scrollHeight, 90); // 최대값
    element.style.height = `${newHeight}px`;
  };

  // 메시지 추가 시
  useEffect(() => {
    // 로컬 스토리지에 저장
    setStoredMessages(messages);

    // 스크롤을 최하단으로 이동
    const isUserMessage = messages[messages.length - 1]?.sender === 'user';
    const scrollBehavior = isUserMessage ? 'instant' : 'smooth';
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: scrollBehavior });
    }
  }, [messages]);

  useEffect(() => {
    if (isLoading && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading]);

  // textarea 높이 변경
  useEffect(() => {
    inputRef.current && adjustHeight(inputRef.current);
  }, [input]);

  return (
    <div className="flex h-screen flex-col bg-white pb-[10px] pt-[16px]">
      <div className="mb-[10px] flex-1 overflow-auto border-b-[1px] border-gray-150 pl-[16px] pr-[12px]">
        {messages.map((message) => (
          <BubbleContainer
            key={message.id}
            data={message}
            setMessages={setMessages}
            setIsLoading={setIsLoading}
            productNameList={productNameListData}
            isLatest={message.sender === 'bot' && message.id === latestBotMessageId}
          />
        ))}
        <div ref={messagesEndRef} />
        {isLoading && <BotBubble type="loading" />}
      </div>

      <div className="flex pl-[16px] pr-[12px]">
        <Input
          textarea={true}
          ref={inputRef}
          className="focous:border-none overflow-y-auto rounded-[8px] border-none bg-gray-100 py-[10px] pr-[70px] Body6M14 scrollbar-hide"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            inputRef.current && adjustHeight(inputRef.current);
          }}
          placeholder="최적화할 상품명을 입력해 주세요"
          InputProps={{
            endAdornment: (
              <button
                onClick={handleSendMessage}
                className={cn(
                  'rounded-[8px] bg-primary-500 px-[8px] py-[5px] text-white Caption1M12',
                  !isActive && 'bg-gray-300',
                )}
              >
                만들기
              </button>
            ),
            endAdornmentStyle: 'right-[8px] absolute m-auto whitespace-nowrap bottom-[7px]',
          }}
          maxLength={100}
        />
      </div>
    </div>
  );
};

export default ChangeNamePage;
