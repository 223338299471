import { forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/utils/tailwind';

export const inputVariants = cva(
  'flex w-full border-gray-300 Body1S16 text-gray-900 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]',
  {
    variants: {
      variant: {
        default:
          'rounded-md bg-background px-3 py-[12px] border border-gray-300 focus:border focus:border-[1px] focus:border-gray-500',
        outline:
          'py-[12px] border-b border-b-[1px] bg-background disabled:bg-gray-300 focus:border-b-gray-500 focus:border-b-[1px] disabled:text-gray-600 disabled:bg-white flex-shrink-0',
        filled: 'bg-gray-100 border-0 rounded-[10px] Body2M16 p-[16px] h-auto',
      },
    },
  },
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> &
  VariantProps<typeof inputVariants> & {
    textarea?: boolean;
    InputProps?: {
      /** 인풋 내부 우측에 들어갈 컨텐츠 */
      endAdornment?: React.ReactNode;
      endAdornmentStyle?: string;
    };
  };

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  ({ className, textarea = false, variant = 'default', type, InputProps, ...props }, ref) => {
    return (
      <div className="relative w-full">
        {textarea ? (
          <textarea
            className={cn(inputVariants({ variant, className }))}
            ref={ref as React.Ref<HTMLTextAreaElement>}
            {...props}
          />
        ) : (
          <input
            type={type}
            className={cn(inputVariants({ variant, className }))}
            ref={ref as React.Ref<HTMLInputElement>}
            {...props}
          />
        )}
        {InputProps?.endAdornment && (
          <div
            className={cn(
              InputProps?.endAdornmentStyle ||
                'absolute right-0 top-[50%] m-auto translate-y-[-50%] whitespace-nowrap',
            )}
          >
            {InputProps.endAdornment}
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
