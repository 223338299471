import { useEffect, useState } from 'react';

import Skeleton from '@/components/atoms/Skeleton';
import { Card, CardContent } from '@/components/atoms/Card';
import { cn } from '@/utils/tailwind';

type ImgCardProps = {
  src: string;
  className?: string;
};

const ImgCard = ({ src, className }: ImgCardProps) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setLoaded(true);
    };

    if (img.complete) {
      setLoaded(true);
    }
  }, [src]);

  return loaded ? (
    <Card>
      <CardContent className={cn('p-[30px]', className)}>
        <img src={src} alt="이미지" />
      </CardContent>
    </Card>
  ) : (
    <Skeleton className="w-full rounded-[20px] bg-gray-200" />
  );
};

export default ImgCard;
