import React, { useState, useRef, useCallback, useEffect } from 'react';

const isDragEvt = (value: any): value is React.DragEvent => {
  return !!value.dataTransfer;
};

const isInput = (value: EventTarget | null): value is HTMLInputElement => {
  return value !== null;
};

const getFilesFromEvent = (e: React.DragEvent | React.ChangeEvent): File[] => {
  if (isDragEvt(e)) {
    return Array.from(e.dataTransfer.files);
  } else if (isInput(e.target) && e.target.files) {
    return Array.from(e.target.files);
  }

  return [];
};

// 파일의 Content-Type
type FileType =
  | 'image/png'
  | 'image/jpeg'
  | 'image/jpg'
  | 'image/gif'
  | 'video/mp4'
  | 'video/quicktime'
  | 'application/pdf';

type DropzoneProps = {
  value?: File[];
  name?: string;
  acceptedFileTypes?: FileType[];
  width?: number | string;
  height?: number | string;
  hasError?: boolean;
  onDrop?: (files: File[]) => void;
  onChange?: (files: File[]) => void;
};

const Dropzone = (props: DropzoneProps) => {
  const {
    onDrop,
    onChange,
    value = [],
    name,
    acceptedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    hasError,
    width = '100%',
    height = '200px',
  } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);

    const files = value.concat(
      getFilesFromEvent(e).filter((f) => acceptedFileTypes.includes(f.type as FileType)),
    );

    onDrop && onDrop(files);
    onChange && onChange(files);
  };

  // 드래그 상태의 마우스 포인터가 범위 안에 드롭되었을 때
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFocused(false);

    const files = value.concat(
      getFilesFromEvent(e).filter((f) => acceptedFileTypes.includes(f.type as FileType)),
    );

    if (files.length == 0) {
      return window.alert(`다음 파일 형식을 지정할 수 없습니다. ${acceptedFileTypes.join(' ,')})`);
    }

    onDrop && onDrop(files);
    onChange && onChange(files);
  };

  // 드래그 상태의 마우스 포인터가 범위 안에 있을 때
  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // 드래그 상태의 마우스 포인터가 범위 밖으로 사라졌을 때 포커스를 없앤다
  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFocused(false);
  }, []);

  // 드래그 상태의 마우스 포인터가 범위 안에 들어왔을 때 포커스를 할당한다
  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFocused(true);
  }, []);

  // 파일 선택 대화 상자를 표시한다
  const handleClick = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (inputRef.current && value && value.length == 0) {
      inputRef.current.value = '';
    }
  }, [value]);

  const rootClasses = [
    'border rounded-[4px] bg-white cursor-pointer border-gray-300',
    isFocused ? 'border-black' : 'border-gray-300',
    hasError ? 'border-red-600' : '',
  ].join(' ');

  const contentClasses = 'flex flex-col items-center justify-center';

  return (
    <div
      className={rootClasses}
      style={{ width, height }}
      ref={rootRef}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnter={handleDragEnter}
      onClick={handleClick}
      data-testid="dropzone"
    >
      <input
        className="hidden"
        ref={inputRef}
        type="file"
        name={name}
        accept={acceptedFileTypes.join(',')}
        onChange={handleChange}
        multiple
      />
      <div className={contentClasses} style={{ width, height }}>
        <span className="text-center">
          파일을 드래그하거나 <span className="underline">폴더에서 선택</span> 하세요
        </span>
      </div>
    </div>
  );
};

Dropzone.defaultProps = {
  acceptedFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
  hasError: false,
};

export default Dropzone;
