export type DiagnosisDataType = {
  GoodsUrl: string;
  StoreName: string;
  Price: number;
  GoodsNo: string;
  ImageUrl: string;
  ReviewAmount: number;
  AverageReviewScore: number;
  SalesCountFor6Month: number;
  IsPredictionFor6Months: boolean;
  GoodsNameInfo: {
    GoodsName: string;
    GoodsNameLength: number;
    KeywordCount: number;
    IntersectionKeywordCount: number;
    IntersectionKeywordList: Array<string>;
  };
  CategoryInfo: {
    CategoryName: string;
    BestCategoryName: string;
    CategoryMatchingRate: number;
  };
  ReviewInfo: {
    NotAnsweredReviewCount: number;
    TotalReviewAnalysis: string;
    PositiveReviewAnalysisList: Array<string>;
    NegativeReviewAnalysisList: Array<string>;
    MentionKeywordList: Array<string>;
  };
  QnaAnswerRate: number;
  QnaTotalCount: number;
  NotRegisteredDetailCount: number;
  ShippingFee: number;
  KeepCount: number;
  RegisterDate: string;
  AverageDeliveryLeadTime: number;
};

export const DIAGNOSIS_INITIAL_DATA: DiagnosisDataType = {
  GoodsUrl: 'https://smartstore.naver.com/wello2/products/5641440620',
  StoreName: '송월타월 서울상사',
  Price: 2300,
  GoodsNo: '5405844844',
  ImageUrl:
    'https://shop-phinf.pstatic.net/20230609_113/1686277112267rEJvr_JPEG/162391061283231_1923180474.jpg',
  ReviewAmount: 47082,
  AverageReviewScore: 4.84,
  SalesCountFor6Month: 288,
  IsPredictionFor6Months: false,
  GoodsNameInfo: {
    GoodsName: '수건 송월타올 호텔수건 화이트코튼',
    GoodsNameLength: 18,
    KeywordCount: 5,
    IntersectionKeywordCount: 1,
    IntersectionKeywordList: ['수건'],
  },
  CategoryInfo: {
    CategoryName: '생활/건강>욕실용품>수건/타월>세면타월',
    BestCategoryName: '생활/건강>욕실용품>수건/타월>세면타월',
    CategoryMatchingRate: 100,
  },
  ReviewInfo: {
    NotAnsweredReviewCount: 5,
    TotalReviewAnalysis:
      "'수건 송월타올 호텔수건 화이트코튼'은 부드러움과 가벼움, 적당한 두께로 많은 사랑을 받고 있으나, 색상 불일치나 흡수력에 대한 아쉬움이 있습니다. 고객의 기대를 충족하기 위한 품질 및 색상 안내가 필요합니다.",
    PositiveReviewAnalysisList: [
      '부드러운 질감(질감): 부드럽고 감촉이 넘 좋아요 사용하기 편해요',
      '가벼운 무게(무게): 마감이 깔끔하게 무게가 가벼워서 데일리로 쓰기 좋습니다',
      '적당한 두께(두께): 딱 원하는 두께여서 좋아요 빨아도 금방 말라요!!!',
    ],
    NegativeReviewAnalysisList: [
      '흡수력 아쉬움(흡수력): 배송 빠르게 왔어요 두께감이 떨어지네요 흡수력이',
      '색상 불일치(색상): 아이보리가 아니라 화이트네요. 다음엔 문의드리고 주문할께요',
      '두께 불만족(두께): 부드럽고 두께도 좋네요',
    ],
    MentionKeywordList: [
      '부드러움 (긍정)',
      '가벼움 (긍정)',
      '두께 (긍정)',
      '흡수력 (부정)',
      '색상 (부정)',
    ],
  },
  QnaAnswerRate: 96.67,
  QnaTotalCount: 0,
  NotRegisteredDetailCount: 1,
  ShippingFee: 3000,
  KeepCount: 1485,
  RegisterDate: '2021-06-29T13:12:50',
  AverageDeliveryLeadTime: 1.0,
};
