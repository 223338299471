import { initUser } from '@/redux/userSlice';
import { useDispatch } from 'react-redux';
import { useSessionStorage } from 'usehooks-ts';

const SignOutContainer = () => {
  const [_, setCplatToken] = useSessionStorage('cplatToken', '');
  const dispatch = useDispatch();

  const handleSignOut = () => {
    setCplatToken('');
    dispatch(initUser());
  };

  return (
    <p className="cursor-pointer" onClick={handleSignOut}>
      로그아웃
    </p>
  );
};

export default SignOutContainer;
