import street11Guide1 from '@/assets/images/11stGuide1.png';
import street11Guide2 from '@/assets/images/11stGuide2.png';
import street11Guide3 from '@/assets/images/11stGuide3.png';
import Button from '@/components/atoms/Button';
import { Card } from '@/components/atoms/Card';
import useCopy from '@/hooks/useCopy';

const Street11GuideImgCard = () => {
  const { copyToClipboard } = useCopy({
    toastMessage: 'IP가 복사됐어요',
  });
  return (
    <Card>
      <img src={street11Guide1} alt="이미지" />
      <div className="flex items-center justify-between pr-[60px]">
        <img src={street11Guide2} alt="이미지" width={500} />
        <Button
          className="flex h-[42px] items-center bg-primary-100 text-primary hover:bg-primary-200 active:bg-primary-100"
          onClick={() => {
            const id = '3.34.167.151;43.200.9.207;13.124.61.168;220.117.192.97';
            copyToClipboard(id);
          }}
        >
          IP 복사하기
        </Button>
      </div>
      <img src={street11Guide3} alt="이미지" />
    </Card>
  );
};

export default Street11GuideImgCard;
