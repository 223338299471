import HeaderLayout from '@/components/layouts/HeaderLayout';
import { ROUTES } from '@/routes';
import { useNavigate } from 'react-router-dom';
import FunctionGuide from '@/assets/images/FunctionGuide.png';

const FunctionGuidePage = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(ROUTES.market);

  return (
    <HeaderLayout onBack={handleBack} title="지원 기능 안내" isBottomPadding>
      <div className="mb-[30px] mt-[15px] flex items-center space-x-[16px]">
        <p className="text-gray-800 Body1S16">마켓별로 지원하는 기능이에요</p>
        <p className="text-gray-600 Body6M14">
          연동 마켓과 지원하는 기능은 지속적으로 확대하고 있어요
        </p>
      </div>
      <img src={FunctionGuide} alt="image" />
    </HeaderLayout>
  );
};

export default FunctionGuidePage;
