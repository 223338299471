import Button from '@/components/atoms/Button';
import BulletList from '@/components/molecules/BulletList';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import { useModal } from '@/hooks/useModal';
import { useAppSelector } from '@/redux/store';

/**
 * alert, confirm용 모달
 * useModal 훅을 사용하여 조작
 */
const Modal = () => {
  const { isOpenModal, buttonType, description, onConfirm, title, confirmText, textList } =
    useAppSelector((state) => state.modal);
  const { closeModal } = useModal();
  const { isLoading } = useAppSelector((state) => state.loading);

  return (
    <Dialog open={isOpenModal} onOpenChange={() => buttonType === 'alert' && closeModal()}>
      <DialogContent className="max-w-[340px] gap-[30px] p-[20px] pt-[30px]" isLoading={isLoading}>
        <div>
          {title && <div className="mb-[16px] text-gray-900 Title3S18">{title}</div>}
          {description && (
            <div className="text-gray-750 Body6M14">
              {description.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          )}
          {textList && textList.length > 0 && (
            <BulletList data={textList} className="text-gray-750 Body2M16" />
          )}
        </div>

        {buttonType === 'confirm' && (
          <div className="flex justify-center space-x-[10px]">
            <Button
              type="button"
              variant={'secondary'}
              className="w-[145px]"
              onClick={() => closeModal()}
            >
              닫기
            </Button>
            <Button
              type="button"
              variant={'default'}
              className="w-[145px]"
              onClick={() => (onConfirm ? onConfirm() : closeModal())}
            >
              {confirmText}
            </Button>
          </div>
        )}

        {buttonType === 'alert' && (
          <Button
            type="button"
            variant={'default'}
            onClick={() => (onConfirm ? onConfirm() : closeModal())}
          >
            {confirmText}
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
