const Footer = () => {
  return (
    <footer className="flex h-[140px] items-center justify-center border-t border-t-[#DADADA] bg-white text-gray-900">
      <div className="mt-[20px] flex w-[1200px] justify-between text-[20px] c-c1">
        <div>
          <p>
            서클플랫폼(주) 대표 : 백민기 | 사업자 등록번호: 557-87-02223 (통신판매
            제2022-서울강남-01773호)
          </p>
          <p className="mt-[6px]">
            contact : support@cplat.io (02-558-2166) │ 주소 : 서울 강남구 테헤란로5길7, KG Tower 9층
          </p>
          <p className="mt-[6px]">ⓒ circleplatform. All rights reserved.</p>
        </div>
        <div>
          <ol className="flex space-x-[36px]">
            <li>이용약관</li>
            <li>개인정보처리방침</li>
            <li>이벤트/프로모션 정보 선택</li>
            <li>사업자정보확인</li>
          </ol>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
