import { forwardRef } from 'react';

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'sm' | 'lg'; // TODO 사이즈
  color?: string;
  children: React.ReactNode;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, size, color, children, ...props }, ref) => {
    // const rootClasses = [size ? 'w-[18px]' : 'w-[24px]'];

    return (
      <div>
        <button ref={ref} {...props}>
          {children}
        </button>
      </div>
    );
  },
);

export default IconButton;
