import { type AxiosResponse } from 'axios';
import moment from 'moment';

import { CPLAT_API_ROUTES, useFetch } from '@/api';
import Skeleton from '@/components/atoms/Skeleton';
import HeaderLayout from '@/components/layouts/HeaderLayout';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/molecules/Accordion';
import { useAppSelector } from '@/redux/store';
import { useNavigate } from 'react-router-dom';

const StatusBox = ({ status }: { status: string }) => {
  switch (status) {
    case 'pending':
      return (
        <div className="inline-flex items-center rounded-[4px] bg-red-100 px-[6px] py-[4px] text-red-400 c-c2">
          답변 대기
        </div>
      );
    case 'success':
      return (
        <div className="inline-flex items-center rounded-[4px] bg-blue-100 px-[6px] py-[4px] text-blue-400 c-c2">
          답변 완료
        </div>
      );
  }
};

type InquiryHistoryProps = {
  askAnswerList: { Item1: Ask; Item2?: Answer | null }[];
};

const InquiryHistory = ({ askAnswerList }: InquiryHistoryProps) => {
  return askAnswerList.map(({ Item1: ask, Item2: answer }) => {
    const isAnswer = !!answer;

    return (
      <AccordionItem key={ask.AskNo} value={`${ask.AskNo}`} className="border-none">
        <AccordionTrigger className="hover:no-underline">
          <div className="flex justify-normal space-x-[16px]">
            <StatusBox status={isAnswer ? 'success' : 'pending'} />
            <p className="text-gray-500 c-t2">Q</p>
            <p className="text-[20px] font-semibold">{ask.AskTitle}</p>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <ol className=" bg-gray-50 p-[16px]">
            <li className="pb-[12px]">
              <p className="text-gray-600 c-b4">{moment.utc(ask.AskDate).format('YY.MM.DD')}</p>
              <p className="mt-[4px] text-gray-750 c-b2">{ask.AskContext}</p>
            </li>
            {isAnswer && (
              <li className="border-t-[1px]">
                <p className="mt-[12px] text-gray-600 c-b4">
                  {moment.utc(answer.AnswerDate).format('YY.MM.DD')}
                </p>
                <p className="mt-[4px] text-gray-750 c-b2">{answer.AnswerContext}</p>
              </li>
            )}
          </ol>
        </AccordionContent>
      </AccordionItem>
    );
  });
};

type InquiryHistorys = {
  list: GetAsk[];
};

const InquiryHistorys = ({ list }: InquiryHistorys) => {
  if (list.length === 0)
    return (
      <div className="flex flex-col items-center justify-center text-gray-800 c-b5">
        <p>문의 내역이 없습니다.</p>
      </div>
    );

  const askAnswerList = list[0]?.AskAnswerList;

  return (
    <Accordion className="space-y-[24px]" collapsible type="single">
      <InquiryHistory askAnswerList={askAnswerList} />
    </Accordion>
  );
};

const InquiryHistoryPage = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const { data, isLoading } = useFetch<AxiosResponse<GetAsk[], unknown>>(
    CPLAT_API_ROUTES.getInquiry,
    {
      method: 'POST',
      params: {
        cplatToken: user.CplatToken,
        orderByMethod: 0,
        skip: 0,
        take: 50,
        serviceType: '셀러박스',
      },
    },
  );

  return (
    <HeaderLayout title="문의 내역 보기" isBottomPadding onBack={() => navigate(-1)}>
      <div className="my-[47px] min-h-[60vh]">
        {!data?.data || isLoading ? (
          <Skeleton className="h-[60vh] w-full" />
        ) : (
          <InquiryHistorys list={data.data} />
        )}
      </div>
    </HeaderLayout>
  );
};

export default InquiryHistoryPage;
