import { Outlet } from 'react-router-dom';
import Gnb from '@/components/organisms/Gnb';
import Footer from '@/components/organisms/Footer';
import { useResponsive } from '@/hooks/useResponsive';
import { cn } from '@/utils/tailwind';

type RootlayoutProps = {
  isViewFooter?: boolean;
  bgColor?: string;
};

const Rootlayout = ({ bgColor, isViewFooter }: RootlayoutProps) => {
  bgColor = bgColor ? bgColor : 'bg-gray-50';
  const { isPCSize } = useResponsive();

  return (
    <div className={`min-h-[100vh] ${bgColor}`}>
      {isPCSize && <Gnb />}
      <div className={cn(isPCSize ? 'w-max-[1920px] container w-[1920px]' : '')}>
        <Outlet />
      </div>
      {isViewFooter && <Footer />}
    </div>
  );
};

export default Rootlayout;
