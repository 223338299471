import * as Sentry from '@sentry/react';

const DNS =
  import.meta.env.MODE === 'development'
    ? 'https://95613402761aa9ab74079cd7775ddc4a@o4506266620461056.ingest.sentry.io/4506266651262976'
    : 'https://cdc0717ab3549edadfc81d6102b9dea8@o4506266620461056.ingest.sentry.io/4506266630356992';

// 평상시에는 dev 추적 x (추후 모니터링이 필요할 때 분기문 제거후 사용)
const isUseSentry = import.meta.env.MODE === 'production'; // || import.meta.env.MODE === 'development'

if (isUseSentry) {
  Sentry.init({
    dsn: DNS,
    release: APP_VERSION + '-' + import.meta.env.MODE,
    // 에러 발생시 어떤 환경인지 체크
    environment: import.meta.env.MODE,
    // 모니터링 환경 dev에서 test 시 주석 처리
    // context 데이터 깊이
    normalizeDepth: 6,
    integrations: [
      // 웹 트레이싱(성능 모니터링)
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost', // 페이지 로드, 사용자 인터랙션 트레이싱
          'app.sellerbox.io', // 페이지 로드, 사용자 인터랙션 트레이싱
          // api 트레이싱
          /^https:\/\/api\.cplat\.io\//,
          /^https:\/\/devapi\.cplat\.io\//,
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
