import Button from '@/components/atoms/Button';
import BotBubble from './BotBubble';
import useCopy from '@/hooks/useCopy';
import { Message, ProductName } from '../ChangeNamePage';

interface BubbleContainerProps {
  data: Message;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  productNameList: Array<ProductName>;
  isLatest: boolean;
}

const BubbleContainer = ({
  data,
  setMessages,
  setIsLoading,
  productNameList,
  isLatest,
}: BubbleContainerProps) => {
  const { copyToClipboard } = useCopy({
    toastMessage: '상품명이 복사됐어요',
  });
  const remainCount = data.remainCount || 0;
  const activeIndex = 5 - remainCount;

  const handleMakeAgain = () => {
    setIsLoading(true);

    setTimeout(() => {
      const botMessage: Message = {
        id: Date.now() + 1,
        text: productNameList[activeIndex].product_name,
        sender: 'bot',
        subText: productNameList[activeIndex].Consideration,
        list: productNameList[activeIndex].Keywords,
        remainCount: remainCount - 1,
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div
      key={data.id}
      className={`flex ${
        data.sender === 'user' ? 'mb-[20px] justify-end' : 'mb-[20px] justify-start'
      }`}
    >
      {data.sender === 'bot' ? (
        <div className="flex w-full flex-col gap-[8px]">
          <BotBubble type="headerBubble" text={data.text} />
          {data.subText && data.list && (
            <BotBubble type="bulletBubble" text={data.subText} list={data.list} />
          )}
          <div className="flex items-center gap-[8px]">
            {data.id !== 0 && (
              <Button
                variant={'outline'}
                className="px-[18px] text-gray-800"
                onClick={() => copyToClipboard(data.text)}
              >
                상품명 복사
              </Button>
            )}

            {productNameList.length > 0 && remainCount > 0 && isLatest && (
              <Button
                variant={'outline'}
                className="px-[18px] text-gray-800"
                onClick={handleMakeAgain}
              >
                다시 만들기 <span className="ml-1 text-primary-500">{activeIndex}</span>/5
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="max-w-[90%] whitespace-pre-wrap break-words rounded-[12px] bg-primary-400 p-[12px] text-gray-900 Body6M14">
          {data.text}
        </div>
      )}
    </div>
  );
};

export default BubbleContainer;
