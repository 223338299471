import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';
import firebaseConfig from '../../../firebase.json';

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logEvent = (event: string, params?: { [key: string]: any }) => {
  firebaseLogEvent(analytics, event, params);
};
