const usePostMessage = () => {
  const navigateApp = (screen: string, params?: any) => {
    const data = JSON.stringify({
      navigateParams: {
        key: screen,
        name: screen,
        params,
      },
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };
  const openExternalUrl = (url: string) => {
    const data = JSON.stringify({
      openExternalUrl: url,
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };
  const goBackApp = (text: string) => {
    const data = JSON.stringify({
      goBackApp: text,
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };
  const setRecoilData = (key: string, value: string) => {
    const data = JSON.stringify({
      setRecoilData: {
        [key]: value,
      },
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };
  const openBottomSheet = (status: 'open' | 'close' | 'error') => {
    const data = JSON.stringify({
      openBottomSheet: status,
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };
  const enableSubmit = (status: 'Y' | 'N') => {
    const data = JSON.stringify({
      enableSubmit: status,
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };
  const enableEdit = (status: 'Y' | 'N') => {
    const data = JSON.stringify({
      enableEdit: status,
    });
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
  };
  return {
    navigateApp,
    openExternalUrl,
    goBackApp,
    setRecoilData,
    openBottomSheet,
    enableSubmit,
    enableEdit,
  };
};

export default usePostMessage;
