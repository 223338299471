import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '@/redux/store';
import { ROUTES } from '.';
import { useLayoutEffect } from 'react';

const ProtectedRoute = () => {
  const { CplatToken } = useAppSelector((state) => state.user);
  const { verificationToken } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  const isSignUpPath = location.pathname === ROUTES.signup;
  const isLogin = CplatToken && CplatToken !== '';
  const isWrongSignUpPath = isSignUpPath && (!verificationToken || verificationToken === '');

  useLayoutEffect(() => {
    if (!isLogin || isWrongSignUpPath) {
      navigate(ROUTES.signin);
    }
  });

  return <Outlet />;
};

export default ProtectedRoute;
