import { Link } from 'react-router-dom';

import Button, { type ButtonProps } from '@/components/atoms/Button';

import IconLinkOrange from '@/assets/icons/LinkOrangeIcon.svg?react';

export type LinkButtonProps = {
  children: React.ReactNode;
  to: string;
  target?: React.HTMLAttributeAnchorTarget;
} & ButtonProps;

const LinkButton = ({ children, target = '_blank', to, ...props }: LinkButtonProps) => {
  return (
    <Button
      className="flex h-[42px] items-center bg-primary-100 text-primary hover:bg-primary-200 active:bg-primary-100"
      {...props}
    >
      <Link to={to} target={target} className="flex items-center space-x-[8px]">
        <div className="c-b6">{children}</div>
        <span>
          <IconLinkOrange />
        </span>
      </Link>
    </Button>
  );
};

export default LinkButton;
