import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { cplatApiV2 } from '@/api';
import { useToast } from '@/hooks/useToast';

/**
 * api 하나가 생성될 때 마다 래퍼 함수를 생성하는 방식이 아니라
 * 자주 사용하는 useQuery, mutaion, useInfiniteQuery 등을 generic 한 함수로 만들고 url 기준으로 사용할 수 있게 했습니다.
 */

type QueryKeyT = [string, object | undefined];
type GetInfinitePagesInterface<T> = {
  nextId?: number;
  previousId?: number;
  data: T;
  count: number;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

/**
 * 데이터 패칭을 위한 함수지만 api 특성상 모든 메서드가 post 이기 때문에 method 선택 옵션을 부여
 */
export const fetcher = async <T>({
  queryKey,
  pageParam,
  method = 'GET',
}: {
  queryKey: QueryKeyT;
  pageParam?: number;
  method?: 'GET' | 'POST';
}): Promise<T> => {
  const [url, params] = queryKey;
  const res =
    method === 'GET'
      ? await cplatApiV2.get<T>(url, {
          params: { ...params, pageParam },
        })
      : await cplatApiV2.post<T>(url, { ...params, pageParam });

  return res.data;
};

/**
 * useQuery generic 함수
 */
export const useFetch = <T>(
  url: string,
  option?: {
    method?: 'GET' | 'POST';
    params?: object;
    config?: UseQueryOptions<T, Error, T, QueryKeyT>;
  },
) => {
  const params = option?.params;
  const method = option?.method ? option.method : 'GET';
  const config = option?.config;

  const context = useQuery<T, Error, T, QueryKeyT>({
    queryKey: [url, params],
    enabled: !!url,
    queryFn({ queryKey }) {
      return fetcher({ queryKey, method });
    },
    ...config,
  });

  return context;
};

// TODO type 좀 더 유연하게 가져오기
export const useFetchs = <T>(
  queriesOptions: {
    url: string;
    option?: {
      method?: 'GET' | 'POST';
      params?: object;
      config?: UseQueryOptions<T, Error, T, QueryKeyT>;
    };
  }[],
) => {
  const queries: unknown[] = queriesOptions.map(({ url, option }) => {
    const params = option?.params;
    const method = option?.method ? option.method : 'GET';
    const config = option?.config;

    return {
      queryKey: [url, params],
      queryFn({ queryKey }: { queryKey: QueryKeyT }) {
        return fetcher({ queryKey, method });
      },
      ...config,
    };
  });

  const context = useQueries({ queries });

  return context;
};

export const useSuspenseFetch = <T>(
  url: string,
  option?: {
    method?: 'GET' | 'POST';
    params?: object;
    config?: UseQueryOptions<T, Error, T, QueryKeyT>;
  },
) => {
  const params = option?.params;
  const method = option?.method ? option.method : 'GET';
  const config = option?.config;

  const context = useSuspenseQuery<T, Error, T, QueryKeyT>({
    queryKey: [url, params],
    enabled: !!url,
    queryFn({ queryKey }) {
      return fetcher({ queryKey, method });
    },
    ...config,
  });

  return context;
};

export const useSuspenseFetchs = <T>(
  queriesOptions: {
    url: string;
    option?: {
      method?: 'GET' | 'POST';
      params?: object;
      config?: UseQueryOptions<T, Error, T, QueryKeyT>;
    };
  }[],
) => {
  const queries: unknown[] = queriesOptions.map(({ url, option }) => {
    const params = option?.params;
    const method = option?.method ? option.method : 'GET';
    const config = option?.config;

    return {
      queryKey: [url, params],
      queryFn({ queryKey }: { queryKey: QueryKeyT }) {
        return fetcher({ queryKey, method });
      },
      ...config,
    };
  });

  const context = useSuspenseQueries({ queries });

  return context;
};

/**
 * useInfiniteQuery generic 함수
 */
export const useLoadMore = <T>(url: string, params?: object) => {
  const context = useInfiniteQuery<
    GetInfinitePagesInterface<T>,
    Error,
    GetInfinitePagesInterface<T>,
    QueryKeyT
  >({
    queryKey: [url, params],
    initialPageParam: 1, // TODO 고민해보기
    queryFn({ queryKey, pageParam = 1 }) {
      return fetcher({ queryKey, pageParam: typeof pageParam === 'number' ? pageParam : 1 });
    },
    getNextPageParam(firstPage) {
      return firstPage.previousId ?? false;
    },
    getPreviousPageParam(lastPage) {
      return lastPage.nextId ?? false;
    },
  });

  return context;
};

/**
 *
 * prefetchQuery generic 함수
 */
export const usePrefetch = <T>(url: string | null, params?: object) => {
  const queryClient = useQueryClient();

  return () => {
    if (!url) {
      return;
    }

    queryClient.prefetchQuery<T, Error, T, QueryKeyT>({
      queryKey: [url, params],
      queryFn({ queryKey }) {
        return fetcher({ queryKey });
      },
    });
  };
};

/**
 * mutation의 generic 함수 해당 함수는 method 규격에 맞게 useDelete, usePost, usePost 으로 나뉨
 */
const useGenericMutation = <T, S>(
  mutationFn: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined,
) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<AxiosResponse, AxiosError, T | S>({
    mutationFn,
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: [url, params] });

      const previousData = queryClient.getQueryData([url, params]);

      queryClient.setQueryData<T>([url, params], (oldData) => {
        return updater ? updater(oldData!, data as S) : (data as T);
      });

      return previousData;
    },
    onError: (_err: AxiosError, _: any, context: unknown) => {
      toast({
        title: '네트워크 통신 에러가 발생 했습니다.',
        description: `
        code : ${_err?.code} 
        message : ${_err.message}`,
        variant: 'error',
      });
      queryClient.setQueryData([url, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [url, params] });
    },
  });
};

/**
 * delete method query
 * useGenericMutation의 wrapper 함수
 */
export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T,
) => {
  return useGenericMutation<T, string | number>(
    (id) => cplatApiV2.delete(`${url}/${id}`),
    url,
    params,
    updater,
  );
};

/**
 * post method query
 * useGenericMutation의 wrapper 함수
 */
export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>((data) => cplatApiV2.post<S>(url, data), url, params, updater);
};

/**
 * patch method query
 * useGenericMutation의 wrapper 함수
 */
export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>((data) => cplatApiV2.patch<S>(url, data), url, params, updater);
};
