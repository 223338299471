import { AxiosResponse } from 'axios';
import { Suspense, useEffect, useState } from 'react';

import { CPLAT_API_ROUTES, useSuspenseFetchs } from '@/api';
import MarketInfoContainer from '@/containers/market/MarketContainer';
import { useAppSelector } from '@/redux/store';
import GlobalSpinner from '@/components/organisms/GlobalSpinner';
import { Dialog } from '@radix-ui/react-dialog';
import { DialogContent } from '@/components/molecules/Dialog';
import Button from '@/components/atoms/Button';
import InstallModal from '@/assets/images/InstallModal.png';
import OnBoardingModal from '@/assets/images/OnBoardingModal.png';
import { useSearchParams } from 'react-router-dom';

const MarketPage = () => {
  const user = useAppSelector((state) => state.user);
  const [isOnBoradingModalOpen, setIsOnBoradingModalOpen] = useState<boolean>(false);
  const [isInstallModalOpen, setIsInstallModalOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const origin = searchParams.get('origin');

  const [{ data: marketInfoRes }, { data: registeredMarketRes }] = useSuspenseFetchs([
    { url: CPLAT_API_ROUTES.getMarketModel, option: { method: 'POST' } },
    {
      url: CPLAT_API_ROUTES.getRegisteredMarketInfo,
      option: {
        method: 'POST',
        params: {
          cplatToken: user.CplatToken,
        },
      },
    },
  ]);

  const { data: marketInfoList } = marketInfoRes as AxiosResponse<MarketInfo[]>;
  const { data: registedMarketList } = registeredMarketRes as AxiosResponse<
    {
      RegisteredMarketList: RegisteredMarketInfo[];
    }[]
  >;

  const isEmptyRegistedMarket = registedMarketList.length <= 0;

  useEffect(() => {
    if (origin && origin === 'signup') {
      setIsOnBoradingModalOpen(true);
    }
  }, [origin]);

  return (
    <section className="mt-[72px]">
      <Suspense fallback={<GlobalSpinner />}>
        <h1 className="mb-[30px] font-Pretendard text-gray-800 c-t2">
          {user.StoreName}님 환영합니다!
          <br />
          PC에서 편하게 마켓 연동을 진행해 보세요
        </h1>
        <MarketInfoContainer
          registedMarketList={
            isEmptyRegistedMarket ? [] : registedMarketList[0].RegisteredMarketList
          }
          marketInfoList={marketInfoList}
          isEmptyRegistedMarket={isEmptyRegistedMarket}
        />

        {/* 온보딩 모달 */}
        <Dialog
          open={isOnBoradingModalOpen}
          onOpenChange={() => {
            setIsOnBoradingModalOpen(false);
          }}
        >
          <DialogContent className="flex flex-col items-center gap-0 p-[30px] pb-[20px] text-center">
            <div className="mb-[40px] flex flex-col gap-[16px] text-start">
              <h1 className="text-gray-900 Title3S18">
                {`안녕하세요 ${user.StoreName}님, 셀러박스 회원가입이 완료되었어요 🎉`}
                <br />
                셀러박스 PC에서 제공하는 기능들을 확인해 보세요!
              </h1>
              <div>
                <div className="ml-[4px] flex items-center gap-[4px]">
                  <h2 className="text-gray-700 Body1S16">1.</h2>
                  <h2 className="text-gray-700 Body1S16">마켓 연동</h2>
                </div>
                <p className="text-gray-750 Body6M14">
                  큰 화면에서 편리하게 운영중인 마켓을 모두 연동할 수 있어요
                </p>
              </div>
              <div>
                <div className="ml-[4px] flex items-center gap-[4px]">
                  <h2 className="text-gray-700 Body1S16">2.</h2>
                  <h2 className="text-gray-700 Body1S16">상품 발송</h2>
                </div>
                <p className="text-gray-750 Body6M14">
                  연동한 마켓에 발생한 신규 주문건의 상세 내역을 확인하고 발송 처리할 수 있어요
                </p>
              </div>
              <img src={OnBoardingModal} loading="lazy" />
            </div>
            <Button
              variant={'secondary'}
              onClick={() => {
                setIsInstallModalOpen(true);
              }}
              className="h-[44px] w-[200px] rounded-sm Body3S15"
            >
              다음
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={isInstallModalOpen}
          onOpenChange={() => {
            setIsInstallModalOpen(false);
          }}
        >
          <DialogContent className="items-left gap-0 p-[30px] pb-[20px] text-start">
            <h1 className="text-gray-900 Title3S18">
              셀러박스 앱을 설치하고 더 많은 기능을 사용해 보세요!
            </h1>
            <div className="mb-[36px] mt-[22px] flex">
              <div className="flex w-[350px] flex-col gap-[16px]">
                <div>
                  <h2 className="text-gray-700 Body1S16">실시간 알림 발송</h2>
                  <p className="text-gray-600 Body5S14">
                    마켓의 신규 주문을 수집하고 실시간으로 알림을 발송해 드려요
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-700 Body1S16">리뷰 수집 및 답변 작성</h2>
                  <p className="text-gray-600 Body5S14">
                    작성된 리뷰의 내용을 AI가 분석하고 알맞은 답변을 생성해요
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[16px]">
                <div>
                  <h2 className="text-gray-700 Body1S16">상품 발송 처리</h2>

                  <p className="text-gray-600 Body5S14">
                    언제 어디서나 모바일로 간편하게 상품의 발송을 처리할 수 있어요
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-700 Body1S16">상품 모니터링</h2>

                  <p className="text-gray-600 Body5S14">
                    검색 키워드 별 상품의 노출 순위를 매일 모니터링해 드려요
                  </p>
                </div>
              </div>
            </div>
            <img src={InstallModal} loading="lazy" />
            <div className="flex gap-[10px] justify-self-center">
              <Button
                variant={'secondary'}
                onClick={() => {
                  setIsInstallModalOpen(false);
                }}
                className="mt-[40px] h-[44px] w-[145px] rounded-sm	Body3S15"
              >
                이전
              </Button>
              <Button
                variant={'default'}
                onClick={() => {
                  setIsOnBoradingModalOpen(false);
                  setIsInstallModalOpen(false);
                }}
                className="mt-[40px] h-[44px] w-[145px] rounded-sm	Body3S15"
              >
                확인
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </Suspense>
    </section>
  );
};

export default MarketPage;
