import { type CheckedState } from '@radix-ui/react-checkbox';

import Checkbox from '@/components/atoms/Checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/atoms/Tooltip';

import AlertCircle from '@/assets/icons/WarningCircleRedIcon.svg?react';

type RegisteredCardProps = {
  checked?: CheckedState;
  onCheckedChange?: (id: string, cheked: CheckedState) => void;
} & RegisteredMarketInfo;

type CommentTooltipProps = {
  storeComment: string;
  className?: string;
};

const CommentTooltip = ({ storeComment, className }: CommentTooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={300}>
        <TooltipTrigger className={className}>
          <AlertCircle />
        </TooltipTrigger>
        <TooltipContent className="bg-red-100 text-red-400 c-b6">
          <p>{storeComment}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const RegisteredCard = (props: RegisteredCardProps) => {
  const {
    MarketIconUrl,
    MarketAlias,
    onCheckedChange,
    checked,
    MarketNameEnglish,
    StoreComment,
    MarketId,
  } = props;
  return (
    <div className="flex w-[250px] items-center">
      <span
        className="flex cursor-pointer items-center space-x-[10px]"
        onClick={() => {
          onCheckedChange && onCheckedChange(MarketNameEnglish + MarketId, !checked);
        }}
      >
        <Checkbox checked={checked} className="w-[20px]" />
        <img src={MarketIconUrl} alt="마켓 아이콘" className="h-[28px] w-[28px]" />
        <span className="translate-y-[1px] text-gray-700 c-b3">{MarketAlias}</span>
      </span>
      {StoreComment !== '' && (
        <CommentTooltip storeComment={StoreComment} className="ml-[10px] cursor-help" />
      )}
    </div>
  );
};

export default RegisteredCard;
