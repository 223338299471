import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrderState {
  checkedOrderList: Array<string>;
  selectedCategory: string;
}

const initialState: OrderState = {
  checkedOrderList: [],
  selectedCategory: 'confirmOrder',
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCheckedOrderList: (state, action: PayloadAction<string[]>) => {
      state.checkedOrderList = action.payload;
    },
    resetCheckedOrderList: (state) => {
      state.checkedOrderList = initialState.checkedOrderList;
    },
    setSelectedCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategory = action.payload;
    },
  },
});

export const { setCheckedOrderList, resetCheckedOrderList, setSelectedCategory } =
  orderSlice.actions;
export default orderSlice.reducer;
