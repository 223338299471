import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type AuthState = {
  verificationToken: string;
};

const initialState: AuthState = {
  verificationToken: '',
};

export const AuthState = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setVerificationToken: (state: AuthState, action: PayloadAction<string>) => {
      return { ...state, verificationToken: action.payload };
    },
    initAuth: () => initialState,
  },
});

export const { setVerificationToken, initAuth } = AuthState.actions;
const AuthReducer = AuthState.reducer;

export default AuthReducer;
