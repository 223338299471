import { cn } from '@/utils/tailwind';
import { VariantProps, cva } from 'class-variance-authority';

const badgeSquareVariants = cva('', {
  variants: {
    variant: {
      primary: 'text-orange-500 bg-orange-100',
      blue: 'text-blue-400 bg-blue-100',
      red: 'text-red-400 bg-red-100',
      green: 'text-green-400 bg-green-100',
      gray: 'text-gray-600 bg-gray-150',
    },
    size: {
      sm: 'px-[3px] py-[1px] rounded-[2px] Caption3M10',
      md: 'px-[6px] py-[2px] rounded-[4px] Caption2B12',
    },
  },
});

const BadgeSquare = ({ className, variant, size, ...props }: BadgeSquareProps) => {
  return <div className={cn(badgeSquareVariants({ variant, size }), className)} {...props} />;
};

export type BadgeSquareProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeSquareVariants> & {
    size: 'sm' | 'md';
  };

export default BadgeSquare;
