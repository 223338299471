import BadgeSquare from '@/components/atoms/BadgeSquare';
import Button from '@/components/atoms/Button';

type MarketInfoCardProps = {
  data: MarketInfo;
  className?: string;
  onClick: (EnName: string, data: MarketInfo) => void;
};

const MarketInfoCard = (props: MarketInfoCardProps) => {
  const { data, onClick } = props;
  const { MarketIconUrl, MarketName, MarketNameEnglish, IsOrderFurtherActionEnable } = data;

  return (
    <Button
      onClick={() => onClick(MarketNameEnglish, data)}
      variant={'secondary'}
      className="relative h-[64px] w-full items-center justify-start rounded-sm bg-gray-50 py-[16px] pl-[12px]"
    >
      <img src={MarketIconUrl} alt="마켓 아이콘" className="mr-[8px] h-[32px] w-[32px]" />
      <span className="text-gray-700 Body5S14">{MarketName}</span>
      {IsOrderFurtherActionEnable && (
        <BadgeSquare variant={'blue'} size="sm" className="absolute right-[5px] top-[5px]">
          PLUS
        </BadgeSquare>
      )}
    </Button>
  );
};

export default MarketInfoCard;
