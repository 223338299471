import { useAppSelector } from '@/redux/store';

const useCheckMembership = (): boolean => {
  const ServiceInfo = useAppSelector((state: any) => state.user.ServiceInfo);

  const isMember =
    Object.keys(ServiceInfo).findIndex((value) => ServiceInfo[value].IsSubcribed) !== -1;

  return isMember;
};

export default useCheckMembership;
