import HeaderLayout from '@/components/layouts/HeaderLayout';
import { ROUTES } from '@/routes';
import { useNavigate } from 'react-router-dom';
import Membership1 from '@/assets/images/Membership1.png';
import Membership2 from '@/assets/images/Membership2.png';

const MembershipPage = () => {
  const navigate = useNavigate();
  return (
    <HeaderLayout
      title="멤버십 안내"
      onBack={() => navigate(ROUTES.productShipment)}
      className="pb-[185px]"
    >
      <div className="align-center mb-[30px] mt-[15px] flex gap-[16px]">
        <span className="text-gray-800 Body1S16">
          셀러박스 앱에서 멤버십을 구독하고 다양한 기능들을 이용해 보세요!
        </span>
        <span className="text-gray-750 Body6M14">1개월 · 15,000원 · VAT 포함</span>
      </div>
      <div className="m-auto w-fit rounded-[20px] bg-white p-[20px] pt-[26px]">
        <img src={Membership1} width={921} height={186} />
        <div className="mb-[20px] mt-[32px] text-gray-800 Title3S18">멤버십 한눈에 비교하기</div>
        <img src={Membership2} width={921} height={410} />
        <div className="mb-[8px] mt-[20px] text-gray-750 Body5S14">확인해 주세요!</div>
        <ul className="list-disc pl-[26px] text-gray-600 Body6M14">
          <li>멤버십 결제는 앱을 통해서만 가능합니다.</li>
          <li>멤버십 결제 회원의 CS를 최우선으로 응대 합니다.</li>
          <li>리뷰 기능 지원 마켓은 지속적으로 추가중에 있으며, 현재 지원하는 다음과 같습니다.</li>
          <li className="ml-[26px]">
            스마트스토어, 카페24, 아임웹, 샵바이, 에이블리, 아이디어스, 지그재그, 식스샵, 올웨이즈,
            인터파크
          </li>
          <li>
            상품 모니터링 기능 지원 마켓은 지속적으로 추가중에 있으며, 현재 지원하는 마켓은 다음과
            같습니다.
          </li>
          <li className="ml-[26px]">스마트스토어</li>
          <li>
            실험실 - 상품진단 기능 지원 마켓은 지속적으로 추가중에 있으며, 현재 지원하는 마켓은
            다음과 같습니다.
          </li>
          <li className="ml-[26px]">스마트스토어</li>
          <li>
            상품 발송 기능 지원 마켓은 지속적으로 추가중에 있으며, 현재 지원하는 마켓은 다음과
            같습니다.
          </li>
          <li className="ml-[26px]">
            스마트스토어, 쿠팡, 11번가, 지마켓, 옥션, 롯데온, 톡스토어, 카페24, 샵바이, 아임웹
          </li>
          <li>
            문의 기능 지원 마켓은 지속적으로 추가중에 있으며, 현재 지원하는 마켓은 다음과 같습니다.
          </li>
          <li className="ml-[26px]">
            스마트스토어, 쿠팡, 11번가, 지마켓, 옥션, 롯데온, 톡스토어, 카페24, 샵바이
          </li>
        </ul>
        <div className="mb-[8px] mt-[12px] text-gray-750 Body5S14">멤버십 결제 유의사항</div>
        <ul className="list-disc pl-[26px] text-gray-600 Body6M14">
          <li>
            14일 무료체험 종료 시 멤버십 자동 결제가 진행되며, 자동 결제 진행 2일 전 앱 푸시
            메시지로 알려드립니다.
          </li>
          <li>멤버십 가격은 부가세와 결제수수료가 포함되어 있습니다.</li>
          <li>등록하신 결제 수단으로 매월 정기 결제일에 멤버십 이용 금액이 자동으로 결제됩니다.</li>
          <li>멤버십은 언제든 해지할 수 있으며 해지 이후에도 결제 만료일 까지 사용 가능합니다.</li>
          <li>멤버십 사용 중에는 남은 기간에 대한 금액은 별도의 환불이 진행되지 않습니다.</li>
        </ul>
      </div>
    </HeaderLayout>
  );
};

export default MembershipPage;
