const EN_TO_KO_MARKET_NAME_MAP: Record<string, string> = {
  smartstore: '스마트스토어',
  coupang: '쿠팡',
  '11st': '11번가',
  esmplus: 'EsmPlus',
  wemakeprice: '위메프',
  gmarket: 'G마켓',
  auction: '옥션',
  lotteon: '롯데온',
  interpark: '인터파크',
  ably: '에이블리',
  cafe24: '카페24',
  tmon: '티몬',
  zigzag: '지그재그',
  melchi: '멸치쇼핑',
  idus: '아이디어스',
  talkstore: '카카오톡 스토어',
  imweb: '아임웹',
  sixshop: '식스샵',
  shopby: '샵바이',
  alwayz: '올웨이즈',
};

export default EN_TO_KO_MARKET_NAME_MAP;
