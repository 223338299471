import HeaderLayout from '@/components/layouts/HeaderLayout';
import InquiryButton from '@/components/organisms/InquiryButton';
import LinkGuide from '@/components/organisms/LinkGuide';
import PromotionSellkeyCard from '@/components/organisms/PromotionSellkeyCard';
import ReqAddMarketContainer from '@/containers/market/ReqAddMarketContainer';
import { useNavigate } from 'react-router-dom';

const ReqAddMarketPage = () => {
  const navigate = useNavigate();
  return (
    <HeaderLayout title="마켓 추가 요청" onBack={() => navigate(-1)}>
      <div className="mt-[14px] flex space-x-[30px]">
        <div className="pl-[6px]">
          <ReqAddMarketContainer />
          <InquiryButton className="mt-[24px]" />
        </div>
        <div>
          <LinkGuide
            guide={{
              type: 'linkGuide',
              contents: {
                title: '마켓 추가를 위해 계정이 필요해요',
                mainText: (
                  <p>
                    연동 가능 스토어를 지속적으로 확대 중입니다.
                    <br />
                    빠른 시일 내에 마켓을 추가할 수 있도록 셀러님의 계정을 셀러박스에게 알려주세요!
                  </p>
                ),
              },
            }}
          />
          <div className="mt-[30px]">
            <PromotionSellkeyCard />
          </div>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default ReqAddMarketPage;
