import { useNavigate } from 'react-router';

import HeaderLayout from '@/components/layouts/HeaderLayout';
import MyProfileContainer from '@/containers/MyProfileContainer';
import { ROUTES } from '@/routes';

const MyProfilePage = () => {
  const navigate = useNavigate();

  return (
    <HeaderLayout title="프로필 수정" onBack={() => navigate(ROUTES.market)}>
      <MyProfileContainer />
    </HeaderLayout>
  );
};

export default MyProfilePage;
