import { useQueryClient } from '@tanstack/react-query';
import { type SubmitHandler, useForm } from 'react-hook-form';

import Button from '@/components/atoms/Button';
import TextField from '@/components/molecules/TextField';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useAppSelector } from '@/redux/store';

import CircleCloseIcon from '@/assets/icons/CircleCloseIcon.svg?react';
import { useToast } from '@/hooks/useToast';
import { useState } from 'react';

type UpdateMarketAliasDialogContainerProps = {
  onClose: () => void;
  checkedMarket: RegisteredMarketInfo;
  onResetChecked: () => void;
};

type FormData = {
  newAlias: string;
};

const UpdateMarketAliasDialogContainer = ({
  onClose,
  checkedMarket,
  onResetChecked,
}: UpdateMarketAliasDialogContainerProps) => {
  const user = useAppSelector((state) => state.user);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { mutate: updateMarketAlias, isPending } = usePost(CPLAT_API_ROUTES.updateMarketAlias);
  const [isRefetching, setisRefetching] = useState(false);
  const { register, watch, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      newAlias: checkedMarket.MarketAlias,
    },
  });

  const onSubmit: SubmitHandler<FormData> = (values, e) => {
    e?.preventDefault();
    const { CplatToken } = user;
    const { MarketNameEnglish, MarketAlias, MarketId } = checkedMarket;

    const body = {
      CplatToken,
      MarketName: MarketNameEnglish,
      StoreId: MarketId,
      originMarketAlias: MarketAlias,
      toBeMarketAlias: values.newAlias,
    };

    updateMarketAlias(body, {
      onSuccess(res) {
        const { code } = res.data as CplatApiResponse<undefined>;

        if (code === '200') {
          setisRefetching(true);
          queryClient
            .refetchQueries({ queryKey: [CPLAT_API_ROUTES.getRegisteredMarketInfo] })
            .then(() => {
              onClose();
              onResetChecked();
            });
        } else if (code === '722') {
          toast({
            title: '❕동일한 마켓별칭이 존재합니다.',
            description: <p className="pl-[15px]">다른 별칭으로 다시 시도해 주세요</p>,
            variant: 'error',
          });
          onClose();
          onResetChecked();
        } else {
          toast({
            title: '❕예상치 못한 에러가 발생했습니다. 다시 시도해 주십시오',
            description: <p className="pl-[15px]">에러코드 {code}</p>,
            variant: 'error',
          });
          onClose();
          onResetChecked();
        }
      },
      onError(err) {
        toast({
          title: '❕예상치 못한 에러가 발생했습니다. 다시 시도해 주십시오',
          description: <p className="pl-[15px]">에러코드 {err.status}</p>,
          variant: 'error',
        });
        onClose();
        onResetChecked();
      },
    });
  };

  const formValues = watch();
  const isDisable = isPending || checkedMarket.MarketAlias === formValues.newAlias;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="text-gray-800 c-b1">스토어 별칭 설정</p>
      <TextField
        className="mt-[10px] h-[56px] rounded-[10px] bg-gray-100 focus:border-none"
        InputProps={{
          endAdornment: (
            <CircleCloseIcon className="cursor-pointer" onClick={() => setValue('newAlias', '')} />
          ),
        }}
        maxLength={15}
        {...register('newAlias')}
      />
      <p className="mt-[6px] text-right text-gray-600 c-c1">{formValues.newAlias.length}/15</p>
      <div className="mt-[10px] flex justify-end space-x-[10px]">
        <Button
          onClick={onClose}
          type="button"
          className="w-[100px]"
          variant="ghost"
          disabled={isPending}
        >
          취소
        </Button>
        <Button
          type="submit"
          className="w-[100px] c-b3"
          isLoading={isPending || isRefetching}
          disabled={isDisable}
        >
          변경
        </Button>
      </div>
    </form>
  );
};

export default UpdateMarketAliasDialogContainer;
