import LotteonGuide1 from '@/assets/images/LotteonGuide1.png';
import LotteonGuide2 from '@/assets/images/LotteonGuide2.png';
import LotteonGuide3 from '@/assets/images/LotteonGuide3.png';
import Button from '@/components/atoms/Button';
import { Card } from '@/components/atoms/Card';
import useCopy from '@/hooks/useCopy';

const LotteonGuideImgCard = () => {
  const { copyToClipboard } = useCopy({
    toastMessage: 'IP가 복사됐어요',
  });
  return (
    <Card>
      <img src={LotteonGuide1} alt="이미지" className="pr-[40px]" />
      <div className="flex items-center justify-between pr-[60px]">
        <img src={LotteonGuide2} alt="이미지" width={500} />
        <Button
          className="flex h-[42px] items-center bg-primary-100 text-primary hover:bg-primary-200 active:bg-primary-100"
          onClick={() => {
            const id = '13.124.61.168;3.34.167.151;43.200.9.207;';
            copyToClipboard(id);
          }}
        >
          IP 복사하기
        </Button>
      </div>
      <img src={LotteonGuide3} alt="이미지" />
    </Card>
  );
};

export default LotteonGuideImgCard;
