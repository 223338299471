import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { type CheckedState } from '@radix-ui/react-checkbox';
import { useQueryClient } from '@tanstack/react-query';
import { X } from 'lucide-react';

import Button from '@/components/atoms/Button';
import { Card } from '@/components/atoms/Card';
import Input from '@/components/atoms/Input';
import TextField from '@/components/molecules/TextField';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import Checkbox from '@/components/atoms/Checkbox';
import InquiryButton from '@/components/organisms/InquiryButton';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useAppSelector } from '@/redux/store';
import { ROUTES } from '@/routes';

import PwSeeIcon from '@/assets/icons/PwSeeIcon.svg?react';
import PwHideIcon from '@/assets/icons/PwHideIcon.svg?react';
import SecondAuthContainer from './SecondAuthContainer';

// type RegisterMarketBody = {
//   MarketName: string;
//   MarketId: string;
//   MarketPassword: string;
//   VendorId: string;
//   AccessKey: string;
//   SecretKey: string;
//   AuthCode: string;
//   AgreementList: {
//     Key: string;
//     GuideMessage: string;
//     ReferenceMessage: string;
//     ReferenceUrl: string;
//     IsInputMandatory: boolean;
//     values: boolean;
//   }[];
// };

type TermsFormData = {
  Key: string;
  GuideMessage: string;
  ReferenceMessage: string;
  ReferenceUrl: string;
  IsInputMandatory: boolean;
  values: CheckedState;
};

type FormData = {
  [key: string]: string;
} & {
  AgreementList: TermsFormData[];
};

type MarketRegisterContainerProps = {
  marketTextboxOptionalList: {
    Key: string;
    PlaceHolder: string;
    HasToBeEncrypted: boolean;
  }[];
  marketAuthCheckOptionalList: MarketAuthCheckOptional[];
  marketNameEnglish: string;
  marketName: string;
};

const DEFAULT_MARKET_INFO = {
  MarketName: '',
  MarketId: '',
  MarketPassword: '',
  VendorId: '',
  AccessKey: '',
  SecretKey: '',
  AuthCode: '',
  AgreementList: [],
};

const MarketRegisterContainer = ({
  marketTextboxOptionalList,
  marketAuthCheckOptionalList,
  marketName,
  marketNameEnglish,
}: MarketRegisterContainerProps) => {
  const { register, watch, handleSubmit, setValue, setError, formState } = useForm<FormData>({
    // @ts-ignore
    defaultValues: {
      ...marketTextboxOptionalList.reduce(
        (acc, { Key }) => ({ ...acc, [Key]: '' }),
        {} as Omit<FormData, 'AgreementList'>,
      ),
      AgreementList: marketAuthCheckOptionalList.map((item) => ({
        ...item,
        IsInputMandatory: true,
        values: true,
      })),
    },
  });

  // dialog
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const formValues = watch();
  const { errors } = formState;

  const inputValues = Object.values(formValues).filter((value) => typeof value === 'string');

  const isDisable =
    inputValues.every((value) => value !== '') &&
    formValues.AgreementList.every(({ values, IsInputMandatory }) => IsInputMandatory && values)
      ? false
      : true;

  const user = useAppSelector((state) => state.user);
  const { CplatToken } = user;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // 입력값 숨기기 or 보이기
  const [hideList, setHideList] = useState(
    marketTextboxOptionalList
      .filter((item) => item.HasToBeEncrypted)
      .reduce(
        (acc, { Key }) => ({
          ...acc,
          [Key]: true,
        }),
        {} as { [key: string]: boolean },
      ),
  );

  // 2차 인증이 필요한 경우에, response로 받아온 휴대폰 번호에 대한 메시지
  const [secondAuthPhoneNumber, setSecondAuthPhoneNumber] = useState<string>('');
  // 남은 시간초
  const [seconds, setSeconds] = useState<number>(180);
  // 카운트다운 타이머 활성화 여부
  const [isActive, setIsActive] = useState<boolean>(false);

  // 2차 인증에 필요한 유효시간 3분 카운트 다운
  useEffect(() => {
    // 2차 인증 api 호출해서 타이머 활성화되었을 때 카운트 다운 시작
    if (seconds > 0 && isActive) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [seconds, isActive]);

  const { mutate: marketRegister, isPending } = usePost(CPLAT_API_ROUTES.registerMarketAccount, {});

  // 연동 동의 핸들러
  const handleCheck = (key: string, checked: CheckedState) =>
    setValue(
      'AgreementList',
      formValues.AgreementList.map((agreement) =>
        agreement.Key === key ? { ...agreement, values: checked } : agreement,
      ),
    );

  // 마켓연동 완료 후 modal 에서 확인 버튼 핸들러
  const handleComplete = () => {
    setIsCompleteModalOpen(false);
    navigate(ROUTES.market);
  };

  // 마켓등록 핸들러
  const onSubmit: SubmitHandler<FormData> = (formData, e) => {
    // TODO 동의받는 로직은 따로 구현해야 한다.
    e?.preventDefault();

    const body = {
      CplatToken,
      MarketName: marketNameEnglish,
      MarketLoginInfo: JSON.stringify({
        ...DEFAULT_MARKET_INFO,
        MarketName: marketNameEnglish,
        ...formData,
      }),
    };

    marketRegister(body, {
      onSuccess(res) {
        const { code, message } = res.data as CplatApiResponse<string>;

        if (code === '200') {
          setIsCompleteModalOpen(true);
          queryClient.refetchQueries({
            queryKey: [CPLAT_API_ROUTES.getRegisteredMarketInfo],
          });
          return;
        }

        if (code === '610') {
          setError('root', { message: '아이디 또는 비밀번호가 일치하지 않습니다.' });
          return;
        }

        // 다른 셀러에 의해 이미 등록된 마켓일 경우
        if (code === '611') {
          setError('root', { message });
          return;
        }

        // 2차 인증이 필요한 경우 (e.g. 롯데온)
        if (code === '615') {
          setSecondAuthPhoneNumber(message);

          // 남은 시간 카운트 활성화
          setIsActive(true);
          // 잔여 시간 3분 설정
          setSeconds(180);
          return;
        }

        setError('root', { message });
      },
    });
  };

  return (
    <>
      <div className="rounded-[20px] pl-[6px]">
        {/* 
        1) marketNameEnglish === 'lotteon' 이면서
        2) 인증 api 호출 후에 노출됨 */}
        {marketNameEnglish === 'lotteon' && isActive && (
          <SecondAuthContainer
            where={'ADD_MARKET'}
            seconds={seconds}
            secondAuthPhoneNumber={secondAuthPhoneNumber}
            marketNameEnglish={marketNameEnglish}
            marketName={marketName}
            marketId={formValues.MarketId}
          />
        )}

        <Card className="w-[410px] p-[40px]">
          <form>
            <div className="relative">
              {marketTextboxOptionalList.map(({ HasToBeEncrypted, Key, PlaceHolder }, i) =>
                HasToBeEncrypted ? (
                  <TextField
                    {...register(Key)}
                    key={Key}
                    placeholder={PlaceHolder}
                    type={hideList[Key] ? 'password' : 'text'}
                    variant="outline"
                    className={`${i > 0 ? 'mt-[16px]' : ''} c-b2`}
                    InputProps={{
                      endAdornment: hideList[Key] ? (
                        <PwHideIcon
                          onClick={() => setHideList({ ...hideList, [Key]: !hideList[Key] })}
                          className="cursor-pointer"
                        />
                      ) : (
                        <PwSeeIcon
                          onClick={() => setHideList({ ...hideList, [Key]: !hideList[Key] })}
                          className="cursor-pointer"
                        />
                      ),
                    }}
                  />
                ) : (
                  <Input
                    {...register(Key)}
                    key={Key}
                    placeholder={PlaceHolder}
                    variant="outline"
                    className={`${i > 0 ? 'mt-[16px]' : ''} c-b2`}
                  />
                ),
              )}
              {errors.root?.message && <p className="text-red c-c1">{errors.root.message}</p>}
            </div>
            <div className="mt-[48px]">
              {formValues.AgreementList.map(({ Key, values }) => (
                <div key={Key} className="flex items-center space-x-[8px]">
                  <Checkbox
                    checked={values}
                    onCheckedChange={(checked) => handleCheck(Key, checked)}
                  />
                  <p className="align-bottom text-gray-750 c-b2">[필수] 계정 연동 권한 동의</p>
                  <a
                    className="cursor-pointer align-bottom text-gray-600 underline c-b6"
                    onClick={() => setIsTermsModalOpen(true)}
                  >
                    동의 내용 보기
                  </a>
                </div>
              ))}
              <Button
                type="submit"
                className="mt-[12px] h-[52px] w-full rounded-[16px] c-b1"
                isLoading={isPending}
                disabled={isDisable || isPending}
                onClick={handleSubmit(onSubmit)}
              >
                {'연동하기'}
              </Button>
            </div>
          </form>
        </Card>
        <InquiryButton className="mt-[24px]" />
      </div>
      <Dialog open={isCompleteModalOpen}>
        <DialogContent className="flex !w-[380px] flex-col items-center p-[30px] pb-[20px] text-center">
          <h3 className="text-gray-800 c-t2">{marketName} 연동이 완료 되었습니다.</h3>
          <p className="text-gray-750 c-b1">
            정보 수집이 완료되면 앱에서 안내드려요.
            <br />
            최근 1주일 정보 : 24시간 이내 연동
            <br />
            최근 1년 : 1주일 이내 연동 <br />
          </p>
          <Button onClick={handleComplete} variant="secondary" className="mt-[10px] w-[200px]">
            확인
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={isTermsModalOpen} onOpenChange={() => setIsTermsModalOpen(!isTermsModalOpen)}>
        <DialogContent className="flex h-[500px] !w-[800px] flex-col items-center !p-0 text-center">
          <div
            className="flex w-full cursor-pointer justify-end p-[20px]"
            onClick={() => setIsTermsModalOpen(false)}
          >
            <X />
          </div>
          <iframe
            src="https://www.cplat.io/sb_login"
            className="h-[100%] w-[100%] rounded-[16px]"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MarketRegisterContainer;
