import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import moment from 'moment';

import Button from '@/components/atoms/Button';
import TextField from '@/components/molecules/TextField';
import useTimeCount from '@/hooks/useTimeCount';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '@/redux/store';
import { setUser } from '@/redux/userSlice';
// import { setVerificationToken as setGlobalVerificationToken } from '@/redux/authSlice';
import { ROUTES } from '@/routes';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { useToast } from '@/hooks/useToast';
import { useSessionStorage } from 'usehooks-ts';
import InfoFillGrayIcon from '@/assets/icons/InfoFillGrayIcon.svg?react';
import SellerBoxLogoColumn from '@/assets/icons/SellerBoxLogoColumn.svg?react';

import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import SignUpContainer from './SignUpContainer';
import ArrowRegularBackwardIcon from '@/assets/icons/ArrowRegularBackwardIcon.svg?react';
import { AES } from '@/utils/AES';

/** 휴대폰 번호 로그인 상태값
 * 참고 : https://circleplatform.atlassian.net/wiki/spaces/ON/whiteboard/672497677
 * 1. reqPhoneNum - 핸드폰 번호 입력 받기 전 상태
 * 2. successPhoneNum - 올바른 핸드폰 번호를 입력 받은 상태 (010, 11자리)
 * 3. reqAuthCode - 인증하기 버튼을 클릭해서, 인증 문자를 요청한 상태
 * 4. expiredAuthCode - 인증 버튼 유효 시간이 초과된 상태
 * 5. successAuthCode - 올바른 인증문자를 입력해서, 인증 성공 상태
 * 6. reqAgreement - 신규회원으로 회원가입이 필요해서, 동의 요청 및 회원가입을 해야 하는 상태
 */
type SignInForm = {
  phoneNo: string;
  step:
    | 'reqPhoneNum'
    | 'successPhoneNum'
    | 'reqAuthCode'
    | 'expiredAuthCode'
    | 'successAuthCode'
    | 'reqAgreement';
  authCode: string;
};

/**
 * 전화번호에 - 을 추가하는 함수
 */
const addHyphenPhoneNumber = (digits: string) => {
  if (digits.length > 3 && digits.length <= 7) {
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  } else if (digits.length > 7 && digits.length <= 11) {
    return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7)}`;
  }
  return digits;
};

/**
 * 전화번호에서 - 을 제거하는 함수
 */
const removeHyphen = (digits: string) => digits.replace(/-/g, '');

const PhoneSigninContainer = () => {
  const dispatch = useAppDispatch();
  const { register, formState, setValue, setError, watch } = useForm<SignInForm>({
    defaultValues: {
      phoneNo: '',
      step: 'reqPhoneNum',
      authCode: '',
    },
  });
  const { errors } = formState;

  const navigate = useNavigate();
  const { toast } = useToast();

  const { mutate: receiveAuthCode } = usePost(CPLAT_API_ROUTES.sendAuthCode);
  const { mutate: verifyAuthCode } = usePost(CPLAT_API_ROUTES.verifyAuthCode);
  const { mutate: getStoreList } = usePost(CPLAT_API_ROUTES.getStoreList);
  // TODO isPending 체크
  const { mutate: signin, isPending } = usePost(CPLAT_API_ROUTES.signInByPhoneNo);

  // 시간을 카운트하는 훅
  const { setTimeLeft, timeLeft } = useTimeCount(0);
  // 문자 재발송을 10초동안 막기위해 카운트하는 시간
  const { timeLeft: tenSeconds, setTimeLeft: setTenSeconds } = useTimeCount(0);
  let isPreventReAuthCode = tenSeconds > 0;

  const formValues = watch();
  const { phoneNo, authCode, step } = formValues;

  const [smsToken, setSmsToken] = useState('');
  const [isSingupModalOpen, setIsSingupModalOpen] = useState<boolean>(false);

  const [_, setCplatToken] = useSessionStorage('cplatToken', '');

  // auth 타임아웃 관리
  useEffect(() => {
    if (timeLeft === 0 && step === 'reqAuthCode') {
      setError('authCode', { type: 'validate', message: '입력시간이 초과되었습니다.' });
      setValue('step', 'expiredAuthCode');
    } else {
      if (0 < authCode.length && authCode.length < 6) setError('authCode', {});
    }
  }, [timeLeft, step]);

  // auth code 6자리 입력 받았을 때
  useEffect(() => {
    // 입력한 인증코드가 6자리이고 유효시간이 있을 때
    if (authCode.length !== 6 || timeLeft <= 0) return;

    // 인증문자 확인 api
    verifyAuthCode(
      {
        SmsToken: smsToken,
        SmsCode: authCode,
      },
      {
        onSuccess(res) {
          const { code, data } = res.data;
          if (code === '580') {
            setError('authCode', {
              type: 'validate',
              message: '인증번호가 일치하지 않습니다. 다시 입력해 주세요.',
            });
            return;
          }

          if (code === '200') {
            // 코드 인증 시, 바로 로그인 후 화면이동
            handleSignin(data[0].VerificationToken);
            setValue('step', 'successAuthCode');
            setError('authCode', {
              type: 'notice',
              message: '인증이 완료되었습니다.',
            });
            return;
          }

          toast({ title: '에러 발생했습니다.', description: code });
        },
      },
    );
  }, [authCode]);

  // 핸드폰 input 핸들러  010 으로 시작하는 지 유효성 검사
  const handlePhoneNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    value = value.replace(/[^\d-]/g, '');
    const digits = removeHyphen(value);

    if (digits.length > 11) return;

    const isnNotIncludePrefixNum = digits.length >= 3 && digits.substring(0, 3) !== '010';

    if (isnNotIncludePrefixNum) {
      setError('phoneNo', {
        type: 'pattern',
        message: '휴대폰 번호는 "010-" 으로 시작해야합니다.',
      });
    } else {
      setError('phoneNo', {});
    }

    value = addHyphenPhoneNumber(digits);

    if (digits.length === 11 && !isnNotIncludePrefixNum) {
      setValue('step', 'successPhoneNum');
    } else {
      setValue('step', 'reqPhoneNum');
    }

    // 입력값을 업데이트
    setValue('phoneNo', value);
  };

  // authcode 요청 버튼 핸들러
  const handleReqAuthCode = () => {
    setError('authCode', {
      type: 'notice',
      message: '인증번호를 발송했습니다.',
    });
    setValue('authCode', '');
    setValue('step', 'reqAuthCode');

    setTenSeconds(10);
    setTimeLeft(180);

    // 인증 문자 요청 api
    receiveAuthCode(
      {
        ServiceType: 0,
        PhoneNo: removeHyphen(phoneNo),
      },
      {
        onSuccess(res) {
          setSmsToken(res.data.data[0].SmsToken);
        },
        onError(error) {
          toast({
            title: '예상치 못한 에러가 발생했습니다.',
            description: error.message,
          });
        },
      },
    );
  };

  // 로그인 버튼  핸들러
  const handleSignin = (token: any) => {
    // 로그인 api 및 회원가입 라우팅
    signin(
      { verificationToken: token, osType: 'web', currentVersion: '1.0.0' },
      {
        onSuccess(res) {
          const { code, data } = res.data as CplatApiResponse<UserData[]>;
          if (code === '200') {
            const encryptedToken = AES.encryptData(data[0].CplatToken);
            setCplatToken(encryptedToken);
            dispatch(setUser(data[0]));
            if (phoneNo === '010-9348-3900') {
              navigate(`${ROUTES.admin}`, { replace: true });
              return;
            } else {
              getStoreList(
                {
                  cplatToken: data[0].CplatToken,
                },
                {
                  onSuccess({ data }) {
                    const { code } = data;
                    if (code === '200') {
                      navigate(ROUTES.productShipment);
                    } else {
                      navigate(`${ROUTES.market}?origin=signin`);
                    }
                  },
                },
              );
            }
            toast({
              icon: '✅',
              title: '셀러박스에 로그인 되었어요!',
              variant: 'dark',
            });
            return;
          } else {
            const encryptedToken = AES.encryptData(token);
            setCplatToken(encryptedToken);
            setIsSingupModalOpen(true);
          }
        },
      },
    );
  };

  return (
    <>
      <div className="mb-[100px] mt-[33px] flex w-[570px] flex-col items-center text-center">
        <div className="mb-[28px]">
          <SellerBoxLogoColumn />
        </div>
        {/* 타이틀 + 설명 */}
        {step === 'reqAgreement' ? (
          <div className="mb-[22px] flex items-center gap-[8px] text-gray-800 Title1B24">
            <ArrowRegularBackwardIcon
              className={`cursor-pointer text-[20px]`}
              onClick={() => {
                setValue('step', 'reqPhoneNum');
                setValue('phoneNo', '');
                setValue('authCode', '');
              }}
            />
            <h2>휴대폰 번호로 가입</h2>
          </div>
        ) : (
          <div className="w-[335px]">
            <h2 className="mb-[30px] text-gray-800 Title2B20">
              휴대폰 번호로 가입 및 로그인을 진행할게요
            </h2>
            <div className="flex gap-[4px] rounded-sm bg-gray-100 p-[8px]">
              <InfoFillGrayIcon />
              <h2 className="text-start text-gray-700 Caption1M12">
                카카오톡으로 가입한 안드로이드 사용자의 경우,
                <br />
                카카오 계정에 연동된 번호를 입력해 주세요
              </h2>
            </div>
          </div>
        )}

        <div className="w-[330px]">
          {/* 연락처 입력폼 + 인증하기 버튼 */}
          <div className="mt-[20px] flex">
            <TextField
              {...register('phoneNo', {
                required: true,
                onChange: handlePhoneNoChange,
                value: phoneNo,
              })}
              disabled={step === 'successAuthCode' || step === 'reqAgreement'}
              variant="outline"
              placeholder="휴대폰 번호 입력"
              maxLength={13}
              error={errors.phoneNo}
              type="tel"
            />
            <Button
              onClick={handleReqAuthCode}
              disabled={
                isPreventReAuthCode || (step !== 'successPhoneNum' && step !== 'expiredAuthCode')
              }
              className="disabled:text ml-[10px]  whitespace-nowrap bg-primary-100 text-primary-500 c-b4 hover:bg-primary-200 active:bg-primary-100 disabled:text-white"
            >
              {step === 'reqPhoneNum' || step === 'successPhoneNum' ? '인증하기' : '재전송'}
            </Button>
          </div>

          {/* 인증번호 입력폼 + 로그인 버튼 */}
          <div className="mt-[20px] text-blue-400 Body1S16">
            {step === 'reqAuthCode' ||
            step === 'expiredAuthCode' ||
            step === 'successAuthCode' ||
            step === 'reqAgreement' ? (
              <>
                <TextField
                  {...register('authCode')}
                  error={errors.authCode}
                  maxLength={6}
                  variant="outline"
                  placeholder="인증번호 6자리"
                  disabled={step === 'successAuthCode' || step === 'reqAgreement'}
                  InputProps={{
                    endAdornment: step === 'reqAuthCode' && (
                      <time>{moment.utc(timeLeft * 1000).format('m:ss')}</time>
                    ),
                  }}
                />
                <div className="mt-[40px]">
                  {step !== 'reqAgreement' && (
                    <Button
                      isLoading={isPending}
                      loadingClassName="w-[24px]"
                      className="h-[52px] w-full rounded-[15px] Body1S16"
                      disabled={isPending || step !== 'successAuthCode'}
                    >
                      로그인
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <div className="h-[127px]"></div>
            )}
            {/* 이용약관 동의 및 회원가입 컨테이너 */}
            {step === 'reqAgreement' && <SignUpContainer />}
          </div>
        </div>
      </div>
      <Dialog open={isSingupModalOpen}>
        <DialogContent className="items-left flex !w-[340px] flex-col gap-0 p-[20px] pt-[30px]">
          <p className="mb-[16px] text-gray-900 Title3S18">셀러박스 회원가입이 필요해요</p>
          <p className="mb-[30px] text-gray-750 Body6M14">
            입력한 번호로 셀러박스에 가입한 내역이 없어요.
            <br />
            서비스 이용을 위해 회원가입을 진행할까요?
          </p>
          <div className="flex gap-[10px]">
            <Button
              variant="secondary"
              onClick={() => {
                setIsSingupModalOpen(false);
                setValue('step', 'reqPhoneNum');
                setValue('phoneNo', '');
                setValue('authCode', '');
              }}
              className="h-[44px] w-full"
            >
              다른 번호 입력
            </Button>
            <Button
              variant="default"
              onClick={() => {
                setValue('step', 'reqAgreement');

                setIsSingupModalOpen(false);
              }}
              className="h-[44px] w-full"
            >
              가입하기
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PhoneSigninContainer;
