import BadgeSquare from '@/components/atoms/BadgeSquare';
import InfoContainer from './_components/InfoContainer';
import BulletList from '@/components/molecules/BulletList';
import TextField from '@/components/molecules/TextField';
import SearchIcon from '@/assets/icons/SearchIcon.svg?react';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import IconInfo from '@/assets/icons/IconInfo.svg?react';
import IconShipping from '@/assets/icons/IconShipping.svg?react';
import ExampleProduct from '@/assets/images/ExampleProduct.png';
import { cplatApiV2Sellkey } from '@/api';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/redux/store';
import { useToast } from '@/hooks/useToast';
import { DIAGNOSIS_INITIAL_DATA, DiagnosisDataType } from '@/mock/Lab';
import { autoComma, formatDateKorean } from '@/utils/parser';
import LoadingBox from '@/assets/animation/LoadingBox.json';
import Lottie from 'lottie-react';

const DiagnoseProductPage = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const cplatToken = searchParams.get('cplatToken');
  const user = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const { toast } = useToast();
  const [isExample, setIsExample] = useState<boolean>(true);
  const [data, setData] = useState<DiagnosisDataType>(DIAGNOSIS_INITIAL_DATA);

  const getDiagnosis = async () => {
    try {
      setIsLoading(true);

      const {
        data: { code, data, message },
      } = await cplatApiV2Sellkey.post('sellkey/get_product_diagnosis', {
        CplatToken: cplatToken || user.CplatToken,
        GoodsUrl: input,
      });

      if (code === '200') {
        setData(data[0]);
        setIsExample(false);
      } else {
        toast({
          icon: '⚠️',
          title: message,
          variant: 'dark',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getCaseInfo = (type: string, value: number) => {
    switch (type) {
      case 'GoodsNameLength':
        if (value > 50) {
          return {
            isPass: false,
            message: `글자수가 ${value}자로 50자 이상이에요. 상품명의 길이를 조절해 보세요.`,
          };
        } else if (50 >= value && value > 35) {
          return {
            isPass: true,
            message: `글자수가 ${value}자로 50자 이하예요. 상품명의 길이가 적절해요.`,
          };
        } else {
          return {
            isPass: false,
            message: `글자수가 ${value}자로 35자 이하예요. 상품명의 길이를 늘려보세요.`,
          };
        }

      case 'KeywordCount':
        if (value > 9) {
          return {
            isPass: false,
            message: `키워드 수가 ${value}개예요. 스마트스토어 검색 SEO에서는 7개를 권장하고 있어요.`,
          };
        } else if (9 >= value && value > 5) {
          return {
            isPass: true,
            message: `키워드 수가 ${value}개예요. 스마트스토어 검색 SEO에서는 권장하는 키워드 수예요.`,
          };
        } else {
          return {
            isPass: false,
            message: `키워드 수가 ${value}개예요. 스마트스토어 검색 SEO에서는 7개를 권장하고 있어요.`,
          };
        }
      case 'IntersectionKeywordCount':
        if (value > 1) {
          return {
            isPass: false,
            message: `상품명에서 ${value}개의 동일한 키워드를 발견했어요. 동일한 키워드를 삭제해 SEO를 최적화하세요.`,
          };
        } else {
          return {
            isPass: true,
            message: `상품명에 동일한 키워드가 없어요. SEO 최적화 상태예요.`,
          };
        }

      case 'CategoryMatchingRate':
        if (value === 100) {
          return {
            isPass: true,
            message: `카테고리 적합도가 100%로 노출에 유리해요.`,
          };
        } else {
          return {
            isPass: false,
            message: `카테고리 적합도가 ${value}%로 노출에 불리해요.`,
          };
        }
      case 'RegisterDate': {
        const SIX_MONTHS_IN_MS = 6 * 30 * 24 * 60 * 60 * 1000;
        const currentDate = new Date();
        const timeDiff = currentDate.getTime() - value;

        if (timeDiff <= SIX_MONTHS_IN_MS) {
          return {
            isPass: true,
            message: `등록된 지 6개월 이하인 상품으로 노출에 유리해요.`,
          };
        } else {
          return {
            isPass: false,
            message: `등록된 지 6개월 이상된 상품으로 노출에 불리해요.`,
          };
        }
      }
      case 'NotRegisteredDetailCount':
        if (value < 5) {
          return {
            isPass: true,
            message: `상세 정보 미표기가 ${value}건 있어요. 상세 정보가 표기되어야 상품 노출에 유리해요.`,
          };
        } else {
          return {
            isPass: false,
            message: `상세 정보 미표기가 ${value}건 있어요. 상세 정보가 표기되어야 상품 노출에 유리해요.`,
          };
        }
      case 'NotAnsweredReviewCount':
        if (value === 0) {
          return {
            isPass: true,
            message: `답변은 상품의 신뢰와 재구매 비율을 높여줘요.`,
          };
        } else if (0 < value && value < 5) {
          return {
            isPass: true,
            message: `최근 ${value}건의 리뷰에 답변을 하지 않았어요. 답변은 상품의 신뢰와 재구매 비율을 높여줘요.`,
          };
        } else {
          return {
            isPass: false,
            message: `최근 ${value}건의 리뷰에 답변을 하지 않았어요. 답변은 상품의 신뢰와 재구매 비율을 높여줘요.`,
          };
        }
      case 'QnaAnswerRate':
        if (value >= 90) {
          return {
            isPass: true,
            message: `${value}% 응답률이에요. 1일 이내 응답률을 90% 이상 유지하면 하면 굿서비스 스토어로 선정될 수 있어요.`,
          };
        } else {
          return {
            isPass: false,
            message: `${value}% 응답률이에요. 1일 이내 응답률을 90% 이상 유지하면 하면 굿서비스 스토어로 선정될 수 있어요.`,
          };
        }
      case 'AverageDeliveryLeadTime':
        if (value <= 2) {
          return {
            isPass: true,
            message: `평균 배송일이 ${value}일이에요. 평균 배송일을 2일 이내로 유지하면 굿서비스 스토어로 선정될 수 있어요.`,
          };
        } else {
          return {
            isPass: false,
            message: `평균 배송일이 ${value}일이에요. 평균 배송일을 2일 이내로 유지하면 굿서비스 스토어로 선정될 수 있어요.`,
          };
        }
      default:
        return {
          isPass: true,
          message: `${value}`,
        };
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input === '') return;
    getDiagnosis();
    inputRef.current && inputRef.current.blur();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

  return (
    <>
      <form className="mx-[20px] mt-[16px]" onSubmit={handleSubmit}>
        <TextField
          ref={inputRef}
          className="focous:border-none border-none bg-gray-100 pl-[40px] Body6M14"
          value={input}
          onChange={handleChange}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          placeholder="스마트스토어 상품 주소를 입력해주세요"
          maxLength={150}
          type="search"
          enterKeyHint="search"
        />
      </form>
      {isLoading ? (
        <div className="mt-[140px] flex flex-col items-center">
          <Lottie className="w-[84px]" animationData={LoadingBox} loop />
          <div className="mb-[8px] text-center text-gray-700 Title3S18">
            조금만 기다려 주세요
            <br />
            분석 결과를 준비하고 있어요
          </div>
          <div className="text-gray-750 Body1S16">분석하는데 1분 정도 소요될 수 있어요</div>
        </div>
      ) : (
        <div>
          <div className="mx-[20px] mb-[24px] mt-[20px]">
            <div className="mb-[16px] flex items-center gap-[4px]">
              <IconInfo />
              <span className="text-gray-700 Caption1M12">
                {isExample ? '예시 화면이에요' : '검색 상품이에요'}
              </span>
            </div>
            <div className="mb-[20px] flex gap-[10px]">
              <img
                src={data.ImageUrl || ExampleProduct}
                className="h-[60px] w-[60px] rounded-[8px] border-[1px] border-gray-150"
              />
              <div>
                <div className="mb-[8px] flex flex-col gap-[4px]">
                  <div className="text-gray-600 Body5S14">{data.StoreName}</div>
                  <div className="text-gray-800 Body1S16">{data.GoodsNameInfo.GoodsName}</div>
                  <div className="text-gray-600 Caption1M12">
                    제품 등록일 : {formatDateKorean(data.RegisterDate)}
                  </div>
                </div>
                <div className="flex items-center gap-[12px]">
                  <span className="text-gray-800 Body1S16">{autoComma(data.Price)}원</span>
                  <div className="flex items-center gap-[6px]">
                    <IconShipping />
                    <span className="text-gray-800 Body2M16">
                      {data.ShippingFee === 0 ? '무료' : `${autoComma(data.ShippingFee)} 원`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex rounded-[10px] bg-gray-100 px-[12px] py-[10px]">
              <div className="w-[180px]">
                <div className="flex">
                  <div className="w-[100px] text-gray-600 Body6M14">6개월 판매량</div>
                  <div className="text-gray-700 Body5S14">
                    {autoComma(data.SalesCountFor6Month)}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[100px] text-gray-600 Body6M14">리뷰 수</div>
                  <div className="text-gray-700 Body5S14">{autoComma(data.ReviewAmount)}</div>
                </div>
              </div>
              <div>
                <div className="flex">
                  <div className="w-[80px] text-gray-600 Body6M14">찜 수</div>
                  <div className="text-gray-700 Body5S14">{autoComma(data.KeepCount)}</div>
                </div>
                <div className="flex">
                  <div className="w-[80px] text-gray-600 Body6M14">평점</div>
                  <div className="text-gray-700 Body5S14">{data.AverageReviewScore}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[12px] bg-gray-150"></div>
          <div className="mx-[20px] mb-[24px] mt-[30px]">
            <div className="mb-[12px] text-gray-800 Body1S16">기본 정보</div>
            <div className="flex flex-col gap-[20px]">
              <InfoContainer
                title={'상품명'}
                isPass={
                  getCaseInfo('GoodsNameLength', data.GoodsNameInfo.GoodsNameLength).isPass ||
                  getCaseInfo('KeywordCount', data.GoodsNameInfo.KeywordCount).isPass ||
                  getCaseInfo(
                    'IntersectionKeywordCount',
                    data.GoodsNameInfo.IntersectionKeywordCount,
                  ).isPass
                }
                name={data.GoodsNameInfo.GoodsName}
                list={[
                  getCaseInfo('GoodsNameLength', data.GoodsNameInfo.GoodsNameLength).message,
                  getCaseInfo('KeywordCount', data.GoodsNameInfo.KeywordCount).message,
                  getCaseInfo(
                    'IntersectionKeywordCount',
                    data.GoodsNameInfo.IntersectionKeywordCount,
                  ).message,
                ]}
              />
              <InfoContainer
                title={'카테고리'}
                isPass={
                  getCaseInfo('CategoryMatchingRate', data.CategoryInfo.CategoryMatchingRate).isPass
                }
                name={data.CategoryInfo.CategoryName}
                description={
                  getCaseInfo('CategoryMatchingRate', data.CategoryInfo.CategoryMatchingRate)
                    .message
                }
              />
              <InfoContainer
                title={'등록일'}
                isPass={getCaseInfo('RegisterDate', new Date(data.RegisterDate).getTime()).isPass}
                name={formatDateKorean(data.RegisterDate)}
                description={
                  getCaseInfo('RegisterDate', new Date(data.RegisterDate).getTime()).message
                }
              />
            </div>
          </div>
          <div className="h-[12px] bg-gray-150"></div>
          <div className="mx-[20px] mb-[24px] mt-[30px]">
            <div className="mb-[12px] text-gray-800 Body1S16">상품 상세</div>
            <div className="flex flex-col gap-[20px]">
              <InfoContainer
                title={'정보 제공고시'}
                isPass={
                  getCaseInfo('NotRegisteredDetailCount', data.NotRegisteredDetailCount).isPass
                }
                description={
                  getCaseInfo('NotRegisteredDetailCount', data.NotRegisteredDetailCount).message
                }
              />
            </div>
          </div>
          <div className="h-[12px] bg-gray-150"></div>
          <div className="mx-[20px] mb-[24px] mt-[30px]">
            <div className="mb-[12px] text-gray-800 Body1S16">판매 관리</div>
            <div className="flex flex-col gap-[20px]">
              <InfoContainer
                title={'리뷰'}
                isPass={
                  getCaseInfo('NotAnsweredReviewCount', data.ReviewInfo.NotAnsweredReviewCount)
                    .isPass
                }
                description={
                  getCaseInfo('NotAnsweredReviewCount', data.ReviewInfo.NotAnsweredReviewCount)
                    .message
                }
              />
              <InfoContainer
                title={'Q&A 응답'}
                isPass={getCaseInfo('QnaAnswerRate', data.QnaAnswerRate).isPass}
                description={
                  data.QnaTotalCount === 0
                    ? '1일 이내 응답률을 90% 이상 유지하면 굿서비스 스토어로 선정될 수 있어요.'
                    : getCaseInfo('QnaAnswerRate', data.QnaAnswerRate).message
                }
                useBadge={data.QnaTotalCount !== 0}
              />
              <InfoContainer
                title={'평균 배송일'}
                isPass={getCaseInfo('AverageDeliveryLeadTime', data.AverageDeliveryLeadTime).isPass}
                description={
                  getCaseInfo('AverageDeliveryLeadTime', data.AverageDeliveryLeadTime).message
                }
              />
            </div>
          </div>
          <div className="h-[12px] bg-gray-150"></div>
          <div className="mx-[20px] mb-[40px] mt-[30px]">
            <div className="mb-[12px] text-gray-800 Body1S16">리뷰 분석</div>
            <div className="mb-[20px] flex flex-col gap-[10px]">
              <div className="items-left flex">
                <BadgeSquare variant={'green'} size="md">
                  리뷰 요약
                </BadgeSquare>
              </div>
              <div className="text-gray-700 Body6M14">{data.ReviewInfo.TotalReviewAnalysis}</div>
            </div>
            <div className="mb-[20px] flex flex-col gap-[10px]">
              <div className="items-left flex">
                <BadgeSquare variant={'blue'} size="md">
                  긍정리뷰
                </BadgeSquare>
              </div>
              <BulletList data={data.ReviewInfo.PositiveReviewAnalysisList} className="gap-0" />
            </div>
            <div className="mb-[20px] flex flex-col gap-[10px]">
              <div className="items-left flex">
                <BadgeSquare variant={'red'} size="md">
                  부정리뷰
                </BadgeSquare>
              </div>
              <BulletList data={data.ReviewInfo.NegativeReviewAnalysisList} className="gap-0" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DiagnoseProductPage;
