import { useToast } from './useToast';

const useGetToastFormat = () => {
  const { toast } = useToast();
  return {
    errorToast: (code: string, message?: string) =>
      toast({
        title: '❕예상치 못한 에러가 발생했습니다. 다시 시도해 주십시오',
        description: (
          <div className="pl-[15px]">
            <p>에러코드 {code}</p>
            <p>내용 {message}</p>
          </div>
        ),
        variant: 'error',
      }),
  };
};

export default useGetToastFormat;
