import { CPLAT_API_ROUTES, usePost } from '@/api';
import Button from '@/components/atoms/Button';
import { Card } from '@/components/atoms/Card';
import Input from '@/components/atoms/Input';
import { secondsParser } from '@/utils/parser';
import { SubmitHandler, useForm } from 'react-hook-form';
import { cn } from '@/utils/tailwind';
import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes';

type SecondAuthContainerProps = {
  /** 2차 인증 컨테이너 사용 위치 - 마켓 추가 | 비밀번호 변경 */
  where: 'ADD_MARKET' | 'CHANGE_PASSWORD';
  /** 남은 시간초 */
  seconds: number;
  /** 인증 중인 핸드폰 번호 */
  secondAuthPhoneNumber: string;
  /** 마켓 영어 이름 (e.g. lotteon) */
  marketNameEnglish: string;
  /** 마켓 한글 이름 (e.g. 롯데온) */
  marketName: string;
  /** 마켓 계정 */
  marketId: string;
};

type FormData = {
  [key: string]: string;
};

const SecondAuthContainer = ({
  where,
  seconds,
  secondAuthPhoneNumber,
  marketNameEnglish,
  marketName,
  marketId,
}: SecondAuthContainerProps) => {
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState<boolean>(false);
  const [isCounting, setIsCounting] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { register, handleSubmit, setError, clearErrors, formState } = useForm({
    defaultValues: {
      marketName: marketNameEnglish, // e.g. lotteon
      marketId, // e.g. 롯데온 계정(아이디)
      authCode: '', // 휴대폰에 온 인증번호
    },
  });

  const { errors } = formState;

  const { mutate, isPending } = usePost(CPLAT_API_ROUTES.setAuthCodeByMarket, {});

  // 2차 인증 완료처리 핸들러
  const onSubmit: SubmitHandler<FormData> = (formData, e) => {
    e?.preventDefault();

    mutate(formData, {
      onSuccess(res) {
        const { code, message } = res.data as CplatApiResponse<string>;
        if (code === '200') {
          // 인증 완료 후 팝업 띄우고, 팝업 확인 클릭 시 목록으로 이동
          setIsCompleteModalOpen(true);
          queryClient.refetchQueries({
            queryKey: [CPLAT_API_ROUTES.getRegisteredMarketInfo],
          });
        }
        if (code === '540') {
          setError('root', { message });
        }
      },
    });
  };

  // 연동완료 팝업 핸들러
  const handleComplete = () => {
    setIsCompleteModalOpen(false);
    navigate(ROUTES.market);
  };

  useEffect(() => {
    // 인증 완료 팝업 떴을 떄, 잔여 시간초 제거
    if (isCompleteModalOpen) {
      setIsCounting(false);
    }

    /** 잔여 입력시간 0초 되면 에러 띄우기 */
    if (seconds === 0) {
      setError('root', {
        message: '입력시간이 초과되었습니다.',
      });
      return;
      /** 2차 인증 코드 발급 버튼 눌러서, 잔여 입력시간 180초 되면 에러 클리어 */
    } else if (seconds === 180) {
      clearErrors();
    }
  }, [seconds]);

  return (
    <>
      <Card className="mb-[21px] w-[410px] p-[40px]">
        <form>
          <span className="mt-[8px] text-gray c-b6">{secondAuthPhoneNumber}</span>
          <div className="relative">
            <Input
              {...register('authCode')}
              key={'authCode'}
              variant={'outline'}
              placeholder={'인증번호를 입력하세요'}
              InputProps={{
                endAdornment: isCounting && (
                  <span className="text-blue-400 c-b1">{secondsParser(seconds)}</span>
                ),
              }}
            />
            {errors.root?.message && (
              <p className="absolute bottom-[-25px] left-0 text-red c-c1">{errors.root.message}</p>
            )}
          </div>
          <Button
            type="submit"
            className={cn(
              'h-[52px] w-full rounded-[16px] c-b1',
              errors.root?.message ? 'mt-[44px]' : 'mt-[26px]',
            )}
            isLoading={isPending}
            disabled={isPending || seconds === 0} // 잔여 입력시간 0초면 클릭 불가
            onClick={handleSubmit(onSubmit)}
          >
            연동하기
          </Button>
        </form>
      </Card>
      <Dialog open={isCompleteModalOpen}>
        {where === 'ADD_MARKET' ? (
          <DialogContent className="flex !w-[380px] flex-col items-center p-[30px] pb-[20px] text-center">
            <h3 className="text-gray-800 c-t2">{marketName} 연동이 완료 되었습니다.</h3>
            <p className="text-gray-750 c-b1">
              정보 수집이 완료되면 앱에서 안내드려요.
              <br />
              최근 1주일 정보 : 24시간 이내 연동
              <br />
              최근 1년 : 1주일 이내 연동 <br />
            </p>
            <Button onClick={handleComplete} variant="secondary" className="mt-[10px] w-[200px]">
              확인
            </Button>
          </DialogContent>
        ) : (
          <DialogContent className="flex !w-[380px] flex-col items-center p-[30px] pb-[20px] text-center">
            <h3 className="text-gray-900 c-t3">비밀번호 변경이 완료되었습니다.</h3>
            <Button onClick={handleComplete} variant="secondary" className="mt-[10px] w-[200px]">
              확인
            </Button>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default SecondAuthContainer;
