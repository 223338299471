import { useRive } from '@rive-app/react-canvas';

const useRiveAnimation = (src: string, autoplay: boolean) => {
  const { RiveComponent, rive } = useRive({
    src: 'animation_' + src + '.riv',
    artboard: src,
    autoplay,
  });
  return { RiveComponent, rive };
};

export default useRiveAnimation;
