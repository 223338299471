import { VariantProps } from 'class-variance-authority';
import { ErrorOption } from 'react-hook-form';
import { forwardRef, useId } from 'react';

import { cn } from '@/utils/tailwind';
import Input, { inputVariants } from '@/components/atoms/Input';

export type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants> & {
    rootClassName?: string;
    error?: ErrorOption;
    label?: string | React.ReactNode;
    InputProps?: {
      startAdornment?: React.ReactNode;
      endAdornment?: React.ReactNode;
    };
  };

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ rootClassName, className, error, InputProps, label, ...props }, ref) => {
    const id = useId();
    const isError = error && error.type;
    const isMessage = isError && error.message;
    const errorColor = isError
      ? error.type === 'notice'
        ? '!border-gray-300 border-b-[1px]'
        : '!border-red-400 border-b-[1px]'
      : '';

    // TODO adorment 크기에 따라 가변적인 padding 영역이 필요함.. 아니면 absolute 가 아닌 block 으로 해결해야 할 수도...
    const isEndAdorment = InputProps?.endAdornment !== undefined;
    const isStartAdornment = InputProps?.startAdornment !== undefined;

    return (
      <div className={cn('w-full', rootClassName)}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className="relative">
          <Input
            id={id}
            ref={ref}
            className={cn(
              errorColor,
              isStartAdornment ? 'pl-[30px]' : '',
              isEndAdorment ? 'pr-[30px]' : '',
              className,
            )}
            {...props}
          />
          {InputProps && InputProps.startAdornment && (
            <div className="absolute left-2 top-[50%] translate-y-[-50%]">
              {InputProps.startAdornment}
            </div>
          )}
          {InputProps && InputProps.endAdornment && (
            <div className="absolute right-2 top-[50%] m-auto translate-y-[-50%] whitespace-nowrap">
              {InputProps.endAdornment}
            </div>
          )}
          {isMessage && (
            <p
              className={cn(
                'absolute Body6M14',
                error.type === 'notice' ? 'text-gray-500' : 'text-red-600',
              )}
            >
              {error.message}
            </p>
          )}
        </div>
      </div>
    );
  },
);

export default TextField;
