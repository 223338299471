import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { ROUTES } from '@/routes';
import { AES } from '@/utils/AES';
import Tab, { TabDataValues, TabItem } from '@/components/molecules/Tab';
import CodeSigninContainer from '@/containers/auth/CodeSigninContainer';
import PhoneSigninContainer from '@/containers/auth/PhoneSigninContainer';

const TAB_DATA: TabItem[] = [
  { label: '휴대폰 번호 로그인', value: 'phone' },
  { label: '코드번호 로그인', value: 'code' },
];

const SignInPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get('tab');
  const [sessionCplatToken] = useSessionStorage('cplatToken', '');
  const decryptedToken = AES.decryptData(sessionCplatToken);

  // 활성화된 탭 타입
  const [selectedTab, setSelectedTab] = useState<TabDataValues<typeof TAB_DATA>>(
    initialTab || 'phone',
  );

  // 이미 토큰 있으면 바로 market 페이지로 보냄
  useLayoutEffect(() => {
    if (decryptedToken && decryptedToken !== '') navigate(ROUTES.market);
  }, []);

  return (
    <div className="flex h-[100vh] items-start justify-center bg-gray-50 pt-[120px]">
      <div className="rounded-lg border bg-white">
        <Tab data={TAB_DATA} selectedTab={selectedTab} handleSelectTab={setSelectedTab} />
        {selectedTab === 'code' ? <CodeSigninContainer /> : <PhoneSigninContainer />}
      </div>
    </div>
  );
};

export default SignInPage;
