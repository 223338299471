import Checkbox from '@/components/atoms/Checkbox';
import { setCheckedOrderList } from '@/redux/orderSlice';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { autoComma, formatDate } from '@/utils/parser';
import { cn } from '@/utils/tailwind';
import BadgeSquare from '@/components/atoms/BadgeSquare';
import { OrderForCard } from '@/pages/ProductShipmentPage';
import { useResponsive } from '@/hooks/useResponsive';
import CopyClipboard from '@/assets/icons/CopyClipboard.svg?react';
import BulletList from '@/components/molecules/BulletList';
import { CPLAT_API_ROUTES, useSuspenseFetchs } from '@/api';
import { AxiosResponse } from 'axios';
import useCopy from '@/hooks/useCopy';

type OrderCardType = {
  data: OrderForCard;
};

const OrderCard = ({ data }: OrderCardType) => {
  const dispatch = useAppDispatch();
  const { checkedOrderList, selectedCategory } = useAppSelector((state) => state.order);
  const orderKey = `${data.OrderNo}${data.OrderItemNo}`;
  const isChecked = checkedOrderList.includes(orderKey);
  const isGrouped = data.OrderItemList.length > 1;
  const { isPCSize } = useResponsive();
  const { copyToClipboard } = useCopy({
    toastMessage: '주문 정보가 복사됐어요',
  });

  const [{ data: marketInfoRes }] = useSuspenseFetchs([
    { url: CPLAT_API_ROUTES.getMarketModel, option: { method: 'POST' } },
  ]);
  const { data: marketInfoList } = marketInfoRes as AxiosResponse<MarketInfo[]>;
  const SellerAdminPageUrl = marketInfoList.find(
    (item) => item.MarketNameEnglish === data.MarketNameEnglish,
  )?.SellerAdminPageUrl;

  const handleChange = () => {
    let updatedList: string[] = [];

    if (selectedCategory === 'confirmOrder') {
      if (isChecked) {
        updatedList = checkedOrderList.filter((item) => item !== orderKey);
      } else {
        updatedList = [...checkedOrderList, orderKey];
      }
    } else {
      if (isChecked) {
        updatedList = [];
      } else {
        updatedList = [orderKey];
      }
    }

    dispatch(setCheckedOrderList(updatedList));
  };

  const copyInfo = `상품명 : ${data.ProductName}\n옵션명 : ${data.ProductOption}\n판매자상품코드 : ${data.ExternalProductInfo}\n결제금액 : ${data.SalesAmount}\n구매수량 : ${data.Quantity}\n구매자정보 : ${data.OrdererInfo.orderer_name}(${data.OrdererInfo.orderer_id})\n수령자정보 : ${data.ReceiverInfo.receiver_name}(${data.ReceiverInfo.receiver_phone_no})\n배송지정보 : [${data.ReceiverInfo.receiver_zip_code}] ${data.ReceiverInfo.receiver_address}\n배송메모 : ${data.ReceiverInfo.shipping_memo}`;

  // 모바일
  if (!isPCSize) {
    return (
      <div className={cn('flex gap-[8px]')}>
        <div className="pt-[2px]">
          <Checkbox checked={isChecked} onClick={handleChange} />
        </div>
        <div>
          <div className="flex items-center gap-[8px]">
            {isGrouped && (
              <BadgeSquare variant={'primary'} size="sm">
                묶음 {data.OrderItemList.length}건
              </BadgeSquare>
            )}
            <span
              className="text-gray-800 underline Caption1M12"
              onClick={() => {
                const data = JSON.stringify({
                  SellerAdminPageUrl,
                });
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(data);
              }}
            >
              {isGrouped ? data.CoupangShipmentBoxNo : data.OrderNo}
            </span>
            <span className="text-gray-600 Caption1M12">{formatDate(data.OrderDate)}</span>
            <CopyClipboard
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(copyInfo);
              }}
            />
          </div>
          <div className="mb-[4px] flex flex-col gap-[10px]">
            {data.OrderItemList.map((item) => (
              <div key={item.ProductName}>
                <div className="text-gray-800 Body6M14 Ellipsis1Line">{item.ProductName}</div>
                <div className="mb-[6px] text-gray-600 Caption1M12">{item.ProductOption}</div>
                <div className=" flex items-center gap-[8px]">
                  <span className="text-gray-800 Caption1M12">
                    {autoComma(item.SalesAmount)}원 / {item.Quantity}개
                  </span>
                  {item.ExternalProductInfo && (
                    <span className="text-gray-800 Caption1M12">
                      판매자 상품 코드 : {item.ExternalProductInfo}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <BulletList
            data={
              data.ReceiverInfo.receiver_name
                ? [
                    `구매자 : ${
                      data.OrdererInfo.orderer_name
                        ? data.OrdererInfo.orderer_id
                          ? `${data.OrdererInfo.orderer_name}(${data.OrdererInfo.orderer_id})`
                          : `${data.OrdererInfo.orderer_name}`
                        : '-'
                    }`,
                    `수령자 : ${data.ReceiverInfo.receiver_name}(${data.ReceiverInfo.receiver_phone_no})`,
                    `[${data.ReceiverInfo.receiver_zip_code}] ${data.ReceiverInfo.receiver_address}`,
                    ...(data.ReceiverInfo.shipping_memo
                      ? [`${data.ReceiverInfo.shipping_memo}`]
                      : []),
                  ]
                : [
                    `구매자 : ${
                      data.OrdererInfo.orderer_name
                        ? data.OrdererInfo.orderer_id
                          ? `${data.OrdererInfo.orderer_name}(${data.OrdererInfo.orderer_id})`
                          : `${data.OrdererInfo.orderer_name}`
                        : '-'
                    }`,
                    '수령자 : -',
                  ]
            }
            className="gap-0 text-gray-600 Caption1M12"
          />
        </div>
      </div>
    );
  } else {
    // PC
    return (
      <div
        className={cn(
          'flex gap-[8px] rounded-[10px] border-[1px] border-gray-150 bg-gray-50 p-[12px] pb-[14px]',
          isChecked && 'border-orange-500',
          'cursor-pointer',
        )}
        onClick={handleChange}
      >
        <div>
          <Checkbox checked={isChecked} />
        </div>
        <div>
          <div className="mb-[4px] flex items-center gap-[8px]">
            {isGrouped && (
              <BadgeSquare variant={'primary'} size="sm">
                묶음 {data.OrderItemList.length}건
              </BadgeSquare>
            )}
            <span
              className="truncate text-gray-800 underline Body6M14"
              onClick={() => {
                window.open(SellerAdminPageUrl);
              }}
            >
              {isGrouped ? data.CoupangShipmentBoxNo : data.OrderNo}
            </span>
            <span className="text-gray-600 Body6M14">{formatDate(data.OrderDate)}</span>
            <CopyClipboard
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(copyInfo);
              }}
            />
          </div>
          <div className="mb-[4px] flex flex-col gap-[10px]">
            {data.OrderItemList.map((item) => (
              <div key={item.ProductName}>
                <div className="text-gray-800 Body3S15 Ellipsis2Line">{item.ProductName}</div>
                <div className="mb-[6px] text-gray-600">{item.ProductOption}</div>
                <div className=" flex items-center gap-[8px]">
                  <span className="text-gray-800 Body6M14">
                    {autoComma(item.SalesAmount)}원 / {item.Quantity}개
                  </span>
                  {item.ExternalProductInfo && (
                    <span className="text-gray-800 Body6M14">
                      판매자 상품 코드 : {item.ExternalProductInfo}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="text-gray-600 Caption1M12">
            {`구매자 : ${
              data.OrdererInfo.orderer_name
                ? data.OrdererInfo.orderer_id
                  ? `${data.OrdererInfo.orderer_name}(${data.OrdererInfo.orderer_id})`
                  : `${data.OrdererInfo.orderer_name}`
                : '-'
            }`}
            {' | '}
            {`수령자 : ${
              data.ReceiverInfo.receiver_name
                ? `${data.ReceiverInfo.receiver_name}(${data.ReceiverInfo.receiver_phone_no}) | [${
                    data.ReceiverInfo.receiver_zip_code || '-'
                  }] ${data.ReceiverInfo.receiver_address || '-'}${
                    data.ReceiverInfo.shipping_memo ? ` | ${data.ReceiverInfo.shipping_memo}` : ''
                  }`
                : '-'
            }`}
          </div>
        </div>
      </div>
    );
  }
};

export default OrderCard;
