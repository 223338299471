import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { X } from 'lucide-react';

import FormCard, { type FormInput } from '@/components/organisms/FormCard';
import { type CheckedState } from '@radix-ui/react-checkbox';
import { ROUTES } from '@/routes';
import { useAppSelector } from '@/redux/store';
import { CPLAT_API_ROUTES, usePost } from '@/api';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import Button from '@/components/atoms/Button';
import SecondAuthContainer from './SecondAuthContainer';

type ChangePasswordContainerProps = {
  marketInfo: MarketInfo;
  registeredMarket: RegisteredMarketInfo;
};

const DEFAULT_MARKET_INFO = {
  MarketName: '',
  MarketId: '',
  MarketPassword: '',
  VendorId: '',
  AccessKey: '',
  SecretKey: '',
  AuthCode: '',
  AgreementList: [],
};

/**
 * 수정해야 할 정보를 체크하는 함수
 * 대부분의 마켓이 MarketId, MarketPassword 이지만
 * 쿠팡의 경우 MarketId -> VendorId
 * MarketPassword -> SecretKey ,AccessKey 이다.
 */
const checkReadonlyValue = (key: string) => {
  switch (key) {
    case 'MarketPassword':
    case 'SecretKey':
    case 'AccessKey':
      return false;
    default:
      return true;
  }
};

const ChangePasswordContainer = ({
  marketInfo,
  registeredMarket,
}: ChangePasswordContainerProps) => {
  const { MarketTextboxOptionalList, MarketAuthCheckOptionalList, MarketNameEnglish } = marketInfo;

  const { mutate: marketRegister, isPending } = usePost(CPLAT_API_ROUTES.registerMarketAccount, {});
  const queryClient = useQueryClient();

  const user = useAppSelector((state) => state.user);
  const { CplatToken } = user;
  const navigate = useNavigate();
  const { register, watch, setValue, setError, formState } = useForm<MarketFormData>({
    defaultValues: {
      ...MarketTextboxOptionalList.reduce(
        (acc, { Key }) => ({
          ...acc,
          // @ts-ignore
          [Key]: checkReadonlyValue(Key) ? registeredMarket[Key] : '',
        }),
        {} as Omit<MarketFormData, 'AgreementList'>,
      ),
      AgreementList: MarketAuthCheckOptionalList.map((item) => ({
        ...item,
        IsInputMandatory: true,
        values: true,
      })),
    },
  });
  const { errors } = formState;

  // 2차 인증이 필요한 경우에, response로 받아온 휴대폰 번호에 대한 메시지
  const [secondAuthPhoneNumber, setSecondAuthPhoneNumber] = useState<string>('');
  // 남은 시간초
  const [seconds, setSeconds] = useState<number>(180);
  // 카운트다운 타이머 활성화 여부
  const [isActive, setIsActive] = useState<boolean>(false);

  // 2차 인증에 필요한 유효시간 3분 카운트 다운
  useEffect(() => {
    // 2차 인증 api 호출해서 타이머 활성화되었을 때 카운트 다운 시작
    if (seconds > 0 && isActive) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [seconds, isActive]);

  // dialog
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const formValues = watch();

  const [inputList, setInputList] = useState<FormInput>(
    MarketTextboxOptionalList.map((marketInfo) => ({
      hasToBeEncrypted: marketInfo.HasToBeEncrypted,
      viewEncryptIcon: marketInfo.HasToBeEncrypted,
      placeholder: marketInfo.PlaceHolder,
      readOnly: checkReadonlyValue(marketInfo.Key),
      disabled: checkReadonlyValue(marketInfo.Key),
      ...register(marketInfo.Key),
    })),
  );

  // 연동 동의 핸들러
  const handleCheck = (key: string, checked: CheckedState) =>
    setValue(
      'AgreementList',
      formValues.AgreementList.map((agreement) =>
        agreement.Key === key ? { ...agreement, values: checked } : agreement,
      ),
    );

  const inputValues = Object.values(formValues).filter((value) => typeof value === 'string');
  const isDisable =
    inputValues.every((value) => value !== '') &&
    formValues.AgreementList.every(({ values, IsInputMandatory }) => IsInputMandatory && values)
      ? false
      : true;

  // 마켓연동 완료 후 modal 에서 확인 버튼 핸들러
  const handleComplete = () => {
    setIsCompleteModalOpen(false);
    navigate(ROUTES.market);
  };

  // 마켓등록 핸들러
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    const body = {
      CplatToken,
      MarketName: MarketNameEnglish,
      MarketLoginInfo: JSON.stringify({
        ...DEFAULT_MARKET_INFO,
        MarketName: MarketNameEnglish,
        ...formValues,
      }),
    };

    marketRegister(body, {
      onSuccess(res) {
        const { code, message } = res.data as CplatApiResponse<string>;

        if (code === '200') {
          setIsCompleteModalOpen(true);
          queryClient.refetchQueries({
            queryKey: [CPLAT_API_ROUTES.getRegisteredMarketInfo],
          });
        }

        if (code === '610') {
          setError('root', { message: '아이디 또는 비밀번호가 일치하지 않습니다.' });
        }

        // 다른 셀러에 의해 이미 등록된 마켓일 경우
        if (code === '611') {
          setError('root', { message });
        }

        // 2차 인증이 필요한 경우 (e.g. 롯데온)
        if (code === '615') {
          setSecondAuthPhoneNumber(message);

          // 남은 시간 카운트 활성화
          setIsActive(true);
          // 잔여 시간 3분 설정
          setSeconds(180);
        }
      },
    });
  };

  const handleRemeberPassword = () => {
    inputList
      .filter((input) => !checkReadonlyValue(input.name))
      // @ts-ignore
      .map((input) => setValue(input.name, registeredMarket[input.name]));

    setInputList(inputList.map((input) => ({ ...input, hasToBeEncrypted: false })));
  };

  return (
    <>
      {/* 
        1) marketNameEnglish === 'lotteon' 이면서
        2) 인증 api 호출 후에 노출됨 */}
      {marketInfo.MarketNameEnglish === 'lotteon' && isActive && (
        <SecondAuthContainer
          where={'CHANGE_PASSWORD'}
          seconds={seconds}
          secondAuthPhoneNumber={secondAuthPhoneNumber}
          marketNameEnglish={marketInfo.MarketNameEnglish}
          marketName={marketInfo.MarketName}
          marketId={formValues.MarketId}
        />
      )}
      <FormCard
        inputList={inputList}
        onCheckedChange={handleCheck}
        disabled={isDisable}
        isLoading={isPending}
        onClickMoreTerms={() => setIsTermsModalOpen(true)}
        agreementList={formValues.AgreementList}
        onSubmit={handleSubmit}
        errors={errors.root}
        onRememberPassword={handleRemeberPassword}
      />
      <Dialog open={isCompleteModalOpen}>
        <DialogContent className="flex !w-[380px] flex-col items-center p-[30px] pb-[20px] text-center">
          <h3 className="text-gray-900 c-t3">비밀번호 변경이 완료되었습니다.</h3>
          <Button onClick={handleComplete} variant="secondary" className="mt-[10px] w-[200px]">
            확인
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={isTermsModalOpen} onOpenChange={() => setIsTermsModalOpen(!isTermsModalOpen)}>
        <DialogContent className="flex h-[500px] !w-[800px] flex-col items-center !p-0  text-center">
          <div
            className="flex w-full cursor-pointer justify-end p-[20px]"
            onClick={() => setIsTermsModalOpen(false)}
          >
            <X />
          </div>
          <iframe
            src="https://www.cplat.io/sb_login"
            className="h-[100%] w-[100%] rounded-[16px]"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangePasswordContainer;
