import { useState } from 'react';
import { useToast } from '@/hooks/useToast';

interface UseCopyProps {
  toastMessage: string;
}

const useCopy = ({ toastMessage }: UseCopyProps) => {
  const { toast } = useToast();
  const [isCopying, setIsCopying] = useState(false);

  const copyToClipboard = (text: string) => {
    if (isCopying) return;
    setIsCopying(true);

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast({
            icon: '✅',
            title: toastMessage,
            variant: 'dark',
          });
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        })
        .finally(() => {
          setTimeout(() => setIsCopying(false), 3000);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        toast({
          icon: '✅',
          title: toastMessage,
          variant: 'dark',
        });
      } catch (err) {
        console.error('Failed to copy using execCommand: ', err);
      } finally {
        document.body.removeChild(textArea);
        setTimeout(() => setIsCopying(false), 3000);
      }
    }
  };

  return { copyToClipboard, isCopying };
};

export default useCopy;
