import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { type CheckedState } from '@radix-ui/react-checkbox';
import { useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';

import { CPLAT_API_ROUTES, usePost } from '@/api';
import FormCard, { type FormInput } from '@/components/organisms/FormCard';
import { useAppSelector } from '@/redux/store';
import { ROUTES } from '@/routes';
import { Dialog, DialogContent } from '@/components/molecules/Dialog';
import Button from '@/components/atoms/Button';

type FormData = {
  marketName: string;
  marketId: string;
  marketPassword: string;
  agreementList: TermsFormData[];
};

const AGREEMENT_LIST = [
  {
    Key: 'extra-guide',
    GuideMessage: '[필수] 계정 연동 권한 동의',
    ReferenceMessage: '동의 내용 보기',
    ReferenceUrl: 'https://www.cplat.io/dccae533-9a8a-4333-8fbe-85019be04c72',
    IsInputMandatory: true,
    values: true,
  },
];

const ReqAddMarketContainer = () => {
  const { register, watch, setError, formState, setValue } = useForm<FormData>({
    defaultValues: {
      marketName: '',
      marketId: '',
      marketPassword: '',
      agreementList: AGREEMENT_LIST,
    },
  });
  const { errors } = formState;
  const formValues = watch();

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const { CplatToken } = user;

  // dialog
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const { mutate: marketRegister, isPending } = usePost(CPLAT_API_ROUTES.registerMarketAccount, {});

  const inputList: FormInput = [
    {
      hasToBeEncrypted: false,
      viewEncryptIcon: false,
      placeholder: '마켓 이름을 입력하세요',
      ...register('marketName'),
    },
    {
      hasToBeEncrypted: false,
      viewEncryptIcon: false,
      placeholder: '아이디를 입력하세요',
      ...register('marketId'),
    },
    {
      hasToBeEncrypted: true,
      viewEncryptIcon: true,
      placeholder: '비밀번호를 입력하세요 ',
      ...register('marketPassword'),
    },
  ];

  // 연동 동의 핸들러
  const handleCheck = (key: string, checked: CheckedState) => {
    setValue(
      'agreementList',
      formValues.agreementList.map((agreement) =>
        agreement.Key === key ? { ...agreement, values: checked } : agreement,
      ),
    );
  };

  const inputValues = Object.values(formValues).filter((value) => typeof value === 'string');
  const isDisable =
    inputValues.every((value) => value !== '') &&
    formValues.agreementList.every(({ values, IsInputMandatory }) => IsInputMandatory && values)
      ? false
      : true;

  // 마켓연동 완료 후 modal 에서 확인 버튼 핸들러
  const handleComplete = () => {
    setIsCompleteModalOpen(false);
    navigate(ROUTES.market);
  };

  // 마켓등록 핸들러
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    const body = {
      CplatToken,
      MarketName: 'extra',
      MarketLoginInfo: JSON.stringify({
        MarketName: 'extra',
        MarketId: formValues.marketId,
        MarketPassword: formValues.marketPassword,
        VendorId: formValues.marketName,
        AgreementList: formValues.agreementList,
      }),
    };

    marketRegister(body, {
      onSuccess(res) {
        const { code, message } = res.data as CplatApiResponse<string>;

        if (code === '200') {
          setIsCompleteModalOpen(true);
        }

        // 다른 셀러에 의해 이미 등록된 마켓일 경우
        if (code === '611') {
          setError('root', { message });
        }
      },
    });
  };

  return (
    <>
      <FormCard
        errors={errors.root}
        inputList={inputList}
        agreementList={formValues.agreementList}
        isLoading={isPending}
        onSubmit={handleSubmit}
        disabled={isDisable}
        onClickMoreTerms={() => setIsTermsModalOpen(true)}
        onCheckedChange={handleCheck}
      />
      <Dialog open={isCompleteModalOpen}>
        <DialogContent className="flex !w-[380px] flex-col items-center p-[30px] pb-[20px] text-center">
          <h3 className="text-gray-900 c-t3">마켓 추가 요청이 완료되었습니다!</h3>
          <p className="text-gray-700 c-b2">
            셀러박스에서 빠른 시일 내에 연동을
            <br />
            진행할 수 있도록 노력하겠습니다!
          </p>
          <Button onClick={handleComplete} variant="secondary" className="mt-[10px] w-[200px]">
            확인
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={isTermsModalOpen} onOpenChange={() => setIsTermsModalOpen(!isTermsModalOpen)}>
        <DialogContent className="flex h-[500px] !w-[800px] flex-col items-center !p-0  text-center">
          <div
            className="flex w-full cursor-pointer justify-end p-[20px]"
            onClick={() => setIsTermsModalOpen(false)}
          >
            <X />
          </div>
          <iframe
            src="https://www.cplat.io/sb_login"
            className="h-[100%] w-[100%] rounded-[16px]"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReqAddMarketContainer;
