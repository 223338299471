import debounce from '@/utils/debounce';
import { useEffect, useState } from 'react';

export const useResponsive = () => {
  const [isPCSize, setIsPCSize] = useState(window.innerWidth >= 1300);
  useEffect(() => {
    const resizeListener = () => {
      window.addEventListener(
        'resize',
        debounce(() => {
          const width = window.innerWidth;

          if (width < 1300) setIsPCSize(false);
          else setIsPCSize(true);
        }),
      );
    };

    resizeListener();

    return () => {
      removeEventListener('resize', resizeListener);
    };
  }, []);

  return { isPCSize };
};
