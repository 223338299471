import LabMainImg from '@/assets/images/LabMainImg.png';
import ListFill from '@/components/organisms/ListFill/indext';

type NavigateParams = {
  key: string;
  name: string;
};

export type MenuType = {
  title: string;
  description: string;
  params: NavigateParams;
};

type MenuDataType = Array<MenuType>;

const LabMainPage = () => {
  const MENU_DATA: MenuDataType = [
    {
      title: '도매몰, 해외 상품명 바꾸기',
      description: '기존 상품명을 변경해 상품을 상위 노출 시키세요',
      params: {
        key: 'ChangeNameWebview',
        name: 'ChangeNameWebview',
      },
    },
    {
      title: '키워드로 상품명 만들기',
      description: '키워드만 입력하면 AI가 상품명을 만들어 드려요',
      params: {
        key: 'MakeNameWebview',
        name: 'MakeNameWebview',
      },
    },
    {
      title: '상품 페이지 진단하기',
      description: '상세페이지의 개선점을 파악하고 최적화하세요',
      params: {
        key: 'DiagnoseProductWebview',
        name: 'DiagnoseProductWebview',
      },
    },
  ];

  return (
    <>
      <div className="mb-[32px] mt-[30px] flex justify-center">
        <img src={LabMainImg} alt="이미지" width={168} height={136} />
      </div>
      <div className="mb-[30px] flex flex-col gap-[8px] text-center">
        <p className="text-gray-900 Body2M16">새로운 AI 기능들을 실험실에서 만나보세요</p>
        <p className="text-gray-550 Caption1M12">*상품 진단은 스마트스토어 상품만 가능합니다.</p>
      </div>
      <div className="mx-[20px] flex flex-col gap-[16px]">
        {MENU_DATA.map((item: MenuType) => (
          <ListFill key={item.title} item={item} />
        ))}
      </div>
    </>
  );
};

export default LabMainPage;
