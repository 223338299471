import Lottie from 'lottie-react';
import LoadingBubbleData from '@/assets/animation/LoadingBubble.json';
import SellerBoxLogoCircle from '@/assets/icons/SellerBoxLogoCircle.svg?react';
import BulletList from '@/components/molecules/BulletList';

interface BotBubbleProps {
  type: 'loading' | 'headerBubble' | 'bulletBubble' | 'textBubble';
  text?: string;
  list?: Array<string>;
}

const BotBubble = ({ type, text, list }: BotBubbleProps) => {
  switch (type) {
    case 'loading':
      return (
        <div className="mb-[10px] flex w-full flex-col gap-[10px]">
          <div className="flex items-end gap-[8px]">
            <SellerBoxLogoCircle />
            <span className="text-gray-900 Body6M14">셀러박스 AI</span>
          </div>
          <Lottie className="mb-[20px] w-[58px]" animationData={LoadingBubbleData} loop />
        </div>
      );
    case 'headerBubble':
      return (
        <div className="flex w-full flex-col gap-[10px]">
          <div className="flex items-end gap-[8px]">
            <SellerBoxLogoCircle />
            <span className="text-gray-900 Body6M14">셀러박스 AI</span>
          </div>
          <div className="w-fit max-w-[90%] whitespace-pre-wrap break-words rounded-[12px] bg-gray-100 p-[12px] text-gray-800 Body6M14">
            {text || '일시적으로 오류가 발생하였습니다.\n오류가 반복되면 1:1 문의를 남겨주세요.'}
          </div>
        </div>
      );
    case 'bulletBubble':
      return (
        <div className="w-fit max-w-[90%] whitespace-pre-wrap break-words rounded-[12px] bg-gray-100 p-[12px] text-gray-800 Body6M14">
          <BulletList
            data={[
              `고려 요소 : ${text}`,
              `키워드 : ${list && list.map((item) => `"${item}"`).join(', ')}`,
            ]}
            className="gap-0"
          />
        </div>
      );
    case 'textBubble':
      return (
        <div className="w-fit max-w-[90%] whitespace-pre-wrap break-words rounded-[12px] bg-gray-100 p-[12px] text-gray-800 Body6M14">
          {text}
        </div>
      );
  }
};

export default BotBubble;
